import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import baseRoutes from "base-routes";

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{pathname: baseRoutes.login.path, state: { from: props.location } }} />
    )} />
)