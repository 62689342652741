/*!

This File contain all route that will use in ./src/index.js & in navigations
*/
import blur_image from "assets/img/darkgr.png";
import logo from "assets/img/asglogo.png";
import {
  ADNETWORK_ID_SLUG,
  CAMPAIGN_ID_SLUG,
  ADVERTISEMENT_ID_SLUG,
  VENDOR_ID_SLUG,
  ASSET_ID_SLUG
} from "__helpers/constants";

// export const basePath = "/ab/asg";
export const basePath = "";

export const baseRoutes = {
  login: {
    path: `${basePath}/login`,
    pathName: "Login"
  },
  signup: {
    path: `${basePath}/signup`,
    pathName: "Signup "
  },
  forgotPassword: {
    path: `${basePath}/forgot-password`,
    pathName: "Forgot Password"
  },
  resetPassword: {
    path: `${basePath}/reset-password`,
    pathName: "Reset Password"
  },
  admin: {
    path: "/",
    pathName: ""
  },
  dashboard: {
    path: `${basePath}/dashboard`,
    pathName: "Dashboard",
    useLink: `/dashboard`
  },
  profile: {
    path: "/profile",
    pathName: "Profile"
  },
  adNetworkList: {
    path: "/adNetwork",
    pathName: "Ad Network",
    useLink: `${basePath}/adNetwork/`
  },
  addAdNetwork: {
    path: `/adNetwork/create-adNetwork`,
    pathName: "Ad Network",
    useLink: `${basePath}/adNetwork/create-adNetwork`
  },
  editAdNetwork: {
    path: `/adNetwork/edit-adNetwork/${ADNETWORK_ID_SLUG}`,
    pathName: "Ad Network",
    useLink: `${basePath}/adNetwork/edit-adNetwork/${ADNETWORK_ID_SLUG}`
  },
  addCampaign: {
    path: `/campaign/create-campaign`,
    pathName: "Create Campaign",
    useLink: `${basePath}/campaign/create-campaign`
  },
  editCampaign: {
    path: `/campaign/edit-campaign/adNetwork/${ADNETWORK_ID_SLUG}/campaign/${CAMPAIGN_ID_SLUG}`,
    pathName: "Edit Campaign",
    useLink: `${basePath}/campaign/edit-campaign/adNetwork/${ADNETWORK_ID_SLUG}/campaign/${CAMPAIGN_ID_SLUG}`
  },
  campaign: {
    path: `/campaign`,
    pathName: "Campaigns",
    useLink: `${basePath}/campaign`
  },
  addAdvertisement: {
    path: `/advertisement/create-advertisement/${CAMPAIGN_ID_SLUG}?`,
    pathName: "Create Advertisement",
    useLink: `${basePath}/advertisement/create-advertisement/`
  },
  editAdvertisement: {
    path: `/advertisement/adNetwork/${ADNETWORK_ID_SLUG}/edit-advertisement/${ADVERTISEMENT_ID_SLUG}?`,
    pathName: "Edit Advertisement",
    useLink: `${basePath}/advertisement/adNetwork/${ADNETWORK_ID_SLUG}/edit-advertisement/${ADVERTISEMENT_ID_SLUG}?`
  },
  advertisementDetail: {
    path: `/advertisement/advertisement-details/${ADNETWORK_ID_SLUG}/${ADVERTISEMENT_ID_SLUG}`,
    pathName: "Advertisement Details",
    useLink: `${basePath}/advertisement/advertisement-details/${ADNETWORK_ID_SLUG}/${ADVERTISEMENT_ID_SLUG}`,
  },
  advertisement: {
    path: `/advertisement`,
    pathName: "Advertisements"
  },
  addAssets: {
    path: `/assets/create-assets/`,
    pathName: "Assets"
  },
  editAsset: {
    path: `/assets/adNetwork/${ADNETWORK_ID_SLUG}/assets/${ASSET_ID_SLUG}`,
    pathName: "Asset",
    useLink: `${basePath}/assets/adNetwork/${ADNETWORK_ID_SLUG}/assets/${ASSET_ID_SLUG}`
  },
  listAsset: {
    path: `/assets`,
    pathName: "Assets",
    useLink: `${basePath}/assets/`
  },
  editVendor: {
    path: `/vendor/adNetwork/${ADNETWORK_ID_SLUG}/vendor/${VENDOR_ID_SLUG}`,
    pathName: "Vendors",
    useLink:`${basePath}/vendor/adNetwork/${ADNETWORK_ID_SLUG}/vendor/${VENDOR_ID_SLUG}`,
  },
  addVendor: {
    path: `/vendor/create-vendor/`,
    pathName: "Vendors"
  },
  listVendor: {
    path: `/vendor`,
    pathName: "Vendors",
    useLink: `${basePath}/vendor/`
  }
};

export const projectAssets = {
  blur_image: blur_image,
  brandShortName: "ASG",
  logo: logo
};

export default baseRoutes;
