/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/* 
==========================================================
* In this class Ad-Network selection and all api's related to adnetwork services are used
* Redux data setup and data fetching is also handling.
==========================================================
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import Poppers from "@material-ui/core/Popper";
import {
  Icon,
  styles,
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  Hidden,
  Divider,
  withStyles,
  Avatar,
  Select,
  FormGroup,
  InputLabel,
  FormControl,
  Input,
  CircularProgress
} from "@material-ui/core/";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import SelectAllTwoTone from "@material-ui/icons/SelectAllTwoTone";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import dummy from "assets/img/dummy.png";
import { sizeHeight } from "@material-ui/system";
import { Link, useHistory } from "react-router-dom";
import { baseRoutes, basePath } from "base-routes";
import { layout } from "admin-routes";
import { userService } from "_services/user.service";
import { DEFAULT_PROFILE_IMG, NO_USERNAME } from "__helpers/constants";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import { STORED_ADNETWORK, DATA_LOADING } from "__helpers/constants";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { STORED_ADVERTISEMENT } from "__helpers/constants";
import dropdown from "assets/img/dropdown.png";
import { STORED_CAMPAIGN_ANALYTICS } from "__helpers/constants";
import { STORED_ADVERTISEMENT_ANALYTICS } from "__helpers/constants";
import { STORED_ASSETS_ANALYTICS } from "__helpers/constants";
import { SELECTED_CAMPAIGN } from "__helpers/constants";
import { FETCH_CATEGORIES, FETCH_AD_STATUS, FETCH_AD_MEDIUM, FETCH_AD_TYPE, FETCH_ASSET_TYPE, FETCH_ASSET_DISPLAY_TYPE, FETCH_CAMPAIGN_STATUS } from "__helpers/constants";
import { FETCH_BRANDS } from "__helpers/constants";
import { withRouter } from 'react-router'

import { connect } from "react-redux";
import {
  addUSerUInfo,
  addAdNetwork,
  addCategories,
  addAdStatus,
  addAdMedium,
  adAdType,
  addAssetDisplayType,
  addAssetType,
  addCampaignStatus,
  addCampaigns,
  addAdvertisements,
  addAssets,
  addVendors,
  addTimeStamp,
  addBrands,
  reduxLoad,
  addAdNetworkList,
} from "../../js/actions/index";
import { ANALYTICS_CSV_DATA } from "__helpers/constants";
import { ANALYTICS_CSV_ADVERTISEMENT_DATA } from "__helpers/constants";

function mapDispatchToProps(dispatch) {
  return {
    addUSerUInfo: addUSerUInfoVal => dispatch(addUSerUInfo(addUSerUInfoVal)),
    addAdNetwork: addAdNetworkVal => dispatch(addAdNetwork(addAdNetworkVal)),
    addAdNetworkList: addAdNetworkListVal => dispatch(addAdNetworkList(addAdNetworkListVal)),
    addCategories: addCategoriesVal => dispatch(addCategories(addCategoriesVal)),
    addBrands: addBrandsVal => dispatch(addBrands(addBrandsVal)),
    addAdStatus: addAdStatusVal => dispatch(addAdStatus(addAdStatusVal)),
    addAdMedium: addAdMediumVal => dispatch(addAdMedium(addAdMediumVal)),
    adAdType: adAdTypeVal => dispatch(adAdType(adAdTypeVal)),
    addAssetDisplayType: addAssetDisplayTypeVal => dispatch(addAssetDisplayType(addAssetDisplayTypeVal)),
    addAssetType: addAssetTypeVal => dispatch(addAssetType(addAssetTypeVal)),
    addCampaignStatus: addCampaignStatusVal => dispatch(addCampaignStatus(addCampaignStatusVal)),
    addCampaigns: addCampaignsVal => dispatch(addCampaigns(addCampaignsVal)),
    addAdvertisements: addAdvertisementsVal => dispatch(addAdvertisements(addAdvertisementsVal)),
    addAssets: addAssetsVal => dispatch(addAssets(addAssetsVal)),
    addVendors: addVendorsVal => dispatch(addVendors(addVendorsVal)),
    addTimeStamp: addTimeStampVal => dispatch(addTimeStamp(addTimeStampVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    userInfo: state.userInfo,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class AdnetworkSelectFn extends React.Component {
  constructor(props) {
    super(props);
    let adNetworksData = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let selectedAdnetworkData = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    let selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
    this._isMounted = false;
    let spinner = document.getElementById('loadingSpinner');
    this.state = {
      openNotifcation: false,
      adnetworkId: selectedAdnetwork ? selectedAdnetwork : "",
      adNetworkJson: (adNetworksData) ? adNetworksData : [],
      adnetworkData: (selectedAdnetworkData) ? selectedAdnetworkData : [],
      loadSpinner: false,
      spinner: spinner,
      loading: false,

      articles: [],
      userInfo: [],
      adNetwork: [],
      loadingFlag: false,
      adCategories: [],
      adBrands: [],
      adStatus: [],
      adMedium: [],
      adType: [],
      assetType: [],
      assetDisplayType: [],
      campaignStatus: [],
      advertisements: [],
      campaings: [],
      assets: [],
      vendors: [],
      timestamp: "",
      reduxLoadFlag: false,
    };
    this.adNetworkChange = this.adNetworkChange.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  componentDidUpdate() {
    if (this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = "";
      let adnetworkData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      this.setState({
        articles: this.props.articles,
        userInfo: this.props.userInfo,
        adNetwork: this.props.adNetwork,
        loadingFlag: this.props.loadingFlag,
        adCategories: this.props.adCategories,
        adBrands: this.props.adBrands,
        adStatus: this.props.adStatus,
        adMedium: this.props.adMedium,
        adType: this.props.adType,
        assetType: this.props.assetType,
        assetDisplayType: this.props.assetDisplayType,
        campaignStatus: this.props.campaignStatus,
        advertisements: this.props.advertisements,
        campaings: this.props.campaings,
        assets: this.props.assets,
        vendors: this.props.vendors,
        timestamp: this.props.timestamp,
        reduxLoadFlag: this.props.reduxLoadFlag,
        adnetworkId: selectedAdnetwork,
        adnetworkData: adnetworkData,
      })
    }
  }
  // shouldComponentUpdate() {
  //   if (this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
  //     return true
  //   }
  //   console.log("Reduxxx userInfo ShouldUpdate", this.props.reduxLoadFlag, this.props.userInfo)
  //   return false;
  // }
  componentDidMount() {
    this._isMounted = true;
    let dataLoadingFlag = JSON.parse(localStorage.getItem(DATA_LOADING));
    if (!dataLoadingFlag) {
      this._isMounted && this.getProfileData();
    }
    // console.log("Reduxxx userInfo DidMount", this.props.reduxLoadFlag, this.props.userInfo)
    this.setState({
      articles: this.props.articles,
      userInfo: this.props.userInfo,
      adNetwork: this.props.adNetwork,
      loadingFlag: this.props.loadingFlag,
      adCategories: this.props.adCategories,
      adBrands: this.props.adBrands,
      adStatus: this.props.adStatus,
      adMedium: this.props.adMedium,
      adType: this.props.adType,
      assetType: this.props.assetType,
      assetDisplayType: this.props.assetDisplayType,
      campaignStatus: this.props.campaignStatus,
      advertisements: this.props.advertisements,
      campaings: this.props.campaings,
      assets: this.props.assets,
      vendors: this.props.vendors,
      timestamp: this.props.timestamp,
      reduxLoadFlag: this.props.reduxLoadFlag,
    })
    this.state.spinner.setAttribute('hidden', 'true');
  }
  getProfileData() {
    let dataLoadingFlag = JSON.parse(localStorage.getItem(DATA_LOADING));
    localStorage.setItem(DATA_LOADING, true);
    this._isMounted && userService.getAll().then(users => {
      this.props.addUSerUInfo(users);
      if (typeof users === "object" && users.fName) {
        const profileImage = users.imageRef
          ? users.imageRef
          : DEFAULT_PROFILE_IMG;
        const username = users.fName
          ? `${users.fName} ${users.lName}`
          : NO_USERNAME;
        let userDetail = {
          profileImage: profileImage,
          username: username
        };
        if (!dataLoadingFlag)
          this.fetchData();
        dataLoadingFlag = true;
        this._isMounted && this.setState(
          {
            userDetail: userDetail,
            profileImage: userDetail.profileImage,
            username: username
          },
          () => {
            this.props.updateUserInfo(userDetail);
          }
        );
      }
    });
  }
  async fetchData() {
    let adNetworks = [];
    try {
      // adNetworks = await userService.fetchAdNetwork();
      adNetworks = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
      if (adNetworks[0] && this.state.adnetworkId == "") {
        this.props.addAdNetworkList(adNetworks);
        localStorage.setItem(DATA_LOADING, true);
        this.adNetworkChange(adNetworks[0]["adNetworkId"])
      } else {
        localStorage.setItem(DATA_LOADING, false);
      }
      this._isMounted &&
        this.setState({
          adNetworkJson: adNetworks,
        });
    } catch (errors) {
    }
  }
  handleToggleNotification = () => {
    this._isMounted && this.setState(state => ({
      openNotifcation: !state.openNotifcation,
      reduxLoadFlag: false,
    }));
  };
  handleCloseNotification = event => {
    if (this.anchorNotification.contains(event.target)) {
      return;
    }
    this.setState({ openNotifcation: false });
  };
  handleSelectAdnetwork(eventId) {
  }

  async adNetworkChange(e) {
    let selectedVal = e;
    this.setState({
      loadSpinner: true,
      loading: true,
    })
    if (selectedVal) {
      var urlPathStr = window.location.pathname;
      var urlPathArr = urlPathStr.split("/");
      
      var fullUrlStr = window.location.href;
      var fullUrlArr = fullUrlStr.split("/");
      var domailUrlStr = fullUrlArr[0] + "//" + fullUrlArr[2]
      var finalUrl = domailUrlStr+"/"+urlPathArr[1];

      console.log(finalUrl, fullUrlStr);
      
      // window.location.replace(domailUrlStr+"/"+urlPathArr[1]);
      this.state.spinner.removeAttribute('hidden', 'true');
      this.setState({ reduxLoadFlag: false, openNotifcation: false });
      this.setState({ adnetworkId: selectedVal });
      this.props.reduxLoad(false);
      let categoryVals = await userService.fetchCategory();
      let adStatus = await userService.fetchAdStatus();
      let adMedium = await userService.fetchAdMedium();
      let adType = await userService.fetchAdType();
      let assetType = await userService.fetchAssetType();
      let assetDisplayType = await userService.fetchAssetDisplayType();
      let campaignStatus = await userService.fetchCampaignStatus();
      let brandVals = await userService.fetchBrand();

      let assets = [];
      let vendors = [];
      vendors = await userService.fetchAllVendors(selectedVal);
      this.props.addVendors(vendors);
      assets = await userService.fetchAllAssets(selectedVal);
      this.props.addAssets(assets);
      console.log(vendors, assets);
      // console.log("static vals... ",adStatus, adMedium, adType, assetType, assetDisplayType, JSON.stringify(campaignStatus))


      // this.state.spinner.removeAttribute('hidden', 'true');
      // localStorage.setItem(FETCH_CATEGORIES, JSON.stringify(categoryVals));
      // localStorage.setItem(FETCH_AD_STATUS, JSON.stringify(adStatus));
      // localStorage.setItem(FETCH_AD_MEDIUM, JSON.stringify(adMedium));
      // localStorage.setItem(FETCH_AD_TYPE, JSON.stringify(adType));
      // localStorage.setItem(FETCH_ASSET_TYPE, JSON.stringify(assetType));
      // localStorage.setItem(FETCH_ASSET_DISPLAY_TYPE, JSON.stringify(assetDisplayType));
      // localStorage.setItem(FETCH_CAMPAIGN_STATUS, JSON.stringify(campaignStatus));
      // localStorage.setItem(FETCH_BRANDS, JSON.stringify(brandVals));

      this.props.addCategories(categoryVals);
      this.props.addAdStatus(adStatus);
      this.props.addAdMedium(adMedium);
      this.props.adAdType(adType);
      this.props.addAssetType(assetType);
      this.props.addAssetDisplayType(assetDisplayType);
      this.props.addCampaignStatus(campaignStatus);
      this.props.addBrands(brandVals);

      this.state.adNetworkJson.map(adNetwork => {
        if (adNetwork.adNetworkId == selectedVal) {
          localStorage.setItem(SELECTED_ADNETWORK, JSON.stringify(adNetwork));
          this.props.addAdNetwork(adNetwork);
        }
      })
      // localStorage.setItem(SELECTED_ADNETWORK, JSON.stringify(selectedVal));
      
      let campaigns = [];
      let advertisement = [];
      try {
        campaigns = await userService.fetchCampagin(selectedVal);
        if (campaigns) {
          campaigns.map((campaign, key) => {
            if (campaign.advertisements && campaign.advertisements.length) {
              Array.prototype.push.apply(advertisement, campaign.advertisements);
            }
          });
        }
        this.props.addCampaigns(campaigns);
        // localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisement));
        this.props.addAdvertisements(advertisement);
        this.setState({reduxLoadFlag: false})
        // update flag after all data fetched.....
        setTimeout(function(){
          this.props.reduxLoad(true);
          this.state.spinner.setAttribute('hidden', 'true');
          this.setState({
            loadSpinner: false,
            loading: false,
          });
          if(finalUrl!=fullUrlStr){
            window.location.replace(finalUrl);
          }
        }.bind(this),2000);

        // clear analytics data after change adnetwork
        let keysToRemove = [
          STORED_CAMPAIGN_ANALYTICS,
          STORED_ADVERTISEMENT_ANALYTICS,
          STORED_ASSETS_ANALYTICS,
          SELECTED_CAMPAIGN,
          ANALYTICS_CSV_DATA,
          ANALYTICS_CSV_ADVERTISEMENT_DATA
        ];
        for (let key of keysToRemove) {
          localStorage.removeItem(key);
        }
        // window.location.reload();
      } catch (errors) {
        console.log(errors);
        this.state.spinner.setAttribute('hidden', 'true');
        // if any error occur, reload page...
        window.location.reload();
        this.setState({
          loadSpinner: false,
          loading: false,
        })
      }
    }
  }
  render() {
    const { classes } = this.props;
    const { openNotifcation, openProfile, adnetworkId, adnetworkData, adNetworkJson } = this.state;
    const user_image = "user-image";
    const styleuser = {
      borderRadius: "50%",
      width: "35px",
      height: "35px"
    };
    const userNameStyle = {
      float: "right",
      display: "block",
      width: "calc(100% - 50px)",
      color: "#000",
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "normal",
      marginTop: "10px"
    };
    const logoutStyle = {
      float: "right",
      display: "block",
      width: "calc(100% - 50px)",
      color: "#2b73cd",
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "normal",
      marginTop: "4px"
    };
    // console.log(this.state.articles,
    //   this.state.userInfo,
    //   this.state.adNetwork,
    //   this.state.loadingFlag,
    //   this.state.adCategories,
    //   this.state.adBrands,
    //   this.state.adStatus,
    //   this.state.adMedium,
    //   this.state.adType,
    //   this.state.assetType,
    //   this.state.assetDisplayType,
    //   this.state.campaignStatus,
    //   this.state.advertisements,
    //   this.state.campaings,
    //   this.state.assets,
    //   this.state.vendors,
    //   this.state.timestamp)
    return (
      <div className="addNetworkBtnCover">
        <div className="">
          <div className={classes.manager + " menuArrowMain"} style={{ width: "100%" }}>
            <Button
              onClick={this.handleToggleNotification}
              buttonRef={node => {
                this.anchorNotification = node;
              }}
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={openNotifcation ? "notification-menu-list-grow" : null}
              aria-haspopup="true"
              //className={classes.buttonLink}
              className="buttonlinkHeader"
              style={{ width: "70%" }}
            >
              <span className={"name-text"}> {(adnetworkData.adNetworkId) ? (adnetworkData.companyName) ? adnetworkData.companyName : "Unknown" : "Select AdNetwork"}</span>
              <span className={classes.notifications + ' AdnetworkSelect-notifications count'}>{adNetworkJson ? adNetworkJson.length : 0}</span>
            </Button>
            <Button className="RegularButton-round drop-btn" color="white" aria-label="edit" justIcon round
              onClick={this.handleToggleNotification}
            >
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  className="buttonProgress"
                />
              )}
              <img
                src={dropdown}
                className="campaign-status-img"
                alt=""
                className={classes.icons}
              />
            </Button>
            <Poppers
              open={openNotifcation}
              anchorEl={this.anchorNotification}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openNotifcation }) +
                " " +
                classes.popperNav + ' AdnetworkSelect-popperNav'
              }
              style={{ width: "100%", zIndex: "9999999" }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="notification-menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleCloseNotification}>
                      <MenuList role="menu">
                        <MenuItem value="">Select AdNetwork</MenuItem>
                        {typeof this.state.adNetworkJson === "object" && this.state.adNetworkJson &&
                          this.state.adNetworkJson.length
                          ? this.state.adNetworkJson.map((adNetwork, key) => {
                            return (
                              <MenuItem
                                key={key}
                                selected={(adNetwork.adNetworkId == adnetworkId) ? true : false}
                                value={adNetwork.adNetworkId}
                                className={classes.dropdownItem}
                                onClick={this.adNetworkChange.bind(this, adNetwork.adNetworkId)}
                              >
                                {(adNetwork.companyName) ? adNetwork.companyName : "Unknown"}
                              </MenuItem>
                            );
                          })
                          : null}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
        </div>
      </div>
    );
  }
}

AdnetworkSelectFn.propTypes = {
  classes: PropTypes.object
};

const AdnetworkSelect = connect(
  mapStateToProps, mapDispatchToProps
)(AdnetworkSelectFn);
export default withStyles(headerLinksStyle)(AdnetworkSelect);
