/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress
} from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { FormErrors } from "./../../components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import { userService } from "_services/user.service";

import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  URL_PATTERN,
  STATUS_CREATED,
  OK_SUCCESS_STATUS,
  FORBIDDEN_STATUS,
  INVALID_DATA_POST,
  ADNETWORK_ID_SLUG,
  STORED_ADNETWORK,
  VENDOR_ID_SLUG
} from "__helpers/constants";
import baseRoutes from "base-routes";
import { NotificationContainer } from "react-notifications";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { connect } from "react-redux";
import {
  addVendors,
  reduxLoad,
} from "../../js/actions/index";

const mat_select = "material-select disabled-dropdown-black";
const cust_label = "custom-label";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF"
    }
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  disabledDropdownBlack: {
    color: "#000 !important"
  }
};
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));
function mapDispatchToProps(dispatch) {
  return {
    addVendors: addVendorsVal => dispatch(addVendors(addVendorsVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class VendorClass extends React.Component {
  inputOpenFileRef = React.createRef(null);
  maxDate = new Date();

  constructor(props) {
    super(props);
    const { vendorID, adnetworkID } = this.props.match.params;
    this._isMounted = false;

    let stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    if (!stroedAdNetwork) {
      stroedAdNetwork = [];
    }
    let selectedAdnetworkData = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    let selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
    this.state = {
      vendorId: vendorID ? vendorID : "",
      isEditing: vendorID ? true : false,
      adNetworkId: adnetworkID ? adnetworkID : (selectedAdnetwork) ? selectedAdnetwork : "",
      selectedAdnetworkData: selectedAdnetworkData ? selectedAdnetworkData : [],
      name: "",
      infoUrl: "",
      adNetWorkJson: stroedAdNetwork,
      reduxLoadFlag: false,

      formErrors: {
        adNetworkId: "",
        name: "",
        infoUrl: ""
      },
      formValid: false,
      adNetworkIdValid: true,
      nameValid: false,
      infoUrlValid: false,
      loading: false,
      showNotification: {},
      apiPath: vendorID ? apiPath.manageVendor : apiPath.vendors
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchAdNetwork = this.fetchAdNetwork.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.fetchVendorDetail = this.fetchVendorDetail.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidUpdate(){
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = this.state.adNetworkId;
      let adnetworkData = [];
      let vendorsData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      if (this.props.vendors) {
        let jsonData = this.props.vendors;
        vendorsData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        adNetworkId: (selectedAdnetwork) ? selectedAdnetwork : "",
        selectedAdnetworkData: (adnetworkData) ? adnetworkData : [],
      })
    }
  }
  async fetchAdNetwork() {
    let data = await userService.fetchAdNetwork();
    this._isMounted && this.setState({ adNetWorkJson: data });
  }

  async fetchVendorDetail() {
    let data = await userService.fetchVendorDetail(
      this.state.vendorId,
      this.state.adNetworkId
    );

    if (data) {
      var name = "name" in data ? data.name : "";
      this.validateField("name", name);
      var infoUrl = "infoUrl" in data ? data.infoUrl : "";
      this.validateField("infoUrl", infoUrl);
      this.validateField("adNetworkId", this.state.adNetworkId);

      this._isMounted &&
        this.setState({
          name: name,
          infoUrl: infoUrl,
          adNetworkId: this.state.adNetworkId
        });
    }
  }

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  /* reset form data */
  resetForm = () => {
    const node = ReactDOM.findDOMNode(this);

    node.querySelector("#postVendorForm").reset();
    const labelNodes = node.querySelectorAll(
      ".MuiInputLabel-shrink.MuiFormLabel-filled"
    );
    this.setState({
      infoUrl: "",
      name: "",
      formValid: false,
      nameValid: false,
      infoUrlValid: false
    });
    if (typeof labelNodes == "object") {
      labelNodes.forEach(function(currentValue, currentIndex, listObj) {
        return currentValue.classList.remove(
          "MuiInputLabel-shrink",
          "MuiFormLabel-filled"
        );
      });
    }
  };

  /* validate form fields */
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;

    let adNetworkIdValid = this.state.adNetworkIdValid;
    let nameValid = this.state.nameValid;
    let infoUrlValid = this.state.infoUrlValid;

    switch (fieldName) {
      case "adNetworkId":
        this.state.adNetworkIdValid = adNetworkIdValid = value ? true : false;
        fieldValidationErrors.adNetworkId = adNetworkIdValid
          ? ""
          : enMsg.adNetworkIdRequired;
        break;

      case "name":
        this.state.nameValid = nameValid = value.trim().length ? true : false;
        fieldValidationErrors.name = nameValid ? "" : enMsg.vendorNameRequired;
        break;
      case "infoUrl":
        infoUrlValid = false;
        let error = "";
        if (!value.trim().length) {
          error = enMsg.infoUrlRequired;
        } else if (!value.match(URL_PATTERN)) {
          error = enMsg.invalidinfoUrl;
        } else {
          this.state.infoUrlValid = infoUrlValid = true;
        }
        fieldValidationErrors.infoUrl = error;
        break;
      default:
    }

    let formValid = adNetworkIdValid && nameValid && infoUrlValid;
    this.setState(
      {
        formErrors: fieldValidationErrors,
        formValid: formValid,
        adNetworkIdValid: adNetworkIdValid,
        nameValid: nameValid,
        infoUrlValid: infoUrlValid
      },
      this.validateForm
    );
  }

  validateForm() {
    return (
      this.state.formValid &&
      this.state.adNetworkIdValid &&
      this.state.nameValid &&
      this.state.infoUrlValid
    );
  }

  componentDidMount() {
    userService.refreshClientAouth();
    this._isMounted = true;
    const req = null;
    const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
    this.fetchAdNetwork();
    if (this.state.isEditing) {
      this.fetchVendorDetail();
    }
    if(!this.state.adNetworkId){
      let showNotification = {
        title: enMsg.selectAdnetworkTitle,
        message: enMsg.selectAdnetwork,
        type: "quickInfoAlert"
      };
      userService.showNotification(showNotification)
    }
    const spinner = document.getElementById('loadingSpinner');
    if (spinner) {
      spinner.setAttribute('hidden', 'true');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }
  async fetchData() {
    let vendors =  await userService.fetchAllVendors(this.state.adNetworkId);
    this.props.addVendors(vendors);
    setTimeout(function(){
      this.props.reduxLoad(true);
    }.bind(this),500);
  }
  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();

    /* use this for  fake API */
    let data = {};
    let message = "Create";
    let sendMethod = "POST";
    let apiPath = String(this.state.apiPath);

    if (this.state.vendorId) {
      // it will be use with Live API , do not remove this code
      apiPath = apiPath.replace(ADNETWORK_ID_SLUG, this.state.adNetworkId);
      apiPath = apiPath.replace(VENDOR_ID_SLUG, this.state.vendorId);
      data = {
        adNetworkId: this.state.adNetworkId,
        name: this.state.name,
        infoUrl: this.state.infoUrl,
        vendorId: this.state.vendorId
      };
      sendMethod = "PUT";
      message = "Update";
    } else {
      apiPath = String(this.state.apiPath);
      apiPath = apiPath.replace(ADNETWORK_ID_SLUG, this.state.adNetworkId);
      apiPath = apiPath.replace(VENDOR_ID_SLUG, "");
      data = {
        adNetworkId: this.state.adNetworkId,
        name: this.state.name,
        infoUrl: this.state.infoUrl
      };
    }
    let showNotification = {};
    try {
      const response = await fetch(apiPath, {
        method: sendMethod,
        headers: authHeader(),
        body: JSON.stringify(data)
      })
        .then(response => {
          const contentType = response.headers.get("content-type");
          if ([FORBIDDEN_STATUS, INVALID_DATA_POST].includes(response.status)) {
            if(contentType && contentType.indexOf("application/json") !== -1){
              return response.json();
            }else{
              showNotification = {
                title: enMsg.failedTitle,
                message: enMsg.invalidData400,
                type: "danger"
              };
            }
          } else if (response.ok) {
            this.fetchData();
            if (response.status === OK_SUCCESS_STATUS) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.vendorUpdated,
                type: "success"
              };
            } else if (response.status === STATUS_CREATED) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.vendorCreated,
                type: "success"
              };
              this.resetForm();
            }
            setTimeout(function() {
              window.history.back();
            }, 2000);
            return response.json();
          } else {
            showNotification = {
              title: enMsg.failedTitle,
              message: enMsg.networkFailedError,
              type: "danger"
            };
          }
        })
        .then(data => {
          let jsonDataVal = data;
          if(("errorMessage" in data) && data["errorMessage"] != undefined){
            showNotification = {
              title: enMsg.failedTitle,
              message: data.errorMessage,
              type: "warning"
            };
          }
        })
        .catch(error => {
          showNotification = {
            title: enMsg.failedTitle,
            message: enMsg.networkFailedError,
            type: "danger"
          };
          return response;
        });

      // throw new Error(error);
    } catch (error) {
      showNotification = {
        title: enMsg.failedTitle,
        message: enMsg.networkFailedError,
        type: "danger"
      };
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
        // notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        // this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification)
    }
    this.setState({ loading: false });
  }

  render() {
    const { adNetworkId, name, infoUrl } = this.state;
    const { classes } = this.props;
    const stylead = { borderBottom: "" };
    return (
      <Card>
        {/* <NotificationContainer/> */}
        <CardHeader color="success" className={"cusCardTitle"}>
          <h4 className={classes.cardTitleWhite}>{this.state.isEditing ? "Update " : "Create "}Vendor</h4>
        </CardHeader>
        <CardBody>
          <GridContainer justify={"center"}>
            <GridItem md={12} xs={12}>
              <div className="profile-content">
                <div className="profile">
                  <form
                    noValidate
                    id="postVendorForm"
                    onSubmit={this.handleSubmit}
                  >
                    <GridContainer>
                      <GridItem md={3} xs={12}>
                        <FormGroup style={{display:"none"}}>
                          <FormControl className={mat_select}>
                            <InputLabel
                              className="required-label"
                              htmlFor="adNetworkId"
                            >
                              AdNetwork
                            </InputLabel>
                            <Select
                              id="adNetworkId"
                              color="secondary"
                              name="adNetworkId"
                              value={adNetworkId}
                              width="100px"
                              onChange={this.handleUserInput}
                              disabled = {this.state.isEditing ? true : false}
                              disabled = {true}
                              input={<Input id="adNetworkId" />}
                            >
                              <MenuItem value="">Select AdNetwork</MenuItem>
                              {this.state.adNetWorkJson
                                ? this.state.adNetWorkJson.map(
                                    adNetWorkData => {
                                      return (
                                        <MenuItem
                                          key={adNetWorkData.adNetworkId}
                                          value={adNetWorkData.adNetworkId}
                                          selected={
                                            adNetWorkData.adNetworkId ==
                                            this.state.selectedAd
                                          }
                                        >
                                          {adNetWorkData.companyName}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                : null}
                            </Select>
                            <FormErrors
                              show={!this.state.adNetworkIdValid}
                              formErrors={this.state.formErrors}
                              fieldName="adNetworkId"
                            />
                          </FormControl>
                        </FormGroup>

                        <FormGroup className={"cusForm"}>
                          <CustomInput
                            labelText="Info Url"
                            id="infoUrl"
                            // value={this.state.infoUrl}
                            labelProps={{
                              className: "required-label"
                            }}
                            inputProps={{
                              type: "text",
                              name: "infoUrl",
                              autoComplete: "off",
                              required: true,
                              value: this.state.infoUrl,
                              inputRef: input => (this.infoUrl = input),
                              onChange: event => this.handleUserInput(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.infoUrlValid}
                            formErrors={this.state.formErrors}
                            fieldName="infoUrl"
                          />
                        </FormGroup>
                      </GridItem>

                      <GridItem md={3} xs={12}>
                        <FormGroup className={"cusForm"}>
                          <CustomInput
                            labelText="Name"
                            labelProps={{
                              className: "required-label"
                            }}
                            // value={this.state.name}
                            id="name"
                            inputProps={{
                              type: "text",
                              name: "name",
                              autoComplete: "off",
                              required: true,
                              value: this.state.name,
                              inputRef: input => (this.name = input),
                              onChange: event => this.handleUserInput(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.nameValid}
                            formErrors={this.state.formErrors}
                            fieldName="name"
                          />
                        </FormGroup>
                      </GridItem>
                    </GridContainer>
                    <div className="form-button-cover">
                      <Button
                        // variant="contained"
                        // color="primary"
                        className={this.state.loading ? "buttonSuccess" : ""}
                        disabled={this.state.loading || !this.validateForm()}
                        type="submit"
                        id="submitBtn"
                      >
                        {this.state.isEditing ? "Update" : "Create"}
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className="buttonProgress"
                          />
                        )}
                      </Button>
                      <Button
                        //disabled={!this.validateForm()}
                        onClick={this.resetForm}
                        type="button"
                      >
                        Reset
                      </Button>
                      {/* <Link
                        color="textSecondary"
                        underline="none"
                        className="cancel-back-btn"
                        to={baseRoutes.dashboard.path}
                      >
                        <Button
                          className="back-btn"
                          color="secondary"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Link> */}
                      <NavLink
                        color="textSecondary"
                        underline="none"
                        className="cancel-back-btn"
                        to={baseRoutes.listVendor.useLink}
                      >
                        <Button
                          className="back-btn"
                          color="secondary"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

VendorClass.propTypes = {
  classes: PropTypes.object
};
const Vendor = connect(
  mapStateToProps, mapDispatchToProps
)(VendorClass);
export default withStyles(styles)(Vendor);
