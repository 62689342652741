import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import { CircularProgress } from "@material-ui/core";
import {
  NotificationOptions,
  FORBIDDEN_STATUS,
  INVALID_DATA_POST,
  NO_DATA_FOUND,
  SECURITY_ERROR,
  OK_SUCCESS_STATUS,
  STORED_VENDORS,
  ADNETWORK_ID_SLUG,
  ADVERTISEMENT_ID_SLUG
} from "__helpers/constants";
import { store } from "react-notifications-component";
import { authHeader } from "__helpers/auth-header";
import { apiPath } from "api";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function VendorList(props) {
  const classes = useStyles();
  // const stroedVendorDetails = JSON.parse(localStorage.getItem(STORED_VENDORS));
  const stroedVendorDetails = useSelector(state => state.vendors);
  const vendorData = props.vendorData ? props.vendorData : "";
  const advertisementData = props.advertisementData
    ? props.advertisementData
    : [];
  const adnetworkID = props.adnetworkID ? props.adnetworkID : [];
  const searchContent = props.searchContent ? props.searchContent : "";
  console.log(searchContent);
  let notificationID = null;
  let localVendor = [];
  // const [selectedVendors, setChecked] = React.useState([0]);
  const [vendors, setVendor] = React.useState([]);
  const [finalVendors, setFinalVendors] = React.useState([]);
  const [searchVendorList, setSearchVendor] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedVendors, pushVendors] = React.useState(vendorData);

  const [defaultMessage, setDefaultMessage] = useState(enMsg.fetchingData);
  const handleToggle = value => () => {
    const currentIndex = selectedVendors.indexOf(value);
    const newChecked = [...selectedVendors];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    pushVendors(newChecked);
  };
  const handleChange = value => () => {
    pushVendors(value);
    // setValue(event.target.value);
  };
  useEffect(() => {
    let selectedVendors = [];
    if (props.vendorData) {
      selectedVendors = props.vendorData.vendorId
    }
    setDefaultMessage(enMsg.noVendorFound);
    setVendor(stroedVendorDetails);
    setFinalVendors(stroedVendorDetails);
    setSearchVendor(stroedVendorDetails);
    fetchVendors();
    pushVendors(selectedVendors);
    if(searchContent != "")
      searchVendor();
  }, [props.vendorData, searchContent]);
  function searchVendor() {
    let setSearchVendorLocal = [];
    try {
      if (searchContent) {
        setSearchVendorLocal = searchVendorList.filter((v, i) => {
          if (v.name) {
            return (
              v.name.toLowerCase().indexOf(searchContent.toLowerCase().trim()) !== -1
            );
          }
        });
        // console.log(setSearchVendorLocal)
        // setSearchVendor(setSearchVendorLocal);
      }
    }catch (error) {
      console.log(error)
    }
    setVendor(setSearchVendorLocal);
  };
  async function attachVendors(event) {
    // selectedVendors;
    setLoading(true);
    event.preventDefault();
    let showNotification = {};
    if (
      typeof selectedVendors === "string" &&
      (advertisementData.adId || advertisementData.id)
    ) {
      // let attachVendors = selectedVendors.sort();
      const adId = advertisementData.adId
        ? advertisementData.adId
        : advertisementData.id;

      let attachVendorsData = [];
      attachVendorsData = {vendorId:selectedVendors};
      if(attachVendorsData.vendorId){
        advertisementData.vendor = attachVendorsData;
      }
      
      try {
        let editAdvertisement = String(apiPath.editAdvertisement)
          .replace(ADNETWORK_ID_SLUG, adnetworkID)
          .replace(ADVERTISEMENT_ID_SLUG, advertisementData.adId);
        ;
        const response = await fetch(
          editAdvertisement,
          {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(advertisementData)
          }
        )
          .then(response => {
            const contentType = response.headers.get("content-type");
            if (
              [FORBIDDEN_STATUS, INVALID_DATA_POST].includes(response.status)
            ) {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            } else if (response.status === NO_DATA_FOUND) {
              showNotification = {
                title: enMsg.failedTitle,
                message: enMsg.noDataFound,
                type: "danger"
              };
            } else if (response.ok && response.status == OK_SUCCESS_STATUS) {
              // const { token } = await response.json();
              if (props.updateVendorDataCallback) {
                props.updateVendorDataCallback(attachVendorsData);
              }
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.attachVendorsSuccess,
                type: "success"
              };
              return response.json();
            } else {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.text();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            }
          })
          .then(data => {
            let jsonDataVal = data;
            if(("errorMessage" in jsonDataVal)){
              showNotification = {
                title: enMsg.failedTitle,
                message: jsonDataVal.errorMessage,
                type: "warning"
              };
            }
          })
          .catch(error => {
            showNotification = {
              title: enMsg.failedTitle,
              message: enMsg.networkFailedError,
              type: "danger"
            };
            return response;
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.failedTitle,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      notificationID = store.removeNotification(notificationID);
      if (notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
      }
      userService.showNotification(showNotification)
    }
    setLoading(false);
  }
  async function fetchVendors() {
    let isSubscribed = true;
    localVendor = await userService.fetchVendors(adnetworkID);
    setDefaultMessage(enMsg.noVendorFound);
    // setVendor(localVendor);
    setSearchVendor(localVendor);
    return () => (isSubscribed = false);
  }

  return (
    <>
      <List className={classes.root + " tab-list-cover"}>
        {vendors
          ? vendors.map((vendor, key) => {
              const id = `checkbox-list-label-${key}`;
              return (
                <ListItem
                  key={key}
                  // role={undefined}
                  className="vendor-details-cover"
                  dense
                  button
                  // onClick={handleToggle(vendor.vendorId)}
                  onClick = {handleChange(vendor.vendorId)}
                >
                  <RadioGroup aria-label="Vendors" name="Vendors" value={selectedVendors} >
                    <ListItemIcon key={key}>
                      <FormControlLabel value={vendor.vendorId} control={<Radio />} key={`Vendor-id-${key}`} />
                    </ListItemIcon>
                  </RadioGroup>
                  <ListItemText
                    key={`Vendor-id-${key}`}
                    id={vendor.vendorId}
                    className="vendor-id"
                    primary={`vendor id:#${vendor.vendorId}`}
                  />
                  <ListItemText
                    key={`Vendor-name-${key}`}
                    id={vendor.name}
                    className="vendor-name"
                    primary={`${vendor.name}`}
                  />
                </ListItem>
              );
            })
          : null}
      </List>

      <div className="add-btn-cover">
        {vendors && vendors.length ? (
          <button
            className={"pointer add-btn " + (loading ? "buttonSuccess" : "")}
            disabled={loading} // || !validateForm()
            onClick={attachVendors}
          >
            update
            {loading && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </button>
        ) : (
          defaultMessage
        )}
      </div>
    </>
  );
}
