/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import DashboardIcons from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import ViewDayIcon from "@material-ui/icons/ViewDay";
import WorkIcon from "@material-ui/icons/Work";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
// import TrendingUpIcon from "@material-ui/icons/TrendingUp";

// core components/views for Admin layout
import Dashboard from "views/Dashboard/Dashboard.jsx";
import Assets from "views/Assets/Assets.jsx";
import AssetsList from "views/Assets/AssetsList";
import Vendor from "views/Vendor/Vendor.jsx";
import VendorList from "views/Vendor/VendorList";
import Profile from "views/Profile/Profile.jsx";
// import UserProfile from "views/UserProfile/UserProfile.jsx";
// import TableList from "views/TableList/TableList.jsx";
// import Analytics from "views/Analytics/Analytics.jsx";
// import Login from "components/Login/Login";
// core components/views for RTL layout
import { basePath, baseRoutes } from "base-routes";

import AddCampaign from "views/AddCampaign/AddCampaign";
import AdNetwork from "views/AdNetwork/AdNetwork";
import Advertisement from "views/Advertisement/Advertisement";
import AdvertisementDetails from "views/AdvertisementDetails/AdvertisementDetails.jsx";
import AddAdvertisement from "views/AddAdvertisement/AddAdvertisement.jsx";
import EditAdvertisement from "views/AddAdvertisement/EditAdvertisement";
import AdNetworkList from "views/AdNetwork/AdNetworkList";
import CampaignList from "views/Campaign/CampaignList";

// const basePath = "/ab/asg";

const dashboardRoutes = [
  {
    path: baseRoutes.dashboard.useLink,
    name: "Dashboard",
    icon: DashboardIcons,
    component: Dashboard,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true
  },
  {
    path: baseRoutes.editAdNetwork.path,
    name: baseRoutes.addAdNetwork.pathName,
    icon: PeopleIcon,
    component: AdNetwork,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.addAdNetwork.path,
    name: baseRoutes.addAdNetwork.pathName,
    icon: PeopleIcon,
    component: AdNetwork,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.adNetworkList.path,
    name: "Ad Networks",
    icon: PeopleIcon,
    component: AdNetworkList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true
  },
  {
    path: baseRoutes.profile.path,
    name: baseRoutes.profile.pathName,
    icon: PermContactCalendarIcon,
    component: Profile,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.addCampaign.path,
    // path: "/addCampaign",
    name: baseRoutes.addCampaign.pathName,
    icon: "add",
    component: AddCampaign,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.editCampaign.path,
    // path: "/editcampaign",
    name: baseRoutes.editCampaign.pathName,
    icon: "add",
    component: AddCampaign,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  // {
  //   path: baseRoutes.campaign.path,
  //   name: baseRoutes.campaign.pathName,
  //   icon: FeaturedPlayListIcon,
  //   component: Campaign,
  //   layout: "/admin",
  //   basePath: basePath,
  //   showInSideBar: true
  // },
  {
    path: baseRoutes.campaign.path,
    name: baseRoutes.campaign.pathName,
    icon: FeaturedPlayListIcon,
    component: CampaignList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true
  },
  {
    path: baseRoutes.editAsset.path,
    name: baseRoutes.editAsset.pathName,
    icon: WorkIcon,
    component: Assets,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.addAssets.path,
    name: baseRoutes.addAssets.pathName,
    icon: WorkIcon,
    component: Assets,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.listAsset.path,
    name: baseRoutes.listAsset.pathName,
    icon: WorkIcon,
    component: AssetsList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true
  },
  {
    path: baseRoutes.editVendor.path,
    name: baseRoutes.editVendor.pathName,
    icon: PermContactCalendarIcon,
    component: Vendor,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.addVendor.path,
    name: baseRoutes.addVendor.pathName,
    icon: PermContactCalendarIcon,
    component: Vendor,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.listVendor.path,
    name: baseRoutes.listVendor.pathName,
    icon: PermContactCalendarIcon,
    component: VendorList,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true
  },
  // {
  //   path: "/analytics",
  //   name: "Analytics",
  //   icon: TrendingUpIcon,
  //   component: Analytics,
  //   layout: "/admin",
  //   basePath:basePath

  // },
  {
    path: baseRoutes.advertisementDetail.path,
    name: baseRoutes.advertisementDetail.pathName,
    icon: ViewDayIcon,
    component: AdvertisementDetails,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.addAdvertisement.path,
    name: baseRoutes.addAdvertisement.pathName,
    icon: "add",
    component: AddAdvertisement,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.editAdvertisement.path,
    name: baseRoutes.editAdvertisement.pathName,
    icon: "add",
    component: EditAdvertisement,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  },
  {
    path: baseRoutes.advertisement.path,
    name: baseRoutes.advertisement.pathName,
    icon: ViewDayIcon,
    component: Advertisement,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: true
  },
  {
    path: "/",
    name: "Dashboard",
    icon: DashboardIcons,
    component: Dashboard,
    layout: "/admin",
    basePath: basePath,
    showInSideBar: false
  }
];
// console.log(dashboardRoutes);
// debugger ;
export default dashboardRoutes;
