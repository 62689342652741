/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress,
  Select,
  Input,
  Tooltip
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { FormErrors } from "./../../components/Login/FormErrors";

import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  URL_PATTERN,
  FORBIDDEN_STATUS,
  INVALID_DATA_POST,
  NO_DATA_FOUND,
  NotificationOptions,
  AdvertisementStatus,
  redemptionTypeValues,
  savingsTypeValues,
  adMediumTypes,
  advertisementTypes,
  categoriesJson,
  brandsJson,
  STORED_ASSETS,
  ADNETWORK_ID_SLUG,
  STORED_VENDORS,
  STORED_ADVERTISEMENT,
  STORED_ADNETWORK,
  /*eslint-disable*/
} from "__helpers/constants";
import { basePath, baseRoutes } from "base-routes";
import fetch from "isomorphic-fetch";
import { apiPath } from "api";
import { NotificationContainer } from "react-notifications";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { FETCH_BRANDS } from "__helpers/constants";
import { FETCH_CATEGORIES } from "__helpers/constants";
import { STORED_CAMPAIGN } from "__helpers/constants";
import { FETCH_AD_STATUS } from "__helpers/constants";
import { FETCH_AD_MEDIUM } from "__helpers/constants";
import { FETCH_AD_TYPE } from "__helpers/constants";
import { connect } from "react-redux";
import {
  addAdvertisements,
  addCampaigns,
  reduxLoad,
} from "../../js/actions/index";

const mat_select = "material-select";
const cust_label = "custom-label";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF"
    }
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  disabledDropdownBlack: {
    color: "#676767 !important"
  }
};
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));
function mapDispatchToProps(dispatch) {
  return {
    addAdvertisements: addAdvertisementsVal => dispatch(addAdvertisements(addAdvertisementsVal)),
    addCampaigns: addCampaignsVal => dispatch(addCampaigns(addCampaignsVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    adNetworkList: state.adNetworkList,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class AddAdvertisementClass extends React.Component {
  inputOpenFileRef = React.createRef(null);
  maxDate = new Date();
  minDate = new Date();

  constructor(props) {
    super(props);
    let selectedAdnetwork = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    selectedAdnetwork = (selectedAdnetwork) ? selectedAdnetwork.adNetworkId : "";
    this.minDate.setDate(this.minDate.getDate() + 1);
    const { campaignId, advertisementId } = this.props.match.params;
    this.stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let assetsJson = JSON.parse(localStorage.getItem(STORED_ASSETS));
    let vendorJson = JSON.parse(localStorage.getItem(STORED_VENDORS));
    this._isMounted = false;
    this.formRef = React.createRef();
    /* create Ref for file input  */
    this.minDate.setYear(this.maxDate.getFullYear());
    this.maxDate.setYear(this.maxDate.getFullYear() + 2);

    let adSatus = JSON.parse(localStorage.getItem(FETCH_AD_STATUS));
    let adType = JSON.parse(localStorage.getItem(FETCH_AD_TYPE));
    let adMedium = JSON.parse(localStorage.getItem(FETCH_AD_MEDIUM));

    // console.log(adSatus, adType, adMedium);

    let spinner = document.getElementById('loadingSpinner');
    if (spinner && !spinner.hasAttribute('hidden')) {
      // spinner.setAttribute('hidden', 'true');
    }

    this.dateUtility = new DateFnsUtils();
    this.defaultClaims = {
      claimId: "",
      description: "",
      savingsType: "",
      savings: "",
      redemptionType: "",
      redemptionCode: "",
      /* validation */
      savingsTypeValid: false,
      savingsValid: false,
      redemptionTypeValid: false,
      redemptionValid: false,
      descriptionValid: true,
      formErrors: {
        description: "",
        savingsType: "",
        savings: "",
        redemptionType: "",
        redemptionCode: ""
      }
    };

    this.state = {
      reduxLoadFlag: false,
      loadSpinner: false,
      spinner: spinner,
      isAwareness: false,
      startMaxDate: this.maxDate,
      expireMinDate: this.minDate,
      advertisementId: advertisementId ? advertisementId : null,
      campaignId: campaignId ? campaignId : "",
      adNetworkJson: this.stroedAdNetwork,
      // adStatusList: (adSatus) ? adSatus : [],
      // adTypeList: (adType) ? adType : [],
      // adMediumList: (adMedium) ? adMedium : [],
      adStatusList: [],
      adTypeList: [],
      adMediumList: [],
      name: "",
      description: "",
      categories: [],
      brands: [],
      assetList: [],
      vendorList: [],
      terms: "",
      purchaseUrl: "",
      adMedium: "",
      adnetwork: selectedAdnetwork ? selectedAdnetwork : "",
      startDate: null,
      expirationDate: null,
      advertisementStatus: "active",
      advertisementType: "",
      vendors: [],
      assets: [],
      vendorsData: [],
      assetsData: [],
      claims: [Object.assign({}, this.defaultClaims)],
      //startDate: new Date(),
      formErrors: {
        campaignId: "",
        name: "",
        description: "",
        categories: "",
        brands: "",
        terms: "",
        purchaseUrl: "",
        adMedium: "",
        startDate: "",
        expirationDate: "",
        advertisementStatus: "",
        advertisementType: "",
        // vendors: "",
        assets: "",
        // claims: ""
      },
      campaignIdValid: false,
      nameValid: false,
      descriptionValid: false,
      categoriesValid: false,
      brandsValid: true,
      adMediumValid: false,
      startDateValid: false,
      expirationDateValid: false,
      advertisementStatusValid: true,
      assetsValid: false,
      /* Optional fields are default true */
      termsValid: true,
      purchaseUrlValid: true,
      advertisementTypeValid: true,
      advertisementValid: true,
      vendorsValid: true,

      claimsValid: false,

      loading: false,
      showNotification: {},
      campaignsJson: [],
      assetsJson: [],
      vendorJson: [],
      // assetsJson: assetsJson,
      // vendorJson: vendorJson,
      openStartDateDialog: false,
      openExpiryDateDialog: false,
      formValid: false,
      categoriesJson: [],
      // brandsJson: brandsJson,
      brandsJson: [],
    };
    this.adNetworkId = "";
    this.addAdvertisement = apiPath.addAdvertisement;
    this.apiListCampaign = apiPath.listCampaign;

    this.handleChange = this.handleChange.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleExpireDate = this.handleExpireDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.campaignChange = this.campaignChange.bind(this);
    this.addMoreClaims = this.addMoreClaims.bind(this);
    this.removeClaims = this.removeClaims.bind(this);

    this.fetchCampagin = this.fetchCampagin.bind(this);
    this.datePickerFocus = this.datePickerFocus.bind(this);
    this.handleMultiSelect = this.handleMultiSelect.bind(this);
    this.handleMultiSelectVendor = this.handleMultiSelectVendor.bind(this);
    this.handleMultiSelectAsset = this.handleMultiSelectAsset.bind(this);
    this.handleExpireDateError = this.handleExpireDateError.bind(this);
    this.handleStartDateError = this.handleStartDateError.bind(this);
    this.adNetworkChange = this.adNetworkChange.bind(this)
  }

  datePickerFocus = event => {
    this.setState({ openStartDateDialog: true });
  };
  handleExpireDateError(error, value) {
    this.setState({
      expirationDateValid: error.length ? false : true
    });
  }

  handleStartDateError(error, value) {
    this.setState({
      // openStartDateDialog: false,
      startDateValid: error.length ? false : true
    });
  }
  resetForm = () => {
    const node = this.formRef.current;
    node.reset();
    const labelNodes = node.querySelectorAll(
      ".MuiInputLabel-shrink.MuiFormLabel-filled:not(.Mui-disabled)"
    );
    if (this.state.advertisementId) {
      this.setState({
        startDate: null,
        expirationDate: null,
        formValid: false,
        claimsValid: true,
        assetsValid: false,
      });
    } else {
      this.setState({
        // campaignId: "",
        name: "",
        description: "",
        categories: [],
        brands: [],
        terms: "",
        purchaseUrl: "",
        adMedium: "",
        startDate: null,
        expirationDate: null,
        // advertisementStatus: "",
        advertisementType: "",
        vendors: [],
        assets: [],
        claims: [Object.assign({}, this.defaultClaims)],
        formValid: false,
        claimsValid: false
      });
    }

    if (typeof labelNodes == "object") {
      labelNodes.forEach(function(currentValue, currentIndex, listObj) {
        return currentValue.classList.remove(
          "MuiInputLabel-shrink",
          "MuiFormLabel-filled"
        );
      });
    }
  };

  addMoreClaims = event => {
    if (this.state.advertisementId) {
      return;
    }
    let allClaims = this.state.claims;
    allClaims.push(Object.assign({}, this.defaultClaims));

    /* when adding new claim since fields are required formValid will be set to false */
    this.setState({
      claims: allClaims,
      claimsValid: false
    });
  };
  removeClaims = event => {
    let allClaims = this.state.claims;
    try {
      const index = event.target.getAttribute("index");
      allClaims.splice(index, 1);
      allClaims.map((data, key) => {
        this.validateClaimField("", "", key);
      });

      this.setState({ claims: allClaims });
    } catch {}
    // allClaims.push(this.defaultClaims);
    // this.setState({ claims: allClaims });
  };

  handleClaimField = (value, name, claimIndex) => {
    if (this.state.advertisementId) {
      return;
    }
    let claims = this.state.claims;

    claims[claimIndex][name] = value;

    this.setState({ claims: claims }, () => {
      this.validateClaimField(name, value, claimIndex);
    });
  };

  // to stop the warning of calling setState of unmounted component
  handleUserInput = e => {
    if (this.state.advertisementId) {
      return;
    }
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let campaignIdValid = this.state.campaignIdValid;
    let nameValid = this.state.nameValid;
    let descriptionValid = this.state.descriptionValid;
    let categoriesValid = this.state.categoriesValid;
    let brandsValid = this.state.brandsValid;
    let termsValid = this.state.termsValid;
    let purchaseUrlValid = this.state.purchaseUrlValid;
    let adMediumValid = this.state.adMediumValid;
    let startDateValid = this.state.startDateValid;
    let expirationDateValid = this.state.expirationDateValid;
    let advertisementStatusValid = this.state.advertisementStatusValid;
    let advertisementValid = this.state.advertisementValid;
    let advertisementTypeValid = this.state.advertisementTypeValid;
    let assetsValid = this.state.assetsValid;
    
    switch (fieldName) {
      case "campaignId":
        campaignIdValid = value ? true : false;
        fieldValidationErrors.campaignId = !campaignIdValid
          ? enMsg.campaignIdRequried
          : "";

        break;
      case "name":
        nameValid = value.trim().length ? true : false;
        fieldValidationErrors.name = !nameValid
          ? enMsg.advertiseNameRequired
          : "";
        break;
      case "description":
        descriptionValid = value.trim().length ? true : false;
        fieldValidationErrors.description = !descriptionValid
          ? enMsg.descriptionRequired
          : "";

        break;
      case "categories":
        categoriesValid = value.length ? true : false;
        fieldValidationErrors.categories = !categoriesValid
          ? enMsg.categoriesRequired
          : "";

        break;
      // case "brands":
      //   brandsValid = value.length ? true : false;
      //   fieldValidationErrors.brands = !brandsValid ? enMsg.brandsRequired : "";

      //   break;

      case "assets":
        assetsValid = value.length ? true : false;
        fieldValidationErrors.assets = !assetsValid ? enMsg.assetsRequired : "";
        break;

      case "terms":
        /* terms is Optional */
        // termsValid = terms.trim().length ? true : false;
        // fieldValidationErrors.terms = !(termsValid)?enMsg.termsRequired:"";

        break;
      case "purchaseUrl":
        purchaseUrlValid = true;
        let errorMessage = "";

        if (value.trim().length && !value.match(URL_PATTERN)) {
          errorMessage = enMsg.invalidPurchaseUrl;
          purchaseUrlValid = false;
        }
        fieldValidationErrors.purchaseUrl = errorMessage;
        break;
      case "adMedium":
        adMediumValid = value ? true : false;
        fieldValidationErrors.adMedium = !adMediumValid
          ? enMsg.adMediumRequired
          : "";

        break;
      case "startDate":
        startDateValid = value ? true : false;
        fieldValidationErrors.startDate = !startDateValid
          ? enMsg.startDateRequired
          : "";

        break;
      case "expirationDate":
        expirationDateValid = value ? true : false;
        fieldValidationErrors.expirationDate = !expirationDateValid
          ? enMsg.expirationDateRequired
          : "";
        break;
      case "advertisementStatus":
        advertisementStatusValid = value ? true : false;
        fieldValidationErrors.advertisementStatus = !advertisementStatusValid
          ? enMsg.advertisementStatusRequired
          : "";

        break;

      case "advertisementType":
        advertisementValid = value ? true : false;
        fieldValidationErrors.advertisementType = !advertisementValid
          ? enMsg.advertisementTypeRequired
          : "";

        break;
    }
    if(this.state.isAwareness){
      this.setState({
        claimsValid: true,
        savingsType: "",
        savings: "",
        redemptionType: "",
        redemptionCode: "",
        claims: [Object.assign({}, this.defaultClaims)],
      })
    }
    // else{
    //   this.setState({
    //     claimsValid: false,
    //   })
    // }
    const formValid =
      campaignIdValid &&
      nameValid &&
      descriptionValid &&
      categoriesValid &&
      brandsValid &&
      termsValid &&
      purchaseUrlValid &&
      adMediumValid &&
      startDateValid &&
      expirationDateValid &&
      advertisementStatusValid &&
      advertisementValid &&
      advertisementTypeValid &&
      assetsValid;
    this.setState(
      {
        formValid: formValid,
        formErrors: fieldValidationErrors,
        campaignIdValid: campaignIdValid,
        nameValid: nameValid,
        descriptionValid: descriptionValid,
        categoriesValid: categoriesValid,
        brandsValid: brandsValid,
        termsValid: termsValid,
        purchaseUrlValid: purchaseUrlValid,
        adMediumValid: adMediumValid,
        startDateValid: startDateValid,
        expirationDateValid: expirationDateValid,
        advertisementStatusValid: advertisementStatusValid,
        advertisementValid: advertisementValid,
        advertisementTypeValid: advertisementTypeValid,
        assetsValid: assetsValid,
      },
      
      this.validateForm
    );
  }

  validateClaimField(fieldName, value, claimIndex) {
    let claims = this.state.claims;
    let claim = claims[claimIndex];

    let fieldValidationErrors = claim.formErrors;
    let descriptionValid = claim.descriptionValid;
    let savingsTypeValid = claim.savingsTypeValid;
    let savingsValid = claim.savingsValid;
    let redemptionTypeValid = claim.redemptionTypeValid;
    let redemptionCodeValid = claim.redemptionCodeValid;
    
    if(this.state.isAwareness){
      switch (fieldName) {
        case "savingsType":
            savingsTypeValid = value ? true : false;
            fieldValidationErrors.savingsType = !savingsTypeValid
              ? enMsg.savingsTypeRequired
              : "";
          break;
        case "savings":
          savingsValid = value.trim().length ? true : false;
          fieldValidationErrors.savings = !savingsValid
            ? enMsg.savingsRequired
            : "";
          break;
        case "redemptionType":
          redemptionTypeValid = value ? true : false;
          fieldValidationErrors.redemptionType = !redemptionTypeValid
            ? enMsg.redemptionTypeRequired
            : "";
          break;
        case "redemptionCode":
          redemptionCodeValid = value.trim().length ? true : false;
          fieldValidationErrors.redemptionCode = !redemptionCodeValid
            ? enMsg.redemptionCodeRequired
            : "";
          break;
      }
    }else{
      this.setState({
        savingsTypeValid: true,
        savingsValid: true,
        redemptionTypeValid: true,
        redemptionValid: true,
        // isAwareness: true
      })
      savingsTypeValid = true;
      savingsValid = true;
      redemptionTypeValid = true;
      redemptionCodeValid = true;
    }

    let claimsValid =
      descriptionValid &&
      savingsTypeValid &&
      savingsValid &&
      redemptionTypeValid &&
      redemptionCodeValid;

    claim = {
      formErrors: fieldValidationErrors,
      descriptionValid: descriptionValid,
      savingsTypeValid: savingsTypeValid,
      savingsValid: savingsValid,
      redemptionTypeValid: redemptionTypeValid,
      redemptionCodeValid: redemptionCodeValid
    };
    claims[claimIndex] = Object.assign({}, claims[claimIndex], claim);

    this.setState(
      {
        claims: claims,
        claimsValid: claimsValid
      },
      this.validateForm
    );
  }

  validateForm() {

    return (
      this.state.claimsValid &&
      this.state.formValid &&
      this.state.campaignIdValid &&
      this.state.nameValid &&
      this.state.descriptionValid &&
      this.state.categoriesValid &&
      this.state.brandsValid &&
      this.state.termsValid &&
      this.state.purchaseUrlValid &&
      this.state.adMediumValid &&
      this.state.startDateValid &&
      this.state.expirationDateValid &&
      this.state.advertisementStatusValid &&
      this.state.advertisementValid &&
      this.state.advertisementTypeValid
    );
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    this._isMounted = false;
    const req = null;
    const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
  }

  handleStartDate = value => {
    let startDateValid = true;

    if (typeof value == "object" && this.dateUtility.isValid(value)) {
      // value = this.dateUtility.format(value, "MM/dd/yyyy");
      this.setState({
        expireMinDate: value
      });
    } else if (value != null && !this.dateUtility.isValid(value)) {
      startDateValid = false;
    }

    this.setState(
      {
        startDate: value,
        startDateValid: startDateValid
      },
      () => {
        this.validateField("startDate", value);
      }
    );
  };

  handleExpireDate = value => {
    let expirationDateValid = true;

    if (typeof value == "object" && this.dateUtility.isValid(value)) {
      // value = this.dateUtility.format(value, "MM/dd/yyyy");

      this.setState({
        startMaxDate: value
      });
    } else if (value == null || !this.dateUtility.isValid(value)) {
      expirationDateValid = false;
    }
    this.setState(
      {
        expirationDate: value,
        expirationDateValid: expirationDateValid
      },
      () => {
        this.validateField("expirationDate", value);
      }
    );
  };
  async componentDidMount() {
    if(this.state.loadSpinner){
      this.state.spinner.removeAttribute('hidden', 'true');
    }else{
      // this.state.spinner.setAttribute('hidden', 'true');
    }
    this._isMounted = true;
    if(!this.state.adnetwork){
      let showNotification = {
        title: enMsg.selectAdnetworkTitle,
        message: enMsg.selectAdnetwork,
        type: "quickInfoAlert"
      };
      userService.showNotification(showNotification)
    }else{
      this.fetchAdNetworkData(this.state.adnetwork);
    }
  }
  fetchAdNetwork = async () => {
    let adNetwork = await userService.fetchAdNetwork();

    this._isMounted &&
      this.setState({
        adNetworkJson: adNetwork
      });
  };
  async fetchAdvertisementDetail() {
    const advertisementDetail = await userService.fetchAdvertisementDetail(
      this.state.advertisementId
    );
    if (advertisementDetail) {
      if (
        advertisementDetail.startDate &&
        this.dateUtility.isValid(advertisementDetail.startDate)
      ) {
        advertisementDetail.startDate = this.dateUtility.format(
          new Date(advertisementDetail.startDate),
          "MM/dd/yyyy"
        );
      }

      if (
        advertisementDetail.expirationDate &&
        this.dateUtility.isValid(advertisementDetail.expirationDate)
      ) {
        advertisementDetail.expirationDate = this.dateUtility.format(
          new Date(advertisementDetail.expirationDate),
          "MM/dd/yyyy"
        );
      }
      let mergeAdvertisementDetail = merge(this.state, advertisementDetail);
      mergeAdvertisementDetail.claims = advertisementDetail.claims.map(
        claim => {
          return merge(this.defaultClaims, claim);
        }
      );
      /* use advertisementStatus as field name for status of advertisement  */
      mergeAdvertisementDetail.advertisementStatus = advertisementDetail.status;
      this._isMounted && this.setState(mergeAdvertisementDetail);
    } else {
      this.props.history.goBack();
    }
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //    logErrorToMyService(error, info);
  }

  async fetchCampagin() {
    let campaignRows = await userService.fetchCampagin();
    this._isMounted &&
      this.setState({
        campaignsJson: campaignRows
      });
  }
  fetchData(newAdData) {
    let adNetworks = [];
    let campaigns = [];
    let campaignsLocal = [];
    let advertisement = [];
    let advertisementLocal = [];
    let assets = [];
    let vendors = [];

    let campaignData = [];
    let advertisementsData = [];
    try {
      if (this.props.campaings) {
        let jsonData = this.props.campaings;
        campaignData = (jsonData) ? jsonData : [];
        campaignsLocal = campaignData;
      }
      if (this.props.advertisements) {
        let jsonData = this.props.advertisements;
        advertisementsData = (jsonData) ? jsonData : [];
        advertisementLocal = advertisementsData;
      }

      // advertisementLocal = JSON.parse(
      //   localStorage.getItem(STORED_ADVERTISEMENT)
      // );
      advertisementLocal.push(newAdData);
      // localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisementLocal));
      this.props.addAdvertisements(advertisementLocal);

      // campaignsLocal = JSON.parse(localStorage.getItem(STORED_CAMPAIGN));
      campaignsLocal = campaignsLocal.map((campaign, key) => {
        if(campaign.campaignId == this.state.campaignId){
          campaign.advertisements.push(newAdData);
        }
        return campaign;
      });
      // localStorage.setItem(STORED_CAMPAIGN, JSON.stringify(campaignsLocal));
      this.props.addCampaigns(campaignsLocal);
      setTimeout(function(){
        this.props.reduxLoad(true);
      }.bind(this),500);

      // campaigns = userService.fetchCampagin(this.adNetworkId);
      // if (campaigns) {
      //   campaigns.map((campaign, key) => {
      //     if (campaign.advertisements && campaign.advertisements.length) {
      //       Array.prototype.push.apply(advertisement, campaign.advertisements);
      //     }
      //   });
      // }
      // localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisement));

      this.state.spinner.setAttribute('hidden', 'true');
      setTimeout(function() {
        window.history.back();
      }, 1500);
      // vendors = await userService.fetchAllVendors();
      // assets = await userService.fetchAllAssets();

      // window.history.back();
    } catch (errors) {
      console.log(errors)
      this.state.spinner.setAttribute('hidden', 'true');
      window.history.back();
    }
  }
  async handleSubmit(event) {
    // this.setState({ loading: true });
    event.preventDefault();
    this.setState({ loading: true });
    this.state.spinner.removeAttribute('hidden', 'true');
    /* use this for  fake API */
    const claims = this.state.claims;
    let claimsData = claims.map(data => {
      return {
        // claimId: data.claimId,
        description: data.description,
        redemptionCode: data.redemptionCode,
        redemptionType: data.redemptionType,
        savings: data.savings,
        savingsType: data.savingsType
      };
    });
    let data = {
      campaignId: this.state.campaignId,
      name: this.state.name,
      description: this.state.description,
      categories: this.state.categories,
      brands: this.state.brands,
      terms: this.state.terms,
      adType: this.state.advertisementType,
      purchaseUrl: this.state.purchaseUrl,
      adMedium: this.state.adMedium,
      startDate: this.state.startDate,
      expirationDate: this.state.expirationDate,
      status: this.state.advertisementStatus,
      // claims: claimsData,
      // vendor: this.state.vendorsData,
      assets: this.state.assetsData
    };
    // let data = {}
    if(this.state.vendorsData && this.state.vendorsData.vendorId){
      Object.assign(data, {vendor: this.state.vendorsData});
    }
    if(!this.state.isAwareness){
      Object.assign(data, {claims: claimsData});      
    }
    // console.log(data)
    // return false
    let showNotification = {};
    try {
      let apiPath = String(this.addAdvertisement).replace(
        ADNETWORK_ID_SLUG,
        this.adNetworkId
      );

      const response = await fetch(apiPath, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
      })
        .then(response => {
          const contentType = response.headers.get("content-type");
          if ([FORBIDDEN_STATUS, INVALID_DATA_POST].includes(response.status)) {
            if(contentType && contentType.indexOf("application/json") !== -1){
              return response.json();
            }else{
              showNotification = {
                title: enMsg.failedTitle,
                message: enMsg.invalidData400,
                type: "danger"
              };
            }
          } else if (response.status === NO_DATA_FOUND) {
            showNotification = {
              title: enMsg.failedTitle,
              message: enMsg.noDataFound,
              type: "danger"
            };
          } else if (response.ok) {
            // const { token } = await response.json();
            showNotification = {
              title: enMsg.successTitle,
              message: enMsg.advertisementCreated,
              type: "success"
            };
            // this.props.history.push(basePath + baseRoutes.advertisement.path);
          } else {
            if(contentType && contentType.indexOf("application/json") !== -1){
              return response.json();
            }else{
              showNotification = {
                title: enMsg.failedTitle,
                message: enMsg.invalidData400,
                type: "danger"
              };
            }
          }
          return response.json();
        })
        .then(data => {
          // this.setState({ loading: false });
          let jsonDataVal = data;
          if(("errorMessage" in data) && data["errorMessage"] != undefined){
            this.state.spinner.setAttribute('hidden', 'true');
            showNotification = {
              title: enMsg.failedTitle,
              message: data.errorMessage,
              type: "warning"
            };
          }else{
            this.props.reduxLoad(false);
            this.fetchData(data);
          }
        })
        .catch(error => {
          showNotification = {
            title: enMsg.failedTitle,
            message: enMsg.networkFailedError,
            type: "danger"
          };
          this.setState({ loading: false });
          this.state.spinner.setAttribute('hidden', 'true');
          return response;
        });

      // throw new Error(error);
    } catch (error) {
      showNotification = {
        title: enMsg.failedTitle,
        message: enMsg.networkFailedError,
        type: "danger"
      };
      this.setState({ loading: false });
      this.state.spinner.setAttribute('hidden', 'true');
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
      }
      userService.showNotification(showNotification)
      //window.location.reload();
    }
    this.setState({ loading: false });
  }

  handleChange(value, name) {
    if(value == "awareness"){
      this.setState({
        savingsTypeValid: true,
        savingsValid: true,
        redemptionTypeValid: true,
        redemptionValid: true,
        isAwareness: true,
        claimsValid: true,
      })
    }else if(value == "offer" || value == "coupon"){
      this.setState({
        claimsValid: false,
        isAwareness: false,
        savingsType: "",
        savings: "",
        redemptionType: "",
        redemptionCode: "",
      })
    }
    if (this.state.advertisementId) {
      return;
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  campaignChange(event) {
    if (this.state.advertisementId) {
      return;
    }
    const name = event.target.name;
    const value = event.target.value;

    this.state.campaignsJson.map((campaign, key) => {
      if (campaign.campaignId == value) {
        return (this.adNetworkId = campaign.adNetworkId);
      }
    });
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }
  handleMultiSelect(event) {
    if (this.state.advertisementId) {
      return;
    }
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }
  handleMultiSelectVendor(event) {
    if (this.state.advertisementId) {
      return;
    }
    const name = event.target.name;
    const value = event.target.value;
    let attachVendorsData = [];
    attachVendorsData = {vendorId:value};
    this.setState({
      [name] : value,
      vendorsData:attachVendorsData
    }, () => {
      this.validateField(name, value);
    });
  }
  handleMultiSelectAsset(event) {
    if (this.state.advertisementId) {
      return;
    }
    const name = event.target.name;
    const value = event.target.value;
    let attachAssetsData = [];
    value.map((v,i) => {
      attachAssetsData.push({"assetId": v});
    })
    this.setState({
      [name] : value,
      assetsData:attachAssetsData
    }, () => {
      this.validateField(name, value);
    });
  }
  async adNetworkChange(e) {
    let selectedVal = e.target.value;
    if (selectedVal) {
      this.setState({ adnetwork: selectedVal });
      // this.fetchAdNetworkData(selectedVal);
    }
  }
  componentDidUpdate(){
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let vendorsData = []; 
      let assetsData = [];
      let adCategoriesData = [];
      let adBrandsData = [];
      let adStatusData = [];
      let adTypeData = [];
      let adMediumData = [];
      let campaingsData = [];
      let adNetworkListData = [];
      let selectedAdnetwork = this.state.adnetwork;
      let adnetworkData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      if (this.props.vendors) {
        let jsonData = this.props.vendors;
        vendorsData = (jsonData) ? jsonData : [];
      }
      if (this.props.assets) {
        let jsonData = this.props.assets;
        assetsData = (jsonData) ? jsonData : [];
      }
      if (this.props.adCategories) {
        let jsonData = this.props.adCategories;
        adCategoriesData = (jsonData) ? jsonData : [];
      }
      if (this.props.adBrands) {
        let jsonData = this.props.adBrands;
        adBrandsData = (jsonData) ? jsonData : [];
      }
      if (this.props.adStatus) {
        let jsonData = this.props.adStatus;
        adStatusData = (jsonData) ? jsonData : [];
      }
      if (this.props.adType) {
        let jsonData = this.props.adType;
        adTypeData = (jsonData) ? jsonData : [];
      }
      if (this.props.adMedium) {
        let jsonData = this.props.adMedium;
        adMediumData = (jsonData) ? jsonData : [];
      }
      if (this.props.campaings) {
        let jsonData = this.props.campaings;
        campaingsData = (jsonData) ? jsonData : [];
      }
      if (this.props.adNetworkList) {
        let jsonData = this.props.adNetworkList;
        adNetworkListData = (jsonData) ? jsonData : [];
        this.originalAdvertisementsJson =  (adNetworkListData) ? adNetworkListData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        adnetworkId: (selectedAdnetwork) ? selectedAdnetwork : "",
        assetsJson: assetsData,
        vendorJson: vendorsData,
        categoriesJson: adCategoriesData,
        brandsJson: adBrandsData,
        adTypeList: adTypeData,
        adMediumList: adMediumData,
        brandsJson: adBrandsData,
        adStatusList: adStatusData,
        campaignsJson: campaingsData,
        stroedAdNetwork: adNetworkListData,
      })
    }
  }
  fetchAdNetworkData = async selectedVal => { 
    let vendorsData = []; 
    let assetsData = [];
    let adCategoriesData = [];
    let adBrandsData = [];
    let adStatusData = [];
    let adTypeData = [];
    let adMediumData = [];
    let campaingsData = [];
    let adNetworkListData = [];
    let selectedAdnetwork = this.state.adnetwork;
    let adnetworkData = [];
    if (this.props.adNetwork) {
      let selectedAdnetworkData = this.props.adNetwork;
      selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
      adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
    }
    if (this.props.vendors) {
      let jsonData = this.props.vendors;
      vendorsData = (jsonData) ? jsonData : [];
    }
    if (this.props.assets) {
      let jsonData = this.props.assets;
      assetsData = (jsonData) ? jsonData : [];
    }
    if (this.props.adCategories) {
      let jsonData = this.props.adCategories;
      adCategoriesData = (jsonData) ? jsonData : [];
    }
    if (this.props.adBrands) {
      let jsonData = this.props.adBrands;
      adBrandsData = (jsonData) ? jsonData : [];
    }
    if (this.props.adStatus) {
      let jsonData = this.props.adStatus;
      adStatusData = (jsonData) ? jsonData : [];
    }
    if (this.props.adType) {
      let jsonData = this.props.adType;
      adTypeData = (jsonData) ? jsonData : [];
    }
    if (this.props.adMedium) {
      let jsonData = this.props.adMedium;
      adMediumData = (jsonData) ? jsonData : [];
    }
    if (this.props.campaings) {
      let jsonData = this.props.campaings;
      campaingsData = (jsonData) ? jsonData : [];
    }
    if (this.props.adNetworkList) {
      let jsonData = this.props.adNetworkList;
      adNetworkListData = (jsonData) ? jsonData : [];
      this.originalAdvertisementsJson =  (adNetworkListData) ? adNetworkListData : [];
    }
    if(Object.keys(campaingsData).length === 0){
      let showNotification = {
        title: "AdNetwork Detail",
        message: "Campaigns are not availabe for this service, please create campaign",
        type: "info"
      };
      userService.showNotification(showNotification)
    }
    if(assetsData && Object.keys(assetsData).length === 0){
      let showNotification = {
        title: "AdNetwork Detail",
        message: "Assets are not availabe for this service, please create asset",
        type: "info"
      };
      userService.showNotification(showNotification)
    }
    this.setState({
      reduxLoadFlag: this.props.reduxLoadFlag,
      adnetworkId: (selectedAdnetwork) ? selectedAdnetwork : "",
      assetsJson: assetsData,
      vendorJson: vendorsData,
      categoriesJson: adCategoriesData,
      brandsJson: adBrandsData,
      adTypeList: adTypeData,
      adMediumList: adMediumData,
      adStatusList: adStatusData,
      campaignsJson: campaingsData,
      stroedAdNetwork: adNetworkListData,
    })
  };
  render() {
    const {
      advertisementType,
      categories,
      adMedium,
      campaignId,
      brands,
      advertisementId,
      assets,
      vendors,
      adnetwork,
      adStatusList,
      adTypeList,
      adMediumList,
    } = this.state;
    const { classes } = this.props;
    const disabledDropdownBlack = advertisementId
      ? classes.disabledDropdownBlack
      : "";
    const stylead = { borderBottom: "" };
    return (
      <Card>
        {/* <NotificationContainer/> */}
        <CardHeader color="success" className={"cusCardTitle"}>
          <h4 className={classes.cardTitleWhite}>Create Advertisement</h4>
        </CardHeader>
        <CardBody>
          <GridContainer justify={"center"}>
            <GridItem md={12} xs={12}>
              {/* <div className="green-header">Login</div> */}
              <div className="profile-content">
                <div className="">
                  <form ref={this.formRef} onSubmit={this.handleSubmit}>
                    <GridContainer>
                      <GridItem md={12} xs={12} style={{display:"none"}}>
                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl className={"mat_select"}>
                              <InputLabel shrink htmlFor="campaigns">
                                Select AdNetWork
                              </InputLabel>
                              <Select
                                id="adnetwork"
                                color="secondary"
                                name="adnetwork"
                                value={adnetwork}
                                width="100px"
                                disabled={true}
                                onChange={this.adNetworkChange}
                                input={<Input value={adnetwork} id="campaignsInput" />}
                                inputProps={{
                                  required: true,
                                  inputRef: this.adNetworkSelectBox
                                }}
                                displayEmpty
                              >
                                <MenuItem value="">Select AdNetwork</MenuItem>
                                {typeof this.state.adNetworkJson === "object" &&
                                this.state.adNetworkJson.length
                                  ? this.state.adNetworkJson.map((adNetwork, key) => {
                                      return (
                                        <MenuItem
                                          key={key}
                                          value={adNetwork.adNetworkId}
                                        >
                                          {adNetwork.companyName}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </div>
                      </GridItem>
                      <GridItem md={12} xs={12}>
                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                className="required-label"
                                htmlFor="campaignId"
                                disabled={advertisementId ? true : false}
                              >
                                Campaign Name
                              </InputLabel>
                              <Select
                                id="campaignId"
                                name="campaignId"
                                color="secondary"
                                value={campaignId}
                                width="100px"
                                // displayEmpty={advertisementId ? true : false}
                                className={disabledDropdownBlack}
                                onChange={event => this.campaignChange(event)}
                                input={<Input id="campaignIdInput" />}
                              >
                                <MenuItem value="">Select Campaign</MenuItem>
                                {typeof this.state.campaignsJson === "object" &&
                                this.state.campaignsJson.length
                                  ? this.state.campaignsJson.map(
                                      (campaign, key) => {
                                        return (
                                          <MenuItem
                                            key={key}
                                            value={campaign.campaignId}
                                          >
                                            {campaign.name}
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : null}
                              </Select>
                              <FormErrors
                                show={!this.state.campaignIdValid}
                                formErrors={this.state.formErrors}
                                fieldName="campaignId"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>

                        <div className="add-advert-form">
                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="Name"
                              labelProps={{
                                className: "required-label",
                                disabled: advertisementId ? true : false
                              }}
                              id="name"
                              inputProps={{
                                name: "name",
                                value: this.state.name,
                                type: "text",
                                autoComplete: "off",
                                disabled: advertisementId ? true : false,
                                className: disabledDropdownBlack,
                                onChange: event => this.handleUserInput(event)
                                // required: true,
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.nameValid}
                              formErrors={this.state.formErrors}
                              fieldName="name"
                            />
                          </FormGroup>
                        </div>
                      </GridItem>

                      <GridItem md={12} xs={12}>
                        <div className="add-advert-form">
                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="Description"
                              labelProps={{
                                className: "required-label",
                                disabled: advertisementId ? true : false
                              }}
                              id="description"
                              inputProps={{
                                type: "text",
                                name: "description",
                                autoComplete: "off",
                                // required: true,
                                disabled: advertisementId ? true : false,
                                className: disabledDropdownBlack,
                                value: this.state.description,
                                inputRef: input => (this.description = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.descriptionValid}
                              formErrors={this.state.formErrors}
                              fieldName="description"
                            />
                          </FormGroup>
                        </div>
                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                className={"required-label"}
                                htmlFor="advertisementType"
                              >
                                Advertisement Type
                              </InputLabel>
                              <Select
                                id="advertisementType"
                                color="secondary"
                                name="advertisementType"
                                value={advertisementType}
                                className={disabledDropdownBlack}
                                width="100px"
                                onChange={event =>
                                  this.handleChange(
                                    event.target.value,
                                    "advertisementType"
                                  )
                                }
                                input={<Input id="advertisementInput" />}
                              >
                                <MenuItem value="">
                                  Select Advertisement{" "}
                                </MenuItem>
                                {adTypeList
                                  ? Object.keys(adTypeList).map(key => {
                                    return (
                                      <Tooltip
                                       title={<span className="customTooltipClass">{adTypeList[key]['description']}</span>} key={adTypeList[key]['name']}
                                       value={adTypeList[key]['name']}
                                       >
                                        <MenuItem key={adTypeList[key]['name']} value={adTypeList[key]['name']}>
                                            {adTypeList[key]['name']}
                                        </MenuItem>
                                      </Tooltip>
                                    );
                                  })
                                  : null
                                }
                              </Select>
                              <FormErrors
                                show={!this.state.advertisementValid}
                                formErrors={this.state.formErrors}
                                fieldName="advertisementType"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>
                      </GridItem>
                      <GridItem md={12} xs={12}>
                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                className={"required-label"}
                                htmlFor="categories"
                              >
                                Categories
                              </InputLabel>

                              <Select
                                id="categories"
                                color="secondary"
                                name="categories"
                                value={categories}
                                className={disabledDropdownBlack}
                                width="100px"
                                multiple={true}
                                onChange={this.handleMultiSelect}
                                input={<Input id="categories" />}
                              >
                                <MenuItem
                                  disabled
                                  className="disabled-menu"
                                  value=""
                                >
                                  Select Categories{" "}
                                </MenuItem>
                                {this.state.categoriesJson
                                  ? this.state.categoriesJson.map(
                                      categoriesData => {
                                        return (
                                          <MenuItem
                                            key={categoriesData.categoryId}
                                            value={categoriesData.categoryId}
                                          >
                                            {categoriesData.name}
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : null}
                              </Select>
                              <FormErrors
                                show={!this.state.categoriesValid}
                                formErrors={this.state.formErrors}
                                fieldName="categories"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>
                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                className={"required-label"}
                                htmlFor="adMedium"
                              >
                                Ad Medium
                              </InputLabel>
                              <Select
                                id="adMedium"
                                color="secondary"
                                name="adMedium"
                                value={adMedium}
                                width="100px"
                                className={disabledDropdownBlack}
                                onChange={event =>
                                  this.handleChange(
                                    event.target.value,
                                    "adMedium"
                                  )
                                }
                                input={<Input id="adMedium" />}
                              >
                                <MenuItem value="">Select Ad Medium</MenuItem>
                                {Object.keys(adMediumList).map(key => {
                                  return (
                                    <Tooltip
                                     title={<span className="customTooltipClass">{adMediumList[key]['description']}</span>} key={adMediumList[key]['name']}
                                     value={adMediumList[key]['name']}
                                     >
                                      <MenuItem key={adMediumList[key]['name']} value={adMediumList[key]['name']}>
                                          {adMediumList[key]['name']}
                                      </MenuItem>
                                    </Tooltip>
                                  );
                                })}
                              </Select>
                              <FormErrors
                                show={!this.state.adMediumValid}
                                formErrors={this.state.formErrors}
                                fieldName="adMedium"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>
                      </GridItem>
                      <GridItem md={12} xs={12}>
                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                htmlFor="brands"
                              >
                                Brands
                              </InputLabel>
                              <Select
                                id="brands"
                                multiple
                                color="secondary"
                                name="brands"
                                value={brands}
                                width="100px"
                                className={disabledDropdownBlack}
                                onChange={this.handleMultiSelect}
                                input={<Input id="brandsInput" />}
                              >
                                <MenuItem
                                  disabled
                                  className="disabled-menu"
                                  value=""
                                >
                                  Select Brands
                                </MenuItem>
                                {this.state.brandsJson
                                  ? this.state.brandsJson.map(
                                      (type, typeIndex) => {
                                        return (
                                          <MenuItem
                                            key={type.id}
                                            value={type.id}
                                          >
                                            {type.name}
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : null}
                              </Select>
                              <FormErrors
                                show={!this.state.brandsValid}
                                formErrors={this.state.formErrors}
                                fieldName="brands"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>
                      </GridItem>

                      <GridItem md={12} xs={12}>
                        <div className="add-advert-form">
                          <FormGroup>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                // variant="inline"
                                label="Start Date"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="start-date-picker-dialog"
                                InputLabelProps={{
                                  className: "required-label"
                                }}
                                InputProps={{ autoComplete: "off" }}
                                name="startDate"
                                animateYearScrolling={true}
                                value={this.state.startDate}
                                maxDate={this.state.startMaxDate}
                                minDate={this.minDate}
                                maxDateMessage={enMsg.adtMaxStartDateMessage}
                                onChange={this.handleStartDate}
                                onError={this.handleStartDateError}
                                // onOpen={}
                                // autoOk={true}
                                // open={this.state.openStartDateDialog}
                                // onFocus={this.datePickerFocus}
                                className="KeyboardDatePicker"
                                invalidDateMessage={enMsg.invalidDate}
                                // closeAfterSelect={true}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                  className: "date-picker-span"
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <FormErrors
                              show={!this.state.startDateValid}
                              formErrors={this.state.formErrors}
                              fieldName="startDate"
                            />
                          </FormGroup>
                        </div>

                        <div className="add-advert-form">
                          <FormGroup>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                // variant="inline"
                                label="Expiration Date"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="expiry-date-picker-dialog"
                                name="expirationDate"
                                InputLabelProps={{
                                  className: "required-label"
                                }}
                                InputProps={{ autoComplete: "off" }}
                                // open={this.state.openExpiryDateDialog}
                                // autoOk={true}
                                value={this.state.expirationDate}
                                maxDate={this.state.maxDate}
                                minDate={this.state.expireMinDate}
                                minDateMessage={enMsg.adtMinExpiryDateMessage}
                                onChange={this.handleExpireDate}
                                onError={this.handleExpireDateError}
                                className="KeyboardDatePicker"
                                invalidDateMessage={enMsg.invalidDate}
                                // closeAfterSelect={true}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                  className: "date-picker-span"
                                }}
                              />
                            </MuiPickersUtilsProvider>
                            <FormErrors
                              show={!this.state.expirationDateValid}
                              formErrors={this.state.formErrors}
                              fieldName="expirationDate"
                            />
                          </FormGroup>
                        </div>
                      </GridItem>

                      <GridItem md={12} sm={12} xs={12}>
                        <div className="add-advert-form">
                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="Purchase Url"
                              id="purchaseUrl"
                              value={this.state.purchaseUrl}
                              inputProps={{
                                type: "text",
                                name: "purchaseUrl",
                                autoComplete: "off",
                                disabled: advertisementId ? true : false,
                                className: disabledDropdownBlack,
                                onChange: event => this.handleUserInput(event),
                                value: this.state.purchaseUrl
                                // required: true,
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.purchaseUrlValid}
                              formErrors={this.state.formErrors}
                              fieldName="purchaseUrl"
                            />
                          </FormGroup>
                        </div>

                        <div className="add-advert-form">
                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="Terms"
                              id="terms"
                              inputProps={{
                                type: "text",
                                name: "terms",
                                autoComplete: "off",
                                // required: true,
                                disabled: advertisementId ? true : false,
                                className: disabledDropdownBlack,
                                value: this.state.terms,
                                inputRef: input => (this.terms = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.termsValid}
                              formErrors={this.state.formErrors}
                              fieldName="terms"
                            />
                          </FormGroup>
                        </div>
                      </GridItem>

                      <GridItem md={12} sm={12} xs={12}>
                        <div className="add-advert-form" style={{display:"none"}}>
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                className={"required-label"}
                                htmlFor="advertisementStatus"
                              >
                                Status
                              </InputLabel>
                              <Select
                                id="advertisementStatus"
                                color="secondary"
                                name="advertisementStatus"
                                className={disabledDropdownBlack}
                                value={this.state.advertisementStatus}
                                width="100px"
                                disabled={true}
                                onChange={event =>
                                  this.handleChange(
                                    event.target.value,
                                    "advertisementStatus"
                                  )
                                }
                                input={<Input id="advertisementStatusInput" />}
                              >
                                <MenuItem value="">Select Status</MenuItem>
                                {Object.keys(AdvertisementStatus).map(
                                  status => {
                                    return (
                                      <MenuItem key={status} value={status}>
                                        {AdvertisementStatus[status]}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                              <FormErrors
                                show={!this.state.advertisementStatusValid}
                                formErrors={this.state.formErrors}
                                fieldName="advertisementStatus"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>
                      </GridItem>
                      <GridItem md={12} xs={12}>
                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                className={"required-label"}
                                htmlFor="Asset List"
                              >
                                Assets List
                              </InputLabel>

                              <Select
                                id="assets"
                                color="secondary"
                                name="assets"
                                value={assets}
                                className={disabledDropdownBlack}
                                width="100px"
                                multiple={true}
                                onChange={this.handleMultiSelectAsset}
                                input={<Input id="assets" />}
                              >
                                <MenuItem
                                  disabled
                                  className="disabled-menu"
                                  value=""
                                >
                                  Select Assets{" "}
                                </MenuItem>
                                {this.state.assetsJson
                                  ? this.state.assetsJson.map(
                                      assetData => {
                                        return (
                                          <MenuItem
                                            key={assetData.assetId}
                                            value={assetData.assetId}
                                          >
                                            {((assetData.name) ? assetData.name : "Unknown") +"("+assetData.assetType+")"}
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : null}
                              </Select>
                              <FormErrors
                                show={!this.state.assetsValid}
                                formErrors={this.state.formErrors}
                                fieldName="assets"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>

                        <div className="add-advert-form">
                          <FormGroup>
                            <FormControl
                              className={mat_select}
                              disabled={advertisementId ? true : false}
                            >
                              <InputLabel
                                // className="required-label"
                                htmlFor="vendors"
                              >
                                Vendors
                              </InputLabel>
                              <Select
                                id="vendors"
                                // multiple
                                color="secondary"
                                name="vendors"
                                value={vendors}
                                width="100px"
                                className={disabledDropdownBlack}
                                onChange={this.handleMultiSelectVendor}
                                input={<Input id="brandsInput" />}
                              >
                                <MenuItem
                                  disabled
                                  className="disabled-menu"
                                  value=""
                                >
                                  Select Vendors
                                </MenuItem>
                                {this.state.vendorJson
                                  ? this.state.vendorJson.map(
                                      (type, typeIndex) => {
                                        return (
                                          <MenuItem
                                            key={typeIndex}
                                            value={type.vendorId}
                                          >
                                            {type.name}
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : null}
                              </Select>
                              <FormErrors
                                show={!this.state.vendorsValid}
                                formErrors={this.state.formErrors}
                                fieldName="vendors"
                              />
                            </FormControl>
                          </FormGroup>
                        </div>
                      </GridItem>
                      { !this.state.isAwareness ? 
                      <FormGroup className="fullwidth">
                        {!advertisementId ? (
                          <GridItem md={12} sm={12} xs={12}>
                            <div className="add-advert-form50">
                              <span className={"claim-text"}>Claims</span>
                              <button
                                type="button"
                                onClick={this.addMoreClaims}
                                className={"add-claim"}
                              >
                                <span>+</span> add claim
                              </button>
                            </div>
                          </GridItem>
                        ) : (
                          ""
                        )}
                        {!advertisementId
                          ? this.state.claims.map((claim, key) => {
                              return (
                                <React.Fragment key={key}>
                                  {key > 0 && this.state.claims.length > 1 ? (
                                    <GridItem md={12} sm={12} xs={12}>
                                      <div className="add-advert-form50">
                                        <span className={"claim-text"}></span>
                                        <button
                                          type="button"
                                          onClick={this.removeClaims}
                                          className={"add-claim"}
                                          index={key}
                                        >
                                          <span>-</span> Remove claim
                                        </button>
                                      </div>
                                    </GridItem>
                                  ) : (
                                    ""
                                  )}
                                  <GridItem xs={12} md={12} sm={12}>
                                    <div className="add-advert-form">
                                      <FormGroup>
                                        <FormControl className={mat_select}>
                                          <InputLabel
                                            className="required-label"
                                            htmlFor={`savingsType_${key}`}
                                          >
                                            Saving Type
                                          </InputLabel>
                                          <Select
                                            id={`savingsType_${key}`}
                                            color="secondary"
                                            name={`savingsType_${key}`}
                                            value={claim.savingsType}
                                            width="100px"
                                            onChange={event =>
                                              this.handleClaimField(
                                                event.target.value,
                                                "savingsType",
                                                key
                                              )
                                            }
                                            input={
                                              <Input
                                                id={`savingsTypeInput_${key}`}
                                              />
                                            }
                                          >
                                            <MenuItem value="">
                                              Select Type
                                            </MenuItem>
                                            {savingsTypeValues
                                              ? Object.keys(
                                                  savingsTypeValues
                                                ).map(savings => {
                                                  return (
                                                    <MenuItem
                                                      key={savings}
                                                      value={savings}
                                                    >
                                                      {savingsTypeValues[savings]}
                                                    </MenuItem>
                                                  );
                                                })
                                              : null}
                                          </Select>
                                          <FormErrors
                                            show={!claim.savingsTypeValid}
                                            formErrors={claim.formErrors}
                                            fieldName="savingsType"
                                          />
                                        </FormControl>
                                      </FormGroup>
                                    </div>

                                    <div className="add-advert-form">
                                      <FormGroup className={"cusForm"}>
                                        <CustomInput
                                          labelText="Savings"
                                          labelProps={{
                                            className: "required-label"
                                          }}
                                          id={`savings_${key}`}
                                          inputProps={{
                                            type: "text",
                                            name: `savings_${key}`,
                                            autoComplete: "off",
                                            value: claim.savings,
                                            // required: true,
                                            data_claim_index: key,
                                            onChange: event =>
                                              this.handleClaimField(
                                                event.target.value,
                                                "savings",
                                                key
                                              )
                                            // inputRef: input =>
                                            //   (claim.savings = input),
                                          }}
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                        />
                                        <FormErrors
                                          show={!claim.savingsValid}
                                          formErrors={claim.formErrors}
                                          fieldName="savings"
                                        />
                                      </FormGroup>
                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} md={12} sm={12}>
                                    <div className="add-advert-form">
                                      <FormGroup>
                                        <FormControl className={mat_select}>
                                          <InputLabel
                                            className="required-label"
                                            htmlFor={`redemptionType_${key}`}
                                          >
                                            Redemption Type
                                          </InputLabel>
                                          <Select
                                            id={`redemptionType_${key}`}
                                            color="secondary"
                                            name={`redemptionType_${key}`}
                                            value={claim.redemptionType}
                                            width="100px"
                                            onChange={event =>
                                              this.handleClaimField(
                                                event.target.value,
                                                "redemptionType",
                                                key
                                              )
                                            }
                                            input={
                                              <Input
                                                id={`redemptionTypeInput_${key}`}
                                              />
                                            }
                                          >
                                            <MenuItem value="">
                                              Select Type
                                            </MenuItem>
                                            {redemptionTypeValues
                                              ? Object.keys(
                                                  redemptionTypeValues
                                                ).map(type => {
                                                  return (
                                                    <MenuItem
                                                      key={type}
                                                      value={type}
                                                    >
                                                      {redemptionTypeValues[type]}
                                                    </MenuItem>
                                                  );
                                                })
                                              : null}
                                          </Select>
                                          <FormErrors
                                            show={!claim.redemptionTypeValid}
                                            formErrors={claim.formErrors}
                                            fieldName="redemptionType"
                                          />
                                        </FormControl>
                                      </FormGroup>
                                    </div>

                                    <div className="add-advert-form">
                                      <FormGroup className={"cusForm"}>
                                        <CustomInput
                                          labelText="Redemption Code"
                                          labelProps={{
                                            className: "required-label"
                                          }}
                                          id={`redemptionCode_${key}`}
                                          inputProps={{
                                            type: "text",
                                            name: `redemptionCode_${key}`,
                                            autoComplete: "off",
                                            value: claim.redemptionCode,
                                            // required: true,
                                            data_claim_index: key,
                                            // inputRef: input =>
                                            //   (claim.redemptionCode = input),
                                            onChange: event =>
                                              this.handleClaimField(
                                                event.target.value,
                                                "redemptionCode",
                                                key
                                              )
                                          }}
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                        />
                                        <FormErrors
                                          show={!claim.redemptionCodeValid}
                                          formErrors={claim.formErrors}
                                          fieldName="redemptionCode"
                                        />
                                      </FormGroup>
                                    </div>
                                  </GridItem>
                                  {/* <GridItem md={12} xs={12}>
                                <div className="add-advert-form50">
                                  <FormGroup>
                                    <FormGroup className={"cusForm"}>
                                      <CustomInput
                                        labelText="Description"
                                        id="description"
                                        inputProps={{
                                          type: "text",
                                          name: "description",
                                          autoComplete: "off",
                                          required: true,
                                          value: claim.description,
                                          data_claim_index: key,
                                          // inputRef: input =>
                                          //   (claim.description = input),
                                          onChange: event =>
                                            this.handleClaimField(event)
                                        }}
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                      />
                                      <FormErrors
                                        show={!claim.descriptionValid}
                                        formErrors={claim.formErrors}
                                        fieldName="description"
                                      />
                                    </FormControl>
                                  </FormGroup>
                                </div>
                              </GridItem> */}
                                </React.Fragment>
                              );
                            })
                          : ""}
                        </FormGroup>
                      : "" }
                    </GridContainer>

                    <div className="form-button-cover">
                      <Button
                        // variant="contained"
                        // color="primary"
                        className={this.state.loading ? "buttonSuccess" : ""}
                        disabled={this.state.loading || !this.validateForm()}
                        type="submit"
                      >
                        Create
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className="buttonProgress"
                          />
                        )}
                      </Button>
                      <Button
                        //disabled={!this.validateForm()}
                        onClick={this.resetForm}
                        type="button"
                      >
                        Reset
                      </Button>
                      <Button
                        className="back-btn"
                        color="secondary"
                        type="button"
                      >
                        <Link
                          color="textSecondary"
                          underline="none"
                          className="cancel-back-btn"
                          to={
                            advertisementId
                              ? baseRoutes.advertisementDetail.useLink +
                                advertisementId
                              : basePath + baseRoutes.advertisement.path
                          }
                        >
                          Cancel
                        </Link>
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

AddAdvertisementClass.propTypes = {
  classes: PropTypes.object
};
const AddAdvertisement = connect(
  mapStateToProps, mapDispatchToProps
)(AddAdvertisementClass);
export default withStyles(styles)(AddAdvertisement);
