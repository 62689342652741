import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import advert from "assets/img/advert.png";
import assetsIcon from "assets/img/assets.png";
import vendor from "assets/img/vendor.png";
import featured from "assets/img/featured.png";
import clock from "assets/img/clock.png";
import enMsg from "__helpers/locale/en/en";
import AddAlert from "@material-ui/icons/AddAlert";
import { Chart } from "react-google-charts";


import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // styles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link as MaterialLink,
  CircularProgress,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  Input,
  Snackbar,
  LinearProgress
} from "@material-ui/core";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { userService } from "_services/user.service";
import {
  STORED_ADNETWORK,
  STORED_CAMPAIGN,
  STORED_ADVERTISEMENT,
  STORED_ASSETS,
  STORED_VENDORS,
  SELECTED_ADNETWORK
} from "__helpers/constants";
import { NotificationContainer } from "react-notifications";
import { SELECTED_ADNETWORK_DATA } from "__helpers/constants";
import { STORED_ASSETS_ANALYTICS } from "__helpers/constants";
import { STORED_ADVERTISEMENT_ANALYTICS } from "__helpers/constants";
import { STORED_CAMPAIGN_ANALYTICS } from "__helpers/constants";
import CampaignAnalytics from "./CampaignAnalytics";
import AdvertisementAnalytics from "./AdvertisementAnalytics";
import AssetAnalytics from "./AssetAnalytics";
import { SELECTED_CAMPAIGN } from "__helpers/constants";
import { connect } from "react-redux";
// import { addArticle } from "../actions/index";
import { addArticle } from "../../js/actions/index";
import moment from "moment";
import { ANALYTICS_CSV_DATA, ANALYTICS_CSV_ADVERTISEMENT_DATA } from "__helpers/constants";

const ref = React.createRef();
const options = {
  orientation: 'landscape',
  unit: 'in',
  format: [4, 2]
};
function mapDispatchToProps(dispatch) {
  return {
    addArticle: article => dispatch(addArticle(article))
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    userInfo: state.userInfo,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class DashboardClass extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    let campaignsData = [];
    let assetsData = [];
    let advertisementData = [];
    let vendorData = [];
    let campaigns = 0;
    let vendors = 0;
    let assets = 0;
    let advertisement = 0;
    // if (this.props.adNetwork) {
    //   let selectedAdnetworkData = this.props.adNetwork;
    //   selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
    //   adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
    // }
    if (this.props.campaings) {
      let jsonData = this.props.campaings;
      campaignsData = (jsonData) ? jsonData : [];
      campaigns = campaignsData ? campaignsData.length : 0;
    }
    if (this.props.assets) {
      let jsonData = this.props.assets;
      assetsData = (jsonData) ? jsonData : [];
      assets = assetsData ? assetsData.length : 0;
    }
    if (this.props.vendors) {
      let jsonData = this.props.vendors;
      vendorData = (jsonData) ? jsonData : [];
      vendors = vendorData ? vendorData.length : 0;
    }
    if (this.props.advertisements) {
      let jsonData = this.props.advertisements;
      advertisementData = (jsonData) ? jsonData : [];
      advertisement = advertisementData ? advertisementData.length : 0;
    }

    let adNetworksData = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let adNetworks = adNetworksData ? adNetworksData.length : 0;

    // let campaignsData = JSON.parse(localStorage.getItem(STORED_CAMPAIGN));
    // let campaigns = campaignsData ? campaignsData.length : 0;

    // let advertisementData = JSON.parse(localStorage.getItem(STORED_ADVERTISEMENT));
    // let advertisement = advertisementData ? advertisementData.length : 0;

    // let assetsData = JSON.parse(localStorage.getItem(STORED_ASSETS));
    // let assets = assetsData ? assetsData.length : 0;

    // let vendors = JSON.parse(localStorage.getItem(STORED_VENDORS));
    // vendors = vendors ? vendors.length : 0;

    let tokenTimeStamp = localStorage.getItem("tokenTimeStamp");
    var dateTimeVal = new Date(+tokenTimeStamp);
    var validDateBoolean = moment(dateTimeVal);

    let selectedAdnetwork = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    selectedAdnetwork = (selectedAdnetwork) ? selectedAdnetwork.adNetworkId : "";
    let selectedCampaignData = JSON.parse(localStorage.getItem(SELECTED_CAMPAIGN));
    let selectedCampaign = (selectedCampaignData) ? selectedCampaignData.campaignId : "";

    this.adNetworkSelectBox = React.createRef(null);

    let advertisementAnalytics = JSON.parse(localStorage.getItem(STORED_ADVERTISEMENT_ANALYTICS));
    let assetAnalytics = JSON.parse(localStorage.getItem(STORED_ASSETS_ANALYTICS));
    let campaignAnalytics = JSON.parse(localStorage.getItem(STORED_CAMPAIGN_ANALYTICS));
    let csvSheetDataCampaign = JSON.parse(localStorage.getItem(ANALYTICS_CSV_DATA));
    let csvSheetDataAdvertisement = JSON.parse(localStorage.getItem(ANALYTICS_CSV_ADVERTISEMENT_DATA));

    this.state = {
      value: 0,
      adnetworkId: (selectedAdnetwork) ? selectedAdnetwork : "",
      campaignId: (selectedCampaign) ? selectedCampaign : "",
      selectedCampaignData: (selectedCampaignData) ? selectedCampaignData : [],
      adNetworks: adNetworks,
      adNetworkJson: (adNetworksData) ? adNetworksData : [],
      campaignsJson: (campaignsData) ? campaignsData : [],
      timeStampVal: new Date().toLocaleTimeString(),
      lastAdNetworks: "2 mins ago",
      campaigns: campaigns,
      lastCampaigns: "10 mins ago",
      advertisement: advertisement,
      lastAdvertisement: "10 mins ago",
      assets: assets,
      lastAssets: "Just now",
      vendors: vendors,
      lastVendors: "Just now",
      user: {},
      users: "Private",
      lastUsers: "30 mins ago",
      tr: false,
      advertisementList: (advertisementData) ? advertisementData : [],
      assetsList: (assetsData) ? assetsData : [],
      campaignAnalytics: (campaignAnalytics) ? campaignAnalytics : [],
      advertisementAnalytics: (advertisementAnalytics) ? advertisementAnalytics : [],
      assetAnalytics: (assetAnalytics) ? assetAnalytics : [],
      reduxLoadFlag: false,
      csvSheetDataCampaign: (csvSheetDataCampaign) ? csvSheetDataCampaign : [],
      csvSheetDataAdvertisement: (csvSheetDataAdvertisement) ? csvSheetDataAdvertisement : [],
    };
    this.adNetworkChange = this.adNetworkChange.bind(this);
    // this.showNotification = this.showNotification.bind(this);
    this.fetchAnalyticsData = this.fetchAnalyticsData.bind(this);
    this.campaignChange = this.campaignChange.bind(this);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    this._isMounted = true;
    // reauthorize token request...
    userService.refreshClientAouth();
    if (!this.state.adnetworkId) {
      let showNotification = {
        title: enMsg.selectAdnetworkTitle,
        message: enMsg.selectAdnetwork,
        type: "quickInfoAlert"
      };
      // userService.showNotification(showNotification)
    }
    let selectedAdnetwork = "";
    let adnetworkData = [];
    let campaignData = [];
    let assetsData = [];
    let advertisementData = [];
    let vendorData = [];
    if (this.props.adNetwork) {
      let selectedAdnetworkData = this.props.adNetwork;
      selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
      adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
    }
    if (this.props.campaings) {
      let jsonData = this.props.campaings;
      campaignData = (jsonData) ? jsonData : [];
    }
    if (this.props.assets) {
      let jsonData = this.props.assets;
      assetsData = (jsonData) ? jsonData : [];
    }
    if (this.props.vendors) {
      let jsonData = this.props.vendors;
      vendorData = (jsonData) ? jsonData : [];
    }
    if (this.props.advertisements) {
      let jsonData = this.props.advertisements;
      advertisementData = (jsonData) ? jsonData : [];
    }
    // this.setState({
    //   reduxLoadFlag: false,
    //   campaignsJson: (campaignData) ? campaignData : [],
    //   campaigns: campaignData.length,
    //   assets: assetsData.length,
    //   vendors: vendorData.length,
    //   advertisement: advertisementData.length,
    //   adnetworkId: selectedAdnetwork,
    //   adNetworks: adnetworkData.length,
    //   adNetworkJson: adnetworkData,
    //   advertisementList: (advertisementData) ? advertisementData : [],
    //   assetsList: (assetsData) ? assetsData : [],
    //   campaignAnalytics: [],
    //   advertisementAnalytics: [],
    //   assetAnalytics: [], 
    //   campaignId: "",
    //   selectedCampaignData: [],
    // })
  }

  componentDidUpdate() {
    if (this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = "";
      let adnetworkData = [];
      let campaignData = [];
      let assetsData = [];
      let advertisementData = [];
      let vendorData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      if (this.props.campaings) {
        let jsonData = this.props.campaings;
        campaignData = (jsonData) ? jsonData : [];
      }
      if (this.props.assets) {
        let jsonData = this.props.assets;
        assetsData = (jsonData) ? jsonData : [];
      }
      if (this.props.vendors) {
        let jsonData = this.props.vendors;
        vendorData = (jsonData) ? jsonData : [];
      }
      if (this.props.advertisements) {
        let jsonData = this.props.advertisements;
        advertisementData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        campaignsJson: (campaignData) ? campaignData : [],
        campaigns: campaignData.length,
        assets: assetsData.length,
        vendors: vendorData.length,
        advertisement: advertisementData.length,
        adnetworkId: selectedAdnetwork,
        adNetworks: adnetworkData.length,
        adNetworkJson: adnetworkData,
        advertisementList: (advertisementData) ? advertisementData : [],
        assetsList: (assetsData) ? assetsData : [],
        campaignAnalytics: [],
        advertisementAnalytics: [],
        assetAnalytics: [],
        campaignId: "",
        selectedCampaignData: [],
      })
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
    if (this.state.adnetworkId) {
      // window.history.back();
    }
  }
  showNotification(place) {
    var x = [];
    x[place] = true;
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      6000
    );
  }
  async fetchData() {
    let adNetworks = [];
    let campaigns = [];
    let advertisement = [];
    let assets = [];
    let vendors = [];
    try {
      adNetworks = await userService.fetchAdNetwork();
      this._isMounted &&
        this.setState({
          adNetworkJson: adNetworks,
          adNetworks: adNetworks.length,
        });
      if (this.state.adnetworkId) {
        campaigns = await userService.fetchCampagin(this.state.adnetworkId);
        if (campaigns) {
          campaigns.map((campaign, key) => {
            if (campaign.advertisements && campaign.advertisements.length) {
              Array.prototype.push.apply(advertisement, campaign.advertisements);
            }
          });
        }
        localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisement));

        vendors = await userService.fetchAllVendors(this.state.adnetworkId);
        assets = await userService.fetchAllAssets(this.state.adnetworkId);

        this._isMounted &&
          this.setState({
            campaigns: campaigns.length,
            advertisement: advertisement.length,
            assets: assets.length,
            vendors: vendors.length,
            campaignsJson: campaigns,
          });
      }
    } catch (errors) {
    }
  }
  async adNetworkChange(e) {
    let selectedVal = e.target.value;
    if (selectedVal) {
      this.setState({ adnetworkId: selectedVal });
      this.state.adNetworkJson.map(adNetwork => {
        if (adNetwork.adNetworkId == selectedVal) {
          localStorage.setItem(SELECTED_ADNETWORK, JSON.stringify(adNetwork));
        }
      })
      // localStorage.setItem(SELECTED_ADNETWORK, JSON.stringify(selectedVal));
      let assets = [];
      let vendors = [];
      let campaigns = [];
      let advertisement = [];
      try {
        campaigns = await userService.fetchCampagin(selectedVal);
        if (campaigns) {
          campaigns.map((campaign, key) => {
            if (campaign.advertisements && campaign.advertisements.length) {
              Array.prototype.push.apply(advertisement, campaign.advertisements);
            }
          });
        }
        localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisement));
        vendors = await userService.fetchAllVendors(selectedVal);
        assets = await userService.fetchAllAssets(selectedVal);

        this._isMounted &&
          this.setState({
            campaigns: campaigns.length,
            advertisement: advertisement.length,
            assets: assets.length,
            vendors: vendors.length
          });
      } catch (errors) {
      }
    }
  }
  async fetchAnalyticsData(campaignId) {
    let campaigns = [];
    let advertisements = [];
    let assets = [];
    let campaignsData = [];
    let advertisementsData = [];
    let assetsData = [];
    let csvSheetDataCampaign = [];
    let csvSheetDataAdvertisement = [];

    try {
      if (this.state.adnetworkId) {
        campaigns = await userService.fetchAnalytics(this.state.adnetworkId, campaignId);
        let tempAdArr = {};
        let tempAssetArr = {};
        if (campaigns) {
          let campaignKpi = campaigns.campaignKpi;
          //console.log(campaignKpi);
          let userAction = campaignKpi.userActionRates;
          console.log(userAction);
          let clickThrough = campaignKpi.clickThroughRate;
          // console.log(clickthroughrate);
          //campaigns.ClickThroughRate = campaignKpi.clickThroughRate;
          campaigns.ClickThroughRate = clickThrough;

          //console.log(campaigns.ClickThroughRate);

          //console.log(userAction);
          campaigns.ViewActionRate = userAction.view;
          //console.log(userAction.view);
          campaigns.ClickActionRate = userAction.click;
          campaigns.SaveActionRate = userAction.save;
          campaigns.RedeemActionRate = userAction.redeem;
          campaigns.DeleteActionRate = userAction.delete;
          campaigns.ShareActionRate = userAction.share;
          campaigns.SharedViewActionRate = userAction.sharedView;
          campaigns.ClaimActionRate = userAction.claim;

          //here  we extract
          let conversionRates = campaignKpi.conversionRates;
          //console.log(conversionRates);
          campaigns.ViewConversionRate = conversionRates.view*100;
          //  console.log(ViewConversionRate)
          campaigns.ClickConversionRate = conversionRates.click*100;
          campaigns.SaveConversionRate = conversionRates.save*100;
          campaigns.DeleteConversionRate = conversionRates.delete*100;
          campaigns.ShareConversionRate = conversionRates.share*100;
          campaigns.SharedViewConversionRate = conversionRates.sharedView*100;
          campaigns.ClaimConversionRate = conversionRates.claim*100;




          console.log(campaigns);
          // table and charts analytics data
          advertisements = campaigns.advertisementAnalytics;


          if (advertisements) {
            advertisements.map((advertisement, key) => {
              if (advertisement.assetAnalytics && advertisement.assetAnalytics.length) {
                Array.prototype.push.apply(assets, advertisement.assetAnalytics);
              }
              tempAdArr["totalPresenceReached"] = (tempAdArr["totalPresenceReached"]) ? tempAdArr["totalPresenceReached"] : 0 + advertisement.totalPresenceReached;
              tempAdArr["totalDevicesReached"] = (tempAdArr["totalDevicesReached"]) ? tempAdArr["totalDevicesReached"] : 0 + advertisement.totalDevicesReached;
              tempAdArr["totalApplicationsReached"] = (tempAdArr["totalApplicationsReached"]) ? tempAdArr["totalApplicationsReached"] : 0 + advertisement.totalApplicationsReached;
              tempAdArr["totalAssetImpressions"] = (tempAdArr["totalAssetImpressions"]) ? tempAdArr["totalAssetImpressions"] : 0 + advertisement.totalAssetImpressions;
              tempAdArr["totalAssetPostImpressions"] = (tempAdArr["totalAssetPostImpressions"]) ? tempAdArr["totalAssetPostImpressions"] : 0 + advertisement.totalAssetPostImpressions;
              tempAdArr["totalAssetViews"] = (tempAdArr["totalAssetViews"]) ? tempAdArr["totalAssetViews"] : 0 + advertisement.totalAssetViews;
              tempAdArr["totalAssetClicks"] = (tempAdArr["totalAssetClicks"]) ? tempAdArr["totalAssetClicks"] : 0 + advertisement.totalAssetClicks;
              tempAdArr["totalAssetSaves"] = (tempAdArr["totalAssetSaves"]) ? tempAdArr["totalAssetSaves"] : 0 + advertisement.totalAssetSaves;
              tempAdArr["totalAssetRedeems"] = (tempAdArr["totalAssetRedeems"]) ? tempAdArr["totalAssetRedeems"] : 0 + advertisement.totalAssetRedeems;
              tempAdArr["totalAssetDeletes"] = (tempAdArr["totalAssetDeletes"]) ? tempAdArr["totalAssetDeletes"] : 0 + advertisement.totalAssetDeletes;
              tempAdArr["totalAssetShares"] = (tempAdArr["totalAssetShares"]) ? tempAdArr["totalAssetShares"] : 0 + advertisement.totalAssetShares;
              tempAdArr["totalAssetClaims"] = (tempAdArr["totalAssetClaims"]) ? tempAdArr["totalAssetClaims"] : 0 + advertisement.totalAssetClaims;
              tempAdArr["totalExternalApplicationsReached"] = (tempAdArr["totalExternalApplicationsReached"]) ? tempAdArr["totalExternalApplicationsReached"] : 0 + advertisement.totalExternalApplicationsReached;
              tempAdArr["totalExternalDevicesReached"] = (tempAdArr["totalExternalDevicesReached"]) ? tempAdArr["totalExternalDevicesReached"] : 0 + advertisement.totalExternalDevicesReached;
              tempAdArr["totalSharedAssetContentViews"] = (tempAdArr["totalSharedAssetContentViews"]) ? tempAdArr["totalSharedAssetContentViews"] : 0 + advertisement.totalSharedAssetContentViews;
            });

            if (assets) {
              assets.map((asset, key) => {
                tempAssetArr["numApplicationsReached"] = (tempAssetArr["numApplicationsReached"]) ? tempAssetArr["numApplicationsReached"] : 0 + asset.numApplicationsReached;
                tempAssetArr["numClaims"] = (tempAssetArr["numClaims"]) ? tempAssetArr["numClaims"] : 0 + asset.numClaims;
                tempAssetArr["numClicks"] = (tempAssetArr["numClicks"]) ? tempAssetArr["numClicks"] : 0 + asset.numClicks;
                tempAssetArr["numDeletes"] = (tempAssetArr["numDeletes"]) ? tempAssetArr["numDeletes"] : 0 + asset.numDeletes;
                tempAssetArr["numImpressions"] = (tempAssetArr["numImpressions"]) ? tempAssetArr["numImpressions"] : 0 + asset.numImpressions;
                tempAssetArr["numPostImpressions"] = (tempAssetArr["numPostImpressions"]) ? tempAssetArr["numPostImpressions"] : 0 + asset.numPostImpressions;
                tempAssetArr["numRedeems"] = (tempAssetArr["numRedeems"]) ? tempAssetArr["numRedeems"] : 0 + asset.numRedeems;
                tempAssetArr["numSaves"] = (tempAssetArr["numSaves"]) ? tempAssetArr["numSaves"] : 0 + asset.numSaves;
                tempAssetArr["numShares"] = (tempAssetArr["numShares"]) ? tempAssetArr["numShares"] : 0 + asset.numShares;
                tempAssetArr["numViews"] = (tempAssetArr["numViews"]) ? tempAssetArr["numViews"] : 0 + asset.numViews;
                tempAssetArr["numDevicesReached"] = (tempAssetArr["numDevicesReached"]) ? tempAssetArr["numDevicesReached"] : 0 + asset.numDevicesReached;
                tempAssetArr["numExternalApplicationsReached"] = (tempAssetArr["numExternalApplicationsReached"]) ? tempAssetArr["numExternalApplicationsReached"] : 0 + asset.numExternalApplicationsReached;
                tempAssetArr["numExternalDevicesReached"] = (tempAssetArr["numExternalDevicesReached"]) ? tempAssetArr["numExternalDevicesReached"] : 0 + asset.numExternalDevicesReached;
                tempAssetArr["numPresenceReached"] = (tempAssetArr["numPresenceReached"]) ? tempAssetArr["numPresenceReached"] : 0 + asset.numPresenceReached;
                tempAssetArr["numSharedContentViews"] = (tempAssetArr["numSharedContentViews"]) ? tempAssetArr["numSharedContentViews"] : 0 + asset.numSharedContentViews;
              });
            }
          }
        }
        campaignsData = { campaigns: campaigns };
        advertisementsData = { statics: tempAdArr, advertisements: advertisements };
        assetsData = { statics: tempAssetArr, assets: assets };

        localStorage.setItem(STORED_CAMPAIGN_ANALYTICS, JSON.stringify(campaignsData));
        localStorage.setItem(STORED_ADVERTISEMENT_ANALYTICS, JSON.stringify(advertisementsData));
        // localStorage.setItem(STORED_ASSETS_ANALYTICS, JSON.stringify(assetsData));

        // excel/csv sheet data
        if (campaigns) {
          let tempAdArrLocal = {};
          tempAdArrLocal["type"] = "Campaign";
          tempAdArrLocal["name"] = campaigns.campaignName;
          tempAdArrLocal["id"] = campaigns.campaignId;
          tempAdArrLocal["adNetworkId"] = campaigns.adNetworkId;
          tempAdArrLocal["status"] = campaigns.campaignStatus;
          tempAdArrLocal["totalImpressions"] = 0 + campaigns.totalAdImpressions;
          tempAdArrLocal["totalPostImpressions"] = 0 + campaigns.totalAdPostImpressions;
          tempAdArrLocal["totalViews"] = 0 + campaigns.totalAdViews;
          tempAdArrLocal["totalClicks"] = 0 + campaigns.totalAdClicks;
          tempAdArrLocal["totalSaves"] = 0 + campaigns.totalAdSaves;
          tempAdArrLocal["totalShares"] = 0 + campaigns.totalAdShares;
          tempAdArrLocal["totalSharedContentViews"] = 0 + campaigns.totalSharedAdContentViews;
          tempAdArrLocal["totalClaims"] = 0 + campaigns.totalAdClaims;
          tempAdArrLocal["totalDeletes"] = 0 + campaigns.totalAdDeletes;
          tempAdArrLocal["totalRedeems"] = 0 + campaigns.totalAdRedeems;
          tempAdArrLocal["totalApplicationsReached"] = 0 + campaigns.totalApplicationsReached;
          tempAdArrLocal["totalPresenceReached"] = 0 + campaigns.totalPresenceReached;
          tempAdArrLocal["totalDevicesReached"] = 0 + campaigns.totalDevicesReached;
          tempAdArrLocal["totalExternalApplicationsReached"] = 0 + campaigns.totalExternalApplicationsReached;
          tempAdArrLocal["totalExternalDevicesReached"] = 0 + campaigns.totalExternalDevicesReached;
          tempAdArrLocal["ViewActionRate"] = 0 + campaigns.ViewActionRate;
          tempAdArrLocal["ClickActionRate"] = 0 + campaigns.ClickActionRate;
          tempAdArrLocal["SavekActionRate"] = 0 + campaigns.SaveActionRate;
          tempAdArrLocal["RedeemActionRate"] = 0 + campaigns.RedeemActionRate;
          tempAdArrLocal["DeleteActionRate"] = 0 + campaigns.DeleteActionRate;
          tempAdArrLocal["ShareActionRate"] = 0 + campaigns.ShareActionRate;
          tempAdArrLocal["SharedViewActionRate"] = 0 + campaigns.SharedViewActionRate;
          tempAdArrLocal["ClaimActionRate"] = 0 + campaigns.ClaimActionRate;
          tempAdArrLocal["ViewConversionRate"] = 0 + campaigns.ViewConversionRate;
          tempAdArrLocal["ClickConversionRate"] = 0 + campaigns.ClickConversionRate;
          tempAdArrLocal["SaveConversionRate"] = 0 + campaigns.SaveConversionRate;
          tempAdArrLocal["DeleteConversionRate"] = 0 + campaigns.DeleteConversionRate;
          tempAdArrLocal["ShareConversionRate"] = 0 + campaigns.ShareConversionRate;
          tempAdArrLocal["SharedViewonversionRate"] = 0 + campaigns.SharedViewConversionRate;
          tempAdArrLocal["ClaimConversionRate"] = 0 + campaigns.ClaimConversionRate;
          tempAdArrLocal["ClickThroughRate"] = 0 + campaigns.ClickThroughRate;
          //  console.log(tempAdArrLocal)
          //console.log(campaigns.ClickThroughRate);

          csvSheetDataCampaign.push(tempAdArrLocal);
          console.log(csvSheetDataCampaign);
          advertisements.map((advertisement, key) => {
            let tempAdArrLocal = {};
            let advertisementKpi = advertisement.adKpi;
            // console.log(advertisementKpi);
            let clickThrough = advertisementKpi.clickThroughRate;
            advertisement.ClickThroughRate = clickThrough;
            // console.log(clickthroughrate);

            //advertisement.ClickThroughRate = clickThrough;


            //console.log(advertisementKpi);
            //console.log(advertisement);
            let userAction = advertisementKpi.userActionRates;

            console.log(userAction)
            let conversionRates = advertisementKpi.conversionRates;
            if (conversionRates) {
              advertisement.ViewConversionRate = conversionRates.view*100;
              advertisement.ClickConversionRate = conversionRates.click*100;
              advertisement.SaveConversionRate = conversionRates.save*100;
              advertisement.DeleteConversionRate = conversionRates.delete*100;
              advertisement.ShareConversionRate = conversionRates.share*100;
              advertisement.SharedViewConversionRate = conversionRates.sharedView*100;
              advertisement.ClaimConversionRate = conversionRates.claim*100;

            }
            else {
              advertisement.ViewConversionRate = 0;
              advertisement.ClickConversionRate = 0;
              advertisement.SaveConversionRate = 0;
              advertisement.DeleteConversionRate = 0;
              advertisement.ShareConversionRate = 0;
              advertisement.SharedViewConversionRate = 0;
              advertisement.ClaimConversionRate = 0;

            }
            //console.log(conversionRates)

            advertisement.ViewActionRate = userAction.view;
            // console.log(userAction.view);
            advertisement.ClickActionRate = userAction.click;
            advertisement.SaveActionRate = userAction.save;
            advertisement.RedeemActionRate = userAction.redeem;
            advertisement.DeleteActionRate = userAction.delete;
            advertisement.ShareActionRate = userAction.share;
            advertisement.SharedViewActionRate = userAction.sharedView;
            advertisement.ClaimActionRate = userAction.claim;
            //advertisement.ClickThroughRate = advertisementKpi.clickThrough;

            tempAdArrLocal["type"] = "Advertisement";
            tempAdArrLocal["name"] = advertisement.adName;
            tempAdArrLocal["id"] = advertisement.adId;
            tempAdArrLocal["adType"] = advertisement.adType;
            tempAdArrLocal["adMedium"] = advertisement.adMedium;
            tempAdArrLocal["status"] = advertisement.adStatus;
            let adStartDate = new Date(advertisement.adStartDate * 1000).toUTCString();
            tempAdArrLocal["adStartDate"] = adStartDate;
            let adExpirationDate = new Date(advertisement.adExpirationDate * 1000).toUTCString();
            tempAdArrLocal["adExpirationDate"] = adExpirationDate;
            tempAdArrLocal["totalImpressions"] = 0 + advertisement.totalAssetImpressions;
            tempAdArrLocal["totalPostImpressions"] = 0 + advertisement.totalAssetPostImpressions;
            tempAdArrLocal["totalViews"] = 0 + advertisement.totalAssetViews;
            tempAdArrLocal["totalClicks"] = 0 + advertisement.totalAssetClicks;
            tempAdArrLocal["totalSaves"] = 0 + advertisement.totalAssetSaves;
            tempAdArrLocal["totalShares"] = 0 + advertisement.totalAssetShares;
            tempAdArrLocal["totalSharedContentViews"] = 0 + advertisement.totalSharedAssetContentViews;
            tempAdArrLocal["totalClaims"] = 0 + advertisement.totalAssetClaims;
            tempAdArrLocal["totalDeletes"] = 0 + advertisement.totalAssetDeletes;
            tempAdArrLocal["totalRedeems"] = 0 + advertisement.totalAssetRedeems;
            tempAdArrLocal["totalApplicationsReached"] = 0 + advertisement.totalApplicationsReached;
            tempAdArrLocal["totalPresenceReached"] = 0 + advertisement.totalPresenceReached;
            tempAdArrLocal["totalDevicesReached"] = 0 + advertisement.totalDevicesReached;
            tempAdArrLocal["totalExternalApplicationsReached"] = 0 + advertisement.totalExternalApplicationsReached;
            tempAdArrLocal["totalExternalDevicesReached"] = 0 + advertisement.totalExternalDevicesReached;
            tempAdArrLocal["ViewActionRate"] = 0 + advertisement.ViewActionRate;
            tempAdArrLocal["ClickActionRate"] = 0 + advertisement.ClickActionRate;
            tempAdArrLocal["SavekActionRate"] = 0 + advertisement.SaveActionRate;
            tempAdArrLocal["DeleteActionRate"] = 0 + advertisement.DeleteActionRate;
            tempAdArrLocal["ShareActionRate"] = 0 + advertisement.ShareActionRate;
            tempAdArrLocal["SharedViewActionRate"] = 0 + advertisement.SharedViewActionRate;
            tempAdArrLocal["ClaimActionRate"] = 0 + advertisement.ClaimActionRate;
            tempAdArrLocal["ViewConversionRate"] = 0 + advertisement.ViewConversionRate;
            tempAdArrLocal["ClickConversionRate"] = 0 + advertisement.ClickConversionRate;
            tempAdArrLocal["SaveConversionRate"] = 0 + advertisement.SaveConversionRate;
            tempAdArrLocal["DeleteConversionRate"] = 0 + advertisement.DeleteConversionRate;
            tempAdArrLocal["ShareConversionRate"] = 0 + advertisement.ShareConversionRate;
            tempAdArrLocal["SharedViewConversionRate"] = 0 + advertisement.SharedViewConversionRate;
            tempAdArrLocal["ClaimConversionRate"] = 0 + advertisement.ClaimConversionRate;
            tempAdArrLocal["ClickThroughRate"] = 0 + advertisement.ClickThroughRate;
            csvSheetDataCampaign.push(tempAdArrLocal);
            csvSheetDataAdvertisement.push(tempAdArrLocal);
            console.log(csvSheetDataAdvertisement)
            advertisement.assetAnalytics.map((asset, key) => {
              let tempAssetArrLocal = {};
              let assetKpi = asset.assetKpi;
              let clickThrough = assetKpi.clickThroughRate;
              // console.log(clickthroughrate);
              //campaigns.ClickThroughRate = campaignKpi.clickThroughRate;
              asset.ClickThroughRate = clickThrough;
              console.log(assetKpi);
              let userAction = assetKpi.userActionRates;
              //  console.log(userAction)
              let conversionRates = assetKpi.conversionRates;
              if (conversionRates) {
                asset.ViewConversionRate = conversionRates.view*100;
                asset.ClickConversionRate = conversionRates.click*100;
                asset.SaveConversionRate = conversionRates.save*100;
                asset.DeleteConversionRate = conversionRates.delete*100;
                asset.ShareConversionRate = conversionRates.share*100;
                asset.SharedViewConversionRate = conversionRates.sharedView*100;
                asset.ClaimConversionRate = conversionRates.claim*100;

              }
              else {
                asset.ViewConversionRate = 0;
                asset.ClickConversionRate = 0;
                asset.SaveConversionRate = 0;
                asset.DeleteConversionRate = 0;
                asset.ShareConversionRate = 0;
                asset.SharedViewConversionRate = 0;
                asset.ClaimConversionRate = 0;

              }
              //console.log(conversionRates)

              //     advertisement.ViewActionRate = userAction.view;
              // console.log(userAction.view);
              asset.ViewActionRate = userAction.view;
              asset.ClickActionRate = userAction.click;
              asset.SaveActionRate = userAction.save;
              asset.RedeemActionRate = userAction.redeem;
              asset.DeleteActionRate = userAction.delete;
              asset.ShareActionRate = userAction.share;
              asset.SharedViewActionRate = userAction.sharedView;
              asset.ClaimActionRate = userAction.claim;
              // asset.ClickThroughRate = assetKpi.clickThrough;
              tempAssetArrLocal["type"] = "Asset";
              tempAssetArrLocal["name"] = asset.assetName;
              tempAssetArrLocal["id"] = asset.assetId;
              tempAssetArrLocal["assetType"] = asset.assetType;
              tempAssetArrLocal["displayType"] = asset.displayType;
              tempAssetArrLocal["totalImpressions"] = 0 + asset.numImpressions;
              tempAssetArrLocal["totalPostImpressions"] = 0 + asset.numPostImpressions;
              tempAssetArrLocal["totalViews"] = 0 + asset.numViews;
              tempAssetArrLocal["totalClicks"] = 0 + asset.numClicks;
              tempAssetArrLocal["totalSaves"] = 0 + asset.numSaves;
              tempAssetArrLocal["totalShares"] = 0 + asset.numShares;
              tempAssetArrLocal["totalSharedContentViews"] = 0 + asset.numSharedContentViews;
              tempAssetArrLocal["totalClaims"] = 0 + asset.numClaims;
              tempAssetArrLocal["totalDeletes"] = 0 + asset.numDeletes;
              tempAssetArrLocal["totalRedeems"] = 0 + asset.numRedeems;
              tempAssetArrLocal["totalApplicationsReached"] = 0 + asset.numApplicationsReached;
              tempAssetArrLocal["totalPresenceReached"] = 0 + asset.numPresenceReached;
              tempAssetArrLocal["totalDevicesReached"] = 0 + asset.numDevicesReached;
              tempAssetArrLocal["totalExternalApplicationsReached"] = 0 + asset.numExternalApplicationsReached;
              tempAssetArrLocal["totalExternalDevicesReached"] = 0 + asset.numExternalDevicesReached;
              tempAssetArrLocal["ViewActionRate"] = 0 + asset.ViewActionRate;
              tempAssetArrLocal["ClickActionRate"] = 0 + asset.ClickActionRate;
              tempAssetArrLocal["SavekActionRate"] = 0 + asset.SaveActionRate;
              tempAssetArrLocal["RedeemActionRate"] = 0 + asset.RedeemActionRate;
              tempAssetArrLocal["DeleteActionRate"] = 0 + asset.DeleteActionRate;
              tempAssetArrLocal["ShareActionRate"] = 0 + asset.ShareActionRate;
              tempAssetArrLocal["SharedViewActionRate"] = 0 + asset.SharedViewActionRate;
              tempAssetArrLocal["ClaimActionRate"] = 0 + asset.ClaimActionRate;
              tempAssetArrLocal["ViewConversionRate"] = 0 + asset.ViewConversionRate;
              tempAssetArrLocal["ClickConversionRate"] = 0 + asset.ClickConversionRate;
              tempAssetArrLocal["SaveConversionRate"] = 0 + asset.SaveConversionRate;
              tempAssetArrLocal["DeleteConversionRate"] = 0 + asset.DeleteConversionRate;
              tempAssetArrLocal["ShareConversionRate"] = 0 + asset.ShareConversionRate;
              tempAssetArrLocal["SharedConversionRate"] = 0 + asset.SharedViewConversionRate;
              tempAssetArrLocal["ClaimConversionRate"] = 0 + asset.ClaimConversionRate;
              tempAssetArrLocal["ClickThroughRate"] = 0 + asset.ClickThroughRate;
              csvSheetDataCampaign.push(tempAssetArrLocal);
              csvSheetDataAdvertisement.push(tempAssetArrLocal);
              console.log(csvSheetDataAdvertisement);
            });
          });
          localStorage.setItem(ANALYTICS_CSV_DATA, JSON.stringify(csvSheetDataCampaign));
          localStorage.setItem(ANALYTICS_CSV_ADVERTISEMENT_DATA, JSON.stringify(csvSheetDataAdvertisement));
        }
        console.log(csvSheetDataCampaign);
        this._isMounted &&
          this.setState({
            campaignAnalytics: campaignsData,
            advertisementAnalytics: advertisementsData,
            assetAnalytics: assetsData,
            csvSheetDataCampaign: csvSheetDataCampaign,
            csvSheetDataAdvertisement: csvSheetDataAdvertisement,
            loading: false,

          });
        // localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisement));
      }
    } catch (errors) {
      console.log(errors);
    }
  }
  getCampaignNAme(campaignId) {
    let campaignName = this.state.selectedCampaignData.name
    return campaignName;
  }
  getAdvertisementName(adId) {
    let adName = "Unknown"
    this.state.advertisementList.map(adData => {
      if (adId == adData.adId) {
        adName = (adData.name) ? adData.name : "Unknown";
      }
    });
    return adName;
  }
  getAssetsName(assetId) {
    let adName = "Unknown"
    this.state.assetsList.map(assetData => {
      if (assetId == assetData.assetId) {
        adName = (assetData.name) ? assetData.name : "Unknown";
      }
    });
    return adName;
  }
  campaignChange(e) {
    let selectedVal = e.target.value
    if (selectedVal) {
      // this.props.addArticle({ selectedVal });
      this.setState({
        campaignId: selectedVal,
        loading: true,
        csvSheetDataCampaign: [],
      });
      this.fetchAnalyticsData(selectedVal);

      this.state.campaignsJson.map(campaign => {
        if (campaign.campaignId == selectedVal) {
          localStorage.setItem(SELECTED_CAMPAIGN, JSON.stringify(campaign));
          this.setState({
            selectedCampaignData: campaign,
          });
        }
      })

      // localStorage.setItem(SELECTED_CAMPAIGN, JSON.stringify(selectedVal));
    }
  }
  render() {
    const { classes } = this.props;
    const test = "test-cover";
    const test1 = "test-cover1";
    const test2 = "test-cover2";
    const test3 = "test-cover3";
    const test4 = "test-cover4";
    const clock_cover = "clock-cover";
    const styletest = { padding: "23px" };
    const styletest1 = { padding: "21px 24px" };
    const styletest2 = {
      padding: "20px 23px",
      background: "linear-gradient(60deg, #1666ca, #3c86e1)",
      boxShadow:
        "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(168, 198, 234,.4)"
    };
    const styletest3 = {
      padding: "19px 25px",
      background: "linear-gradient(60deg, #05b0c5, #20c2d6)",
      boxShadow:
        "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(209, 240, 244,.4)"
    };
    const styletest4 = {
      background: "linear-gradient(60deg, #932aad, #a742b9)",
      boxShadow:
        "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(214, 176, 224,.4)"
    };
    const clock_style = {
      width: "15px",
      height: "15px",
      position: "relative",
      top: "3px",
      marginRight: "3px"
    };

    const {
      adNetworks,
      campaigns,
      advertisement,
      assets,
      vendors,
      users,
      lastAdNetworks,
      lastCampaigns,
      lastAdvertisement,
      lastAssets,
      lastVendors,
      lastUsers,
      adnetworkId,
      campaignId,
      campaignsJson,
      timeStampVal
    } = this.state;
    return (
      <div>
        {/* <NotificationContainer/> */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ display: "none" }}>
            <div className="form-cover">
              <FormGroup>
                <FormControl className={"mat_select"}>
                  <InputLabel shrink htmlFor="campaigns">
                    Select AdNetWork
                  </InputLabel>
                  <Select
                    id="adnetwork"
                    color="secondary"
                    name="adnetwork"
                    value={adnetworkId}
                    width="100px"
                    onChange={this.adNetworkChange}
                    input={<Input value={adnetworkId} id="campaignsInput" />}
                    inputProps={{
                      required: true,
                      inputRef: this.adNetworkSelectBox
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">Select AdNetwork</MenuItem>
                    {typeof this.state.adNetworkJson === "object" &&
                      this.state.adNetworkJson.length
                      ? this.state.adNetworkJson.map((adNetwork, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={adNetwork.adNetworkId}
                          >
                            {(adNetwork.companyName) ? adNetwork.companyName : "Unknown"}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
          </GridItem>
          <hr className={"custom-hr"}></hr>
          <GridItem xs={12} sm={6} md={4} style={{ display: "none" }}>
            <Card className={"dash-tiles"}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>people</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Number of AdNetworks</p>
                <h3 className={classes.cardTitle}>
                  {adNetworks} <small></small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <img
                    src={clock}
                    className={clock_cover}
                    style={clock_style}
                    alt="time"
                  />
                  <span>{timeStampVal}</span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card className={"dash-tiles"}>
              <CardHeader color="success" stats icon>
                <CardIcon color="success" className={test} style={styletest}>
                  <img src={featured} alt="logo" />
                </CardIcon>
                <p className={classes.cardCategory}>Number of Campaigns</p>
                <h3 className={classes.cardTitle}>{campaigns}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <img
                    src={clock}
                    className={clock_cover}
                    style={clock_style}
                    alt="time"
                  />
                  <span>{lastCampaigns}</span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card className={"dash-tiles"}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger" className={test1} style={styletest1}>
                  <img src={advert} alt="logo" />
                </CardIcon>
                <p className={classes.cardCategory}>Number of Ads</p>
                <h3 className={classes.cardTitle}>{advertisement}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <img
                    src={clock}
                    className={clock_cover}
                    style={clock_style}
                    alt="time"
                  />
                  <span>{lastAdvertisement}</span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card className={"dash-tiles"}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger" className={test2} style={styletest2}>
                  <img src={assetsIcon} alt="logo" />
                </CardIcon>
                <p className={classes.cardCategory}>Number of Assets</p>
                <h3 className={classes.cardTitle}>{assets}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <img
                    src={clock}
                    className={clock_cover}
                    style={clock_style}
                    alt="time"
                  />
                  <span>{lastAssets}</span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={"dash-tiles"}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger" className={test3} style={styletest3}>
                  <img src={vendor} alt="logo" />
                </CardIcon>
                <p className={classes.cardCategory}>Number of Vendors</p>
                <h3 className={classes.cardTitle}>{vendors}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <img
                    src={clock}
                    className={clock_cover}
                    style={clock_style}
                    alt="time"
                  />
                  <span>{lastVendors}</span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4} style={{ display: "none" }} >
            <Card className={"dash-tiles"}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger" className={test4} style={styletest4}>
                  <Icon>people</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Number of Users</p>
                <h3 className={classes.cardTitle}>{users}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <img
                    src={clock}
                    className={clock_cover}
                    style={clock_style}
                    alt="time"
                  />
                  <span>{lastUsers}</span>
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <div className="form-cover">
              <FormGroup>
                <FormControl className={"mat_select"}>
                  <InputLabel shrink htmlFor="campaigns">
                    Select Campaign
                  </InputLabel>
                  <Select
                    id="campaigns"
                    color="secondary"
                    name="campaigns"
                    value={campaignId}
                    width="100px"
                    onChange={this.campaignChange}
                    input={<Input value={campaignId} id="campaignsInput" />}
                    displayEmpty
                  >
                    <MenuItem value="">Select Campaign</MenuItem>
                    {typeof campaignsJson === "object" && campaignsJson.length
                      ? campaignsJson.map((campaign, key) => {
                        return (
                          <MenuItem key={key} value={campaign.campaignId}>
                            {campaign.name}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </FormGroup>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {(campaignId) ?
              <GridItem xs={12} sm={12} md={12}>
                <CampaignAnalytics
                  campaignAnalytics={this.state.campaignAnalytics}
                  csvSheetData={this.state.csvSheetDataCampaign}
                  loading={this.state.loading}
                  selectedCampaignData={this.state.selectedCampaignData}
                />
                <AdvertisementAnalytics
                  advertisementAnalytics={this.state.advertisementAnalytics}
                  csvSheetData={this.state.csvSheetDataAdvertisement}
                  loading={this.state.loading}
                  advertisementList={this.state.advertisementList}
                  assetsList={this.state.assetsList}
                  campaignId={campaignId}
                />
                {/* <AssetAnalytics assetAnalytics={this.state.assetAnalytics} loading={this.state.loading}/> */}
              </GridItem>
              :
              null
            }
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

DashboardClass.propTypes = {
  classes: PropTypes.object.isRequired
};

const Dashboard = connect(
  mapStateToProps, mapDispatchToProps
)(DashboardClass);

// export default Form;
export default withStyles(dashboardStyle)(Dashboard);