import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import {
  FormGroup,
  FormControl,
  InputLabel,
  TablePagination
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import assetssmall from "assets/img/assets-small.png";
import vendorssmall from "assets/img/vendors-small.png";
import claimssmall from "assets/img/claims-small.png";
import sortJsonArray from "sort-json-array";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { userService } from "_services/user.service";
import { basePath, baseRoutes } from "base-routes";
import enMsg from "__helpers/locale/en/en";
import {
  ADVT_SORT,
  STORED_CAMPAIGN,
  STORED_ADVERTISEMENT,
  CAMPAIGN_ID_SLUG,
  ADNETWORK_ID_SLUG,
  ADVERTISEMENT_ID_SLUG
} from "__helpers/constants";
import "react-notifications-component/dist/theme.css";
import done from "assets/img/done.svg";

// import advertismentJsonData from "assets/github/advertisement.json";
import { AdvertisementStatus } from "__helpers/constants";
import { NotificationContainer } from "react-notifications";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { connect } from "react-redux";
import {
  addCampaigns,
  reduxLoad,
} from "../../js/actions/index";

function mapDispatchToProps(dispatch) {
  return {
    addCampaigns: addCampaignsVal => dispatch(addCampaigns(addCampaignsVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    adNetworkList: state.adNetworkList,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class AdvertisementClass extends React.Component {
  openMenuButton = React.createRef(null);
  menuButton = React.createRef(null);

  constructor(props) {
    super(props);
    this.addAdvertisementLink = `${basePath}${baseRoutes.addAdvertisement.path}`;
    /* replace advertisement id*/
    let addAdvertisementLink = this.addAdvertisementLink.replace(
      CAMPAIGN_ID_SLUG,
      ""
    );
    let advertisementsJson = JSON.parse(
      localStorage.getItem(STORED_ADVERTISEMENT)
    );
    let selectedAdnetwork = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    selectedAdnetwork = (selectedAdnetwork) ? selectedAdnetwork.adNetworkId : "";

    this._isMounted = false;
    this.state = {
      value: 0,
      user: {},
      sorting: "",
      users: [],
      openMenu: false,
      campaigns: "",
      campaignsJson: [],
      campaignJsonList: [],
      adnetworkId: (selectedAdnetwork) ? selectedAdnetwork : "",
      /* this will hold the filtered & sorted data */
      advertisementsJson: advertisementsJson ? advertisementsJson : [],
      totalAdvertisements: advertisementsJson ? advertisementsJson.length : 0,
      addAdvertisementLink: addAdvertisementLink,
      page: 0,
      rowsPerPage: 12, // default
      defaultMessage: enMsg.fetchingData,
      reduxLoadFlag: false,
    };
    /* this will hold the original data */
    this.originalAdvertisementsJson = advertisementsJson
      ? advertisementsJson
      : [];
    this.notificationID = null;
    this.fetchCampagin = this.fetchCampagin.bind(this);
    this.setAdvertisement = this.setAdvertisement.bind(this);
    this.handleAdvertisementSorting = this.handleAdvertisementSorting.bind(
      this
    );
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.campaignChange = this.campaignChange.bind(this);
    this.sortAndFilter = this.sortAndFilter.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  componentDidUpdate(){
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = this.state.adnetworkId;
      let adnetworkData = [];
      let campaignData = [];
      let adNetworkListData = [];
      let advertisementsData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      if (this.props.adNetworkList) {
        let jsonData = this.props.adNetworkList;
        adNetworkListData = (jsonData) ? jsonData : [];
        this.originalAdvertisementsJson =  (adNetworkListData) ? adNetworkListData : [];
      }
      if (this.props.campaings) {
        let jsonData = this.props.campaings;
        campaignData = (jsonData) ? jsonData : [];
      }
      if (this.props.advertisements) {
        let jsonData = this.props.advertisements;
        advertisementsData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        adnetworkId: (selectedAdnetwork) ? selectedAdnetwork : "",
        advertisementsJson: (advertisementsData) ? advertisementsData : [],
        // totalAdvertisements: (adNetworkListData) ? adNetworkListData : [],
        campaignJsonList: (campaignData) ? campaignData : [],
      })
      setTimeout(function(){
        this.setAdvertisement();
      }.bind(this),500);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClickOpen = () => {
    this.setState({ openMenu: true });
  };
  handleClose = () => {
    this.setState({ openMenu: false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    });
  };

  async componentDidMount() {
    this._isMounted = true;
    // await this.fetchCampagin();
    this.setAdvertisement();
    // reauthorize token request...
    userService.refreshClientAouth();
    // await userService.fetchAnalytics(this.state.adnetworkId , "81a34a60-a505-4985-bc30-e98be1a13135");
  }

  sortAndFilter() {
    let advertisementsJson = this.originalAdvertisementsJson;
    if (this.state.campaigns) {
      advertisementsJson = advertisementsJson.filter(advertisement => {
        return advertisement.campaignId === this.state.campaigns;
      });
    }

    if (this.state.sorting) {
      sortJsonArray(advertisementsJson, this.state.sorting); // default is 'asc'
    }
    this.setState({
      advertisementsJson: advertisementsJson,
      totalAdvertisements: advertisementsJson.length
        ? advertisementsJson.length
        : 0
    });
  }
  campaignChange = e => {
    const value = e.target.value;

    this.setState(
      {
        campaigns: value,
        addAdvertisementLink: this.addAdvertisementLink.replace(
          CAMPAIGN_ID_SLUG,
          value
        )
      },
      () => this.sortAndFilter()
    );
  };

  handleAdvertisementSorting = event => {
    const name = event.target.name;
    const sortByValue = event.target.value;
    this.setState(
      {
        [name]: sortByValue
      },
      () => this.sortAndFilter()
    );
  };

  async fetchCampagin() {
    let campaignRows = await userService.fetchCampagin();
    this._isMounted &&
      this.setState({
        campaignsJson: campaignRows
      });
  }

  setAdvertisement = async () => {
    let originalAdvertisementsJson = [];
    // let campaignsJson = JSON.parse(localStorage.getItem(STORED_CAMPAIGN));
    let campaignsJson = this.state.campaignJsonList;
    if(campaignsJson){
      campaignsJson.map((campaign, key) => {
        if (campaign.advertisements) {
          campaign.advertisements = campaign.advertisements.map(
            (advertisement, key) => {
              return Object.assign(advertisement, {
                adNetworkId: campaign.adNetworkId
              });
            }
          );
          Array.prototype.push.apply(
            originalAdvertisementsJson,
            campaign.advertisements
          );
        }

        // return false;
      });
      this.originalAdvertisementsJson = originalAdvertisementsJson;
      this._isMounted &&
        this.setState({
          defaultMessage: enMsg.noDataFound,
          campaignsJson: campaignsJson,
          advertisementsJson: originalAdvertisementsJson,
          originalAdvertisementsJson: originalAdvertisementsJson,
          totalAdvertisements: originalAdvertisementsJson.length
            ? originalAdvertisementsJson.length
            : 0
        });
    }
    return null;
  };

  render() {
    const {
      campaigns,
      sorting,
      campaignsJson,
      advertisementsJson,
      rowsPerPage,
      page,
      defaultMessage
    } = this.state;
    const { classes } = this.props;

    return (
      <div>
        {/* <NotificationContainer/> */}
        <GridContainer>
          <Card className={"advert-cover"}>
            <GridItem xs={12} sm={12} md={12}>
              <CardHeader
                color="success"
                className={"cusCardheader cusCardTitle"}
              >
                <h4 className={classes.cardTitleWhite}>Advertisements</h4>
              </CardHeader>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className="form-cover">
                <FormGroup>
                  <FormControl className={"mat_select"}>
                    <InputLabel shrink htmlFor="campaigns">
                      Select Campaign
                    </InputLabel>
                    <Select
                      id="campaigns"
                      color="secondary"
                      name="campaigns"
                      value={campaigns}
                      width="100px"
                      onChange={this.campaignChange}
                      input={<Input value={campaigns} id="campaignsInput" />}
                      displayEmpty
                    >
                      <MenuItem value="">All Campaign</MenuItem>
                      {typeof campaignsJson === "object" && campaignsJson.length
                        ? campaignsJson.map((campaign, key) => {
                            return (
                              <MenuItem key={key} value={campaign.campaignId}>
                                {campaign.name}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </FormGroup>
              </div>
              <div className="button-cover">
                <Link
                  to={`${this.state.addAdvertisementLink}`}
                  color="textSecondary"
                  className="add-campaign-btn"
                  underline="none"
                >
                  <button className="pointer">new advertisement</button>
                </Link>
              </div>
            </GridItem>
            <hr className={"custom-hr"}></hr>
            <GridItem xs={12} sm={12} md={12}>
              <div className="sort-by-cover">
                <FormGroup>
                  <FormControl className={"mat_select"}>
                    <InputLabel htmlFor="sorting">Sort By</InputLabel>
                    <Select
                      id="sorting"
                      color="secondary"
                      name="sorting"
                      value={sorting}
                      width="100px"
                      onChange={this.handleAdvertisementSorting}
                      input={<Input id="sorting" />}
                      inputProps={{
                        // to hide check image
                        className: "selected-sorting-dropdown"
                      }}
                    >
                      {ADVT_SORT
                        ? Object.keys(ADVT_SORT).map(key => {
                            return (
                              <MenuItem
                                className={
                                  sorting !== key ? "sorting-items" : ""
                                }
                                key={key}
                                value={key}
                              >
                                {sorting === key ? (
                                  <img
                                    src={done}
                                    alt=""
                                    className="pop-check"
                                  />
                                ) : (
                                  ""
                                )}
                                {ADVT_SORT[key]}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </FormGroup>
              </div>
              <div className="advert-count">
                total {this.state.totalAdvertisements}{" "}
                {this.state.totalAdvertisements > 1
                  ? "advertisements"
                  : "advertisement"}
              </div>
            </GridItem>
            <div className={"custom-container"}>
              <GridContainer spacing={3}>
                {typeof advertisementsJson === "object" &&
                advertisementsJson.length ? (
                  advertisementsJson
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((advertisement, key) => {
                      if (!advertisement) {
                        return;
                      }
                      let statusClass = `card-btn ${advertisement.status}`;
                      const timestamp = new Date(
                        advertisement.startDate
                      ).getTime();
                      advertisementsJson[key].timestamp = timestamp;
                      return (
                        <React.Fragment key={key}>
                          <GridItem key={key} md={4} sm={6} xs={12}>
                            <Card className={"dash-tiles advertisement"}>
                              <CardHeader
                                className={"advert-cardheader"}
                                color="warning"
                                stats
                                icon
                              >
                                <Link
                                  color="textSecondary"
                                  underline="none"
                                  className="cancel-back-btn"
                                  to={baseRoutes.advertisementDetail.useLink
                                    .replace(
                                      ADNETWORK_ID_SLUG,
                                      advertisement.adNetworkId
                                    )
                                    .replace(
                                      ADVERTISEMENT_ID_SLUG,
                                      advertisement.adId
                                    )}
                                >
                                  <p className={classes.cardCategory}>
                                    {advertisement.name}
                                  </p>
                                </Link>

                                <div className="advert-details">
                                  <div className="assets-count">
                                    <img src={assetssmall} />
                                    <span className="grey-text">assets</span>
                                    <span className="black-text">
                                      {advertisement.assets
                                        ? advertisement.assets.length
                                        : 0}
                                    </span>
                                  </div>
                                  <div className="vendor-count">
                                    <img src={vendorssmall} />
                                    <span className="grey-text">Vendor</span>
                                    <span className="black-text">
                                      {advertisement.vendor
                                        ? 1
                                        : 0}
                                    </span>
                                  </div>
                                  <div className="claims-count">
                                    <img src={claimssmall} />
                                    <span className="grey-text">claims</span>
                                    <span className="black-text">
                                      {advertisement.claims
                                        ? advertisement.claims.length
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                              </CardHeader>
                              <div className="card-btn-cover">
                                <button className={statusClass}>
                                  {AdvertisementStatus[advertisement.status]
                                    ? AdvertisementStatus[advertisement.status]
                                    : "N/A"}
                                </button>
                              </div>
                            </Card>
                          </GridItem>
                        </React.Fragment>
                      );
                    })
                ) : (
                  <div className="display-block text-center">
                    {defaultMessage}
                  </div>
                )}
              </GridContainer>
            </div>
            <TablePagination
              // rowsPerPageOptions={advertisementsJson.length >= rowsPerPage? [12, 24, 99]:[]}
              rowsPerPageOptions={[]} // no per page required as per design
              labelRowsPerPage="" //Record Per Page
              component="div"
              count={advertisementsJson.length ? advertisementsJson.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page"
              }}
              nextIconButtonProps={{
                "aria-label": "next page"
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Card>
        </GridContainer>
      </div>
    );
  }
}

AdvertisementClass.propTypes = {
  classes: PropTypes.object.isRequired
};
const Advertisement = connect(
  mapStateToProps, mapDispatchToProps
)(AdvertisementClass);
export default withStyles(dashboardStyle)(Advertisement);
