import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link as MaterialLink,
  LinearProgress,
  TableSortLabel,
} from "@material-ui/core";

// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {
  NotificationOptions,
  CampaginStatus,
  STORED_ADNETWORK,
  ADNETWORK_ID_SLUG,
  CAMPAIGN_ID_SLUG,
  VENDOR_ID_SLUG,
  PER_PAGE_OPTIONS,
  RECORD_PER_PAGE,
  SELECTED_ADNETWORK,
  STORED_CAMPAIGN_ANALYTICS,
  advertisementClaims,
  advertisementClicks,
  advertisementReached,
  advertisementImpression,
  advertisementRedeems,
} from "__helpers/constants";
import { STORED_ADVERTISEMENT_ANALYTICS } from "__helpers/constants";
import dropdown from "assets/img/dropdown.png";
import { CSVLink, CSVDownload } from "react-csv";
import { STORED_ADVERTISEMENT } from "__helpers/constants";
import { Chart } from "react-google-charts";
import { STORED_ASSETS_ANALYTICS } from "__helpers/constants";
import AssetAnalytics from "./AssetAnalytics";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// then all Highcharts modules you will need
import HighchartsMore from 'highcharts/highcharts-more';
import dataModule from 'highcharts/modules/data';


HighchartsMore(Highcharts);
dataModule(Highcharts);
const columns = [
  // { id: "adId", label: "Id" },
  // { id: "name", label: "Name" },
  // { id: "totalAssetClaims", label: "Asset Claims" },
  // { id: "totalAssetClicks", label: "Asset Clicks" },
  // { id: "totalApplicationsReached", label: "Applications" },
  // { id: "totalAssetImpressions", label: "Asset Impressions" },
  // { id: "totalAssetPostImpressions", label: "AssetPost Impressions" },
  // { id: "totalAssetSaves", label: "Asset Saves" },
  // { id: "totalAssetShares", label: "Asset Shares" },
  // { id: "totalAssetViews", label: "Asset Views" },
  // { id: "totalDevicesReached", label: "Devices Reached" },
  // { id: "totalAssetRedeems", label: "Asset Redeems" },
  // { id: "totalAssetDeletes", label: "Asset Deletes" },
  // { id: "totalExternalApplicationsReached", label: "External Applications" },
  // { id: "totalExternalDevicesReached", label: "External Devices" },
  // { id: "totalPresenceReached", label: "Presence" },
  // { id: "totalSharedAssetContentViews", label: "SharedAsset Content" },

  { id: "adName", label: "Name" },
  { id: "totalAssetImpressions", label: "Impressions" },
  { id: "totalAssetPostImpressions", label: "Post Impressions" },
  { id: "totalAssetViews", label: "Views" },
  { id: "totalAssetClicks", label: "Clicks" },
  { id: "totalAssetSaves", label: "Saves" },
  { id: "totalAssetShares", label: "Shares" },
  { id: "totalSharedAssetContentViews", label: "Shared Views" },
  { id: "totalAssetClaims", label: "Claims" },
  { id: "totalAssetDeletes", label: "Deletes" },
  { id: "totalAssetRedeems", label: "Redeems" },
  { id: "totalApplicationsReached", label: "Application Reached" },
  { id: "totalPresenceReached", label: "Presence Reached" },
  { id: "totalDevicesReached", label: "Devices Reached" },
  { id: "totalExternalApplicationsReached", label: "External Applications Reached" },
  { id: "totalExternalDevicesReached", label: "External Devices Reached" },
];
let csvHeadersLock = [
  { key: "type", label: "Data Type" },
  { key: "name", label: "Name" },
  { key: "adId", label: "Id" },
  { key: "totalAssetImpressions", label: "Impressions" },
  { key: "totalAssetPostImpressions", label: "Post Impressions" },
  { key: "totalAssetViews", label: "Views" },
  { key: "totalAssetClicks", label: "Clicks" },
  { key: "totalAssetSaves", label: "Saves" },
  { key: "totalAssetShares", label: "Shares" },
  { key: "totalSharedAssetContentViews", label: "Shared Views" },
  { key: "totalAssetClaims", label: "Claims" },
  { key: "totalAssetDeletes", label: "Deletes" },
  { key: "totalAssetRedeems", label: "Redeems" },
  { key: "totalApplicationsReached", label: "Application Reached" },
  { key: "totalPresenceReached", label: "Presence Reached" },
  { key: "totalDevicesReached", label: "Devices Reached" },
  { key: "totalExternalApplicationsReached", label: "External Applications Reached" },
  { key: "totalExternalDevicesReached", label: "External Devices Reached" },
];
let csvHeaders = [
  { key: "type", label: "Data Type" },
  { key: "name", label: "Name" },
  { key: "id", label: "Id" },
  { key: "adType", label: "Ad Type" },
  { key: "adMedium", label: "Ad Medium" },
  { key: "status", label: "Ad Status" },
  { key: "assetType", label: "Asset Type" },
  { key: "displayType", label: "Display Type" },
  { key: "adStartDate", label: "Ad StartDate" },
  { key: "adExpirationDate", label: "Ad ExpirationDate" },
  { key: "totalImpressions", label: "Impressions" },
  { key: "totalPostImpressions", label: "Post Impressions" },
  { key: "totalViews", label: "Views" },
  { key: "totalClicks", label: "Clicks" },
  { key: "totalSaves", label: "Saves" },
  { key: "totalShares", label: "Shares" },
  { key: "totalSharedContentViews", label: "Shared Views" },
  { key: "totalClaims", label: "Claims" },
  { key: "totalDeletes", label: "Deletes" },
  { key: "totalRedeems", label: "Redeems" },
  { key: "totalApplicationsReached", label: "Application Reached" },
  { key: "totalPresenceReached", label: "Presence Reached" },
  { key: "totalDevicesReached", label: "Devices Reached" },
  { key: "totalExternalApplicationsReached", label: "External Applications Reached" },
  { key: "totalExternalDevicesReached", label: "External Devices Reached" },
  { key: "ViewActionRate", label: "View Action Rate" },
  { key: "ClickActionRate", label: "Click Action Rate" },
  { key: "SaveActionRate", label: "Save Action Rate" },
  { key: "RedeemActionRate", label: "Redeem Action Rate" },
  { key: "DeleteActionRate", label: "Delete Action Rate" },
  { key: "ShareActionRate", label: "Share Action Rate" },
  { key: "SharedViewActionRate", label: "Shared View Action Rate" },
  { key: "ClaimActionRate", label: "Claim Action Rate" },
  { key: "ViewConversionRate", label: "View Conversio Rate" },
  { key: "ClickConversionRate", label: "Click Conversin Rate" },
  { key: "SaveConversionRate", label: "Save Conversion Rae" },
  { key: "DeleteConversionRate", label: "Delete Conversion Rate" },
  { key: "ShareConversionRate", label: "Share Conversion Rate" },
  { key: "SharedViewConversionRate", label: "Shared View Conversion Rate" },
  { key: "ClaimConversionRate", label: "Claim Conversion Rate" },
  { key: "ClickThroughRate", label: "Click Through Rate" },
];

const useStyles = {
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto"
  }
};
export function CustomFun(text) {
  let newText = text.split('\n').map((item, i) => {
    return <p key={i}>{item}</p>;
  });
}
class AdvertisementAnalytics extends React.Component {
  cust_table_cover = "custom-table-cover";
  camph = "campaign-header";
  tableh = "table-header";
  cust_table = "custom-table";
  camp_btn_cover = "campaign-btn-cover";
  camp_btn = "campaign-btn";
  icon_cover = "icon-cover";

  constructor(props) {
    super(props);
    console.log(props);
    let advertisementAnalytics = JSON.parse(localStorage.getItem(STORED_ADVERTISEMENT_ANALYTICS));
    // let advertisementData = JSON.parse(localStorage.getItem(STORED_ADVERTISEMENT));
    let advertisementData = props.advertisementList ? props.advertisementList : [];
    // let assetAnalytics = JSON.parse(localStorage.getItem(STORED_ASSETS_ANALYTICS));
    let assetData = props.assetsList ? props.assetsList : [];

    this._isMounted = false;
    this.state = {

      chartDataTop: [],

      chartEmpty: [],
      barPercentVal: 0,
      page: 0,
      rowsPerPage: RECORD_PER_PAGE,
      selectedAd: "",
      recyleChartData: [],
      reachedChartData: [],
      // recycleUserData: [],
      reachedConversion: [],
      recycleClickThrough: [],
      csvSheetData: [],
      advertisementData: (advertisementData) ? advertisementData : [],
      assetsData: assetData,
      loading: (advertisementAnalytics) ? false : true,
      assetAnalytics: [],
      campaignId: "",
      advertisementAnalytics: (advertisementAnalytics) ? (advertisementAnalytics.advertisements) ? advertisementAnalytics.advertisements : [] : [],
      conversationValArr: [],
      displayConvertedVals: [],
      percentVal: 0,
      highChartOption: {},
    };

    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  async componentDidMount() {
    let newAnalyticsData = []
    this.setState({
      loading: true,
    })
    if (this.state.advertisementAnalytics) {
      this.state.advertisementAnalytics.map(adAnalytics => {
        this.state.advertisementData.map(adData => {
          if (adAnalytics.adId == adData.adId) {
            adAnalytics.name = adData.name;
          }
        });
        newAnalyticsData.push(adAnalytics);
      });
    }
    this.setState({
      advertisementAnalytics: newAnalyticsData,
      loading: false,
    })
  }
  getPercentageCategoryVales(percentageVal, valueArr, roundFlag) {
    let rangeValArr = [];
    valueArr.map((arrVal, key) => {
      let localRangeValArr = []
      if (arrVal < 100) {
        if (arrVal == 0) {
          arrVal = arrVal + 1;
        }
        localRangeValArr.push(arrVal)
        localRangeValArr.push(100)
      } else {
        localRangeValArr.push(100)
        localRangeValArr.push(arrVal)
      }
      rangeValArr.push(localRangeValArr)
    });
    // console.log(dataArr)
    return rangeValArr;
  }
  componentWillReceiveProps(nextProps) {
    let campaignId = nextProps.campaignId ? nextProps.campaignId : "";
    let advertisementAnalytics = nextProps.advertisementAnalytics
    advertisementAnalytics = (advertisementAnalytics) ? (advertisementAnalytics.advertisements) ? advertisementAnalytics.advertisements : [] : [];
    let newAnalyticsData = [];
    let csvSheetData = nextProps.csvSheetData;
    let assetsList = nextProps.assetsList ? nextProps.assetsList : [];
    let advertisementList = nextProps.advertisementList ? nextProps.advertisementList : [];
    this.setState({
      advertisementAnalytics: advertisementAnalytics,
      loading: nextProps.loading,
      csvSheetData: csvSheetData,
      advertisementData: advertisementList,
      assetsData: assetsList,
      campaignId: campaignId,
    })
  }
  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    });
  }
  handleChangePage = async (event, newPage) => {
    this.setState({
      page: newPage
    });
  };
  filterAdAnalytics(selectedAdId) {
    this.setState({
      loading: true,
    });
    let tempAssetArr = {};
    let assetsData = [];
    let advertisements = [];
    let assetAnalytics = [];
    let assets = [];
    let chartDataOne = [['Life Cycle', 'Impressions', 'Post Impressions', 'Views', 'Clicks', 'Saves', 'Shares', 'Shared Content Views', 'Claims', 'Deletes', 'Redeems']];
    let chartDataTwo = [['Outreach', 'Applications Reached', 'Presence Reached', 'Devices Reached', 'External Applications Reached', 'External Devices Reached']];
    let chartDataConversion = [['Conversion Rate', 'ViewConversionRate', 'ClickConversionRate', 'SaveConversionRate', 'DeleteConversionRate', 'ShareCoversionRate', 'SharedViewConversionRate', 'ClaimConversionRate']];
    let chartClickThrough = [['Click Through Rate', 'ClickThroughRate']];
    let chartUserRate = [['UserActionRate', 'ViewActionRate', 'ClickActionRate', 'SaveActionRate', 'RedeemActionRate', 'DeleteActionRate', 'ShareActionRate', 'SharedViewActionRate', 'ClaimActionRate']];
    let chartDataTop = [];
    let chartEmpty = [];
    let highChartOption = {};
    try {
      advertisements = this.state.advertisementAnalytics;
      if (advertisements) {
        // console.log(assetAnalytics);
        advertisements.map((localAnalyticVar, key) => {
          console.log(localAnalyticVar);
          if (localAnalyticVar.assetAnalytics && localAnalyticVar.assetAnalytics.length) {
            if (localAnalyticVar.adId == selectedAdId) {
              Array.prototype.push.apply(assets, localAnalyticVar.assetAnalytics);
              let Impressions = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetImpressions : 0;
              let postImpressions = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetPostImpressions : 0;
              let Views = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetViews : 0;
              let Clicks = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetClicks : 0;
              let Saves = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetSaves : 0;
              let Deletes = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetDeletes : 0;
              let Redeems = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetRedeems : 0;
              let Shares = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetShares : 0;
              let sharedContentViews = (localAnalyticVar.adId) ? localAnalyticVar.totalSharedAssetContentViews : 0;
              let Claims = (localAnalyticVar.adId) ? localAnalyticVar.totalAssetClaims : 0;

              let devicesReached = (localAnalyticVar.adId) ? localAnalyticVar.totalDevicesReached : 0;
              let applicationsReached = (localAnalyticVar.adId) ? localAnalyticVar.totalApplicationsReached : 0;
              let presenceReached = (localAnalyticVar.adId) ? localAnalyticVar.totalPresenceReached : 0;
              let externaldevicesReached = (localAnalyticVar.adId) ? localAnalyticVar.totalExternalDevicesReached : 0;
              let externalApplicationsReached = (localAnalyticVar.adId) ? localAnalyticVar.totalExternalApplicationsReached : 0;

              let ViewActionRate = (localAnalyticVar.adId) ? localAnalyticVar.ViewActionRate : 0;
              let ClickActionRate = (localAnalyticVar.adId) ? localAnalyticVar.ClickActionRate : 0;
              let SaveActionRate = (localAnalyticVar.adId) ? localAnalyticVar.SaveActionRate : 0;
              let RedeemActionRate = (localAnalyticVar.adId) ? localAnalyticVar.RedeemActionRate : 0;
              let DeleteActionRate = (localAnalyticVar.adId) ? localAnalyticVar.DeleteActionRate : 0;
              let ShareActionRate = (localAnalyticVar.adId) ? localAnalyticVar.ShareActionRate : 0;
              let SharedViewActionRate = (localAnalyticVar.adId) ? localAnalyticVar.SharedViewActionRate : 0;
              let ClaimActionRate = (localAnalyticVar.adId) ? localAnalyticVar.ClaimActionRate : 0;

              //let camUserOne = (localAnalyticVar.campaignId) ? selectedCampaign.name : "Uknown";
              let ViewConversionRate = (localAnalyticVar.adId) ? localAnalyticVar.ViewConversionRate : 0;
              let ClickConversionRate = (localAnalyticVar.adId) ? localAnalyticVar.ClickConversionRate : 0;
              let SaveConversionRate = (localAnalyticVar.adId) ? localAnalyticVar.SaveConversionRate : 0;
              //let RedeemActionRate =(localAnalyticVar.campaignId)? localAnalyticVar.RedeemActionRate:0;
              let DeleteConversionRate = (localAnalyticVar.adId) ? localAnalyticVar.DeleteConversionRate : 0;
              let ShareConversionRate = (localAnalyticVar.adId) ? localAnalyticVar.ShareConversionRate : 0;
              let SharedViewConversionRate = (localAnalyticVar.adId) ? localAnalyticVar.SharedViewConversionRate : 0;
              let ClaimConversionRate = (localAnalyticVar.adId) ? localAnalyticVar.ClaimConversionRate : 0;
              
              
              let ClickThroughRate = (localAnalyticVar.adId) ? localAnalyticVar.ClickThroughRate : 0;

              let adName = (localAnalyticVar.adName) ? localAnalyticVar.adName : "Uknown";

              // User Action Rate
              let localUserActionRate = [adName, ViewActionRate, ClickActionRate, SaveActionRate, RedeemActionRate, DeleteActionRate, ShareActionRate, SharedViewActionRate, ClaimActionRate];
              chartUserRate.push(localUserActionRate);
              //  console.log(localUserActionRate)

              let localClickThroughConversion = [adName, ClickThroughRate];
              //let localClickThroughConversion = [adName, 20];
              chartClickThrough.push(localClickThroughConversion);

              let conversationValArr = [ViewConversionRate, ClickConversionRate, SaveConversionRate, DeleteConversionRate, ShareConversionRate, SharedViewConversionRate, ClaimConversionRate]             //[-10,200,100000,-50,5000]; 
              // let conversationValArr = [3.9, 300, 19780, 11, 11180, 77, 7.5]
              let displayConvertedVals = this.getPercentageCategoryVales(1, conversationValArr, true);
              var chartLabels = ['View Conversion Rate', 'Click Conversion Rate', 'Save Conversion Rate', 'Delete Conversion Rate', 'Share Conversion Rate', 'Shared View Conversion Rate', 'Claim Conversion Rate']
              highChartOption = {
                chart: {
                  type: 'columnrange',
                  inverted: true,
                  zoom: "xy"
                },
                title: {
                  text: 'Conversion Rate'
                },
                subtitle: {
                  text: ''
                },
                xAxis: {
                  title: {
                    text: 'User Action'
                  },
                  type: 'category',
                  categories: chartLabels,
                  labels: {
                    formatter: function () {
                      return chartLabels[this.pos]
                    }
                  }
                },
                yAxis: {
                  title: {
                    text: 'values'
                  },
                  type: 'logarithmic',
                  min: 1,
                },
                tooltip: {
                  useHTML: true,
                  formatter: function () {
                    console.log(this, this.point.low); // just to see , what data you can access
                    let barValue = this.point.high == 100 ? this.point.low : this.point.high
                    return '<b>' + this.x +
                      '</b>: <b>' + barValue + ' %</b>';
                  }
                },
                colors: ['#b0120a', '#ffab91', '#ED5534', '#433C39', '#545150', '#B09187', '#B087A0'],
                plotOptions: {
                  columnrange: {
                    dataLabels: {
                      // enabled: true,
                      //  format: '{y}°C'
                    },
                    colorByPoint: true
                  },
                  series: {
                    animation: false
                  },
                  column: {
                    // colorByPoint: true
                  }
                },
                legend: {
                  enabled: false
                },
                credits: {
                  enabled: false
                },
                series: [{
                  name: 'Action Rates',
                  data: displayConvertedVals,
                }]
              };


              let localDataOne = [adName, Impressions, postImpressions, Views, Clicks, Saves, Shares, sharedContentViews, Claims, Deletes, Redeems];
              chartDataOne.push(localDataOne);

              let localDataTwo = [adName, applicationsReached, presenceReached, devicesReached, externalApplicationsReached, externaldevicesReached];
              chartDataTwo.push(localDataTwo);

              chartDataTop = [
                ["Campaign", "Advertisement"],
                ["View", ViewActionRate],
                ["Click", ClickActionRate],
                ["Save", SaveActionRate],
                ["Redeem", RedeemActionRate],
                ["Delete", DeleteActionRate],
                ["Share", ShareActionRate],
                ["SharedView", SharedViewActionRate],
                ["Claim", ClaimActionRate]
              ];
              if (!(ViewActionRate + ClickActionRate + SaveActionRate + RedeemActionRate + DeleteActionRate + ShareActionRate + SharedViewActionRate + ClaimActionRate)) {
                chartDataTop = null;
              }
              chartEmpty = [
                ["campaign", "Advertisement"],
                ["", 1]
              ];
            }
          }
        });
        if (assets) {
          assets.map((asset, key) => {
            tempAssetArr["numApplicationsReached"] = (tempAssetArr["numApplicationsReached"]) ? tempAssetArr["numApplicationsReached"] : 0 + asset.numApplicationsReached;
            tempAssetArr["numClaims"] = (tempAssetArr["numClaims"]) ? tempAssetArr["numClaims"] : 0 + asset.numClaims;
            tempAssetArr["numClicks"] = (tempAssetArr["numClicks"]) ? tempAssetArr["numClicks"] : 0 + asset.numClicks;
            tempAssetArr["numDeletes"] = (tempAssetArr["numDeletes"]) ? tempAssetArr["numDeletes"] : 0 + asset.numDeletes;
            tempAssetArr["numImpressions"] = (tempAssetArr["numImpressions"]) ? tempAssetArr["numImpressions"] : 0 + asset.numImpressions;
            tempAssetArr["numPostImpressions"] = (tempAssetArr["numPostImpressions"]) ? tempAssetArr["numPostImpressions"] : 0 + asset.numPostImpressions;
            tempAssetArr["numRedeems"] = (tempAssetArr["numRedeems"]) ? tempAssetArr["numRedeems"] : 0 + asset.numRedeems;
            tempAssetArr["numSaves"] = (tempAssetArr["numSaves"]) ? tempAssetArr["numSaves"] : 0 + asset.numSaves;
            tempAssetArr["numShares"] = (tempAssetArr["numShares"]) ? tempAssetArr["numShares"] : 0 + asset.numShares;
            tempAssetArr["numViews"] = (tempAssetArr["numViews"]) ? tempAssetArr["numViews"] : 0 + asset.numViews;
            tempAssetArr["numDevicesReached"] = (tempAssetArr["numDevicesReached"]) ? tempAssetArr["numDevicesReached"] : 0 + asset.numDevicesReached;
            tempAssetArr["numExternalApplicationsReached"] = (tempAssetArr["numExternalApplicationsReached"]) ? tempAssetArr["numExternalApplicationsReached"] : 0 + asset.numExternalApplicationsReached;
            tempAssetArr["numExternalDevicesReached"] = (tempAssetArr["numExternalDevicesReached"]) ? tempAssetArr["numExternalDevicesReached"] : 0 + asset.numExternalDevicesReached;
            tempAssetArr["numPresenceReached"] = (tempAssetArr["numPresenceReached"]) ? tempAssetArr["numPresenceReached"] : 0 + asset.numPresenceReached;
            tempAssetArr["numSharedContentViews"] = (tempAssetArr["numSharedContentViews"]) ? tempAssetArr["numSharedContentViews"] : 0 + asset.numSharedContentViews;
          });
        }
      }
      assetsData = { statics: tempAssetArr, assets: assets };
      localStorage.setItem(STORED_ASSETS_ANALYTICS, JSON.stringify(assetsData));
      this.setState({
        assetAnalytics: assetsData,
        loading: false,
        selectedAd: selectedAdId,
        recyleChartData: chartDataOne,
        reachedChartData: chartDataTwo,
        reachedConversion: chartDataConversion,
        recycleClickThrough: chartClickThrough,
        chartDataTop: chartDataTop,
        chartEmpty: chartEmpty,
        highChartOption: highChartOption,
      });
    } catch (errors) {
      console.log(errors)
    }
  }
  render() {
    const classes = this.props.classes;
    const {
      advertisementAnalytics,
      selectedAd,
      recyleChartData,
      reachedChartData,
      recycleUserData,
      reachedConversion,
      recycleClickThrough,
      chartDataTop,
      csvSheetData,
      conversationValArr,
      displayConvertedVals,
      percentVal,
      highChartOption,
    } = this.state;

    return (
      <div>
        <Card>
          <CardHeader color="success" className={"cusCardTitle"}>
            <h4 className={this.camph}>Advertisement Analytics {(advertisementAnalytics && advertisementAnalytics.length) ? <CSVLink data={csvSheetData} headers={csvHeaders} className="tblDownloadBtn" filename={"advertisementData.csv"}><i className="fa fa-download"></i></CSVLink> : ""}</h4>
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <LinearProgress
                color="primary"
              />
            )}

            {(selectedAd && selectedAd != "")
              ?
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer >
                    <GridItem xs={12} sm={6} md={6} lg={6} >
                      <span className={'text-select chart-title'}>Life Cycle</span>
                      <Chart
                        width={'100%'}
                        className="chart-css"
                        height={'500px'}
                        chartType="Bar"
                        loader={<LinearProgress color="primary" />}
                        data={recyleChartData}
                        options={{
                          // Material design options
                          chart: {
                            // title: 'Life Cycle ',
                            // subtitle: 'Impressions, Post Impressions, Views, Clicks, Saves, Shares, Shared Content Views, Claims, Deletes, Redeems',
                          },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6} >
                      <span className={'text-select chart-title'}>Outreach</span>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        className="chart-css"
                        chartType="Bar"
                        loader={<LinearProgress color="primary" />}
                        data={reachedChartData}
                        options={{
                          // Material design options
                          chart: {
                            // title: 'Outreach',
                            // subtitle: 'Applications Reached, Presence Reached, Devices Reached, Applications External Reached, Device External Reached',
                          },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6} >
                      <span className={'text-select chart-title'}>User Action Rate</span>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        className="chart-css"
                        chartType="PieChart"
                        // loader={<LinearProgress color="primary" />}
                        loader={<div>Loading Chart</div>}
                        // data={chartDataTop}
                        data={chartDataTop ? chartDataTop : this.state.chartEmpty}
                        // options={{
                        //   // Material design options
                        // //   chart: {
                        // //     title: 'User Action Rates',
                        // //     // subtitle: 'Impressions, Post Impressions, Views, Clicks, Saves, Shares, Shared Content Views, Claims, Deletes, Redeems',
                        // //     is3D: true,
                        // //   },
                        // // }}
                        options={{
                          // title: 'User Action Rate',
                          // Just add this option
                          is3D: true,

                        }}
                        //for test
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6} >
                      <span className={'text-select chart-title'}>Click Through Rate</span>

                      <Chart
                        width={'100%'}
                        height={'500px'}
                        className="chart-css"
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={recycleClickThrough}
                        options={{
                          // title: 'Click Through Rate',
                          chartArea: { width: '50%' },
                          colors: ['#ffab91', '#b0120a'],
                          hAxis: {
                            title: 'Click Through Rate',
                            minValue: 0,
                            // maxValue: 100,
                          },
                          vAxis: {
                            title: 'Value',
                          },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '4' }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12} >
                      <span className={'text-select chart-title'}>Conversion Rate Chart</span>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartOption}
                        className="chart-css"
                      />
                    </GridItem>
                    <br /><br />
                  </GridContainer>
                </GridItem>
              </GridContainer>
              : <span className={'text-select'}>Please select advertisement from table to view advertisement analytics chart</span>
            }
            <Paper className={(classes.root, this.cust_table_cover)}>
              <div className={(classes.tableWrapper, this.cust_table)}>
                <Table>
                  <TableHead className={this.tableh}>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          size="small"
                          style={{ minWidth: column.minWidth }}
                        >
                          {(column.id == "adId") ?
                            <div>
                              {column.label}
                            </div>
                            :
                            <div>
                              <span style={{ whiteSpace: "nowrap" }}>
                                {column.label}
                              </span>
                            </div>
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody key="TableBody">
                    {/* {console.log(advertisementAnalytics)} */}
                    {typeof advertisementAnalytics === "object" &&
                      advertisementAnalytics.length
                      ? advertisementAnalytics
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                        )
                        .map(row => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.adId}
                              onClick={this.filterAdAnalytics.bind(this, row.adId)}
                              className={(selectedAd == row.adId) ? classes.TableRow + " selectedAdAnalytics" : classes.TableRow}
                            >
                              {columns.map(column => {
                                const value = row[column.id];
                                // if (column.id == "name") {
                                //   return (
                                //     <TableCell key={column.name}>
                                //       {row.name}
                                //     </TableCell>
                                //   );
                                // }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format &&
                                      typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTIONS}
                component="div"
                count={
                  typeof advertisementAnalytics === "object" &&
                    advertisementAnalytics.length
                    ? advertisementAnalytics.length
                    : 0
                }
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  "aria-label": "previous page"
                }}
                nextIconButtonProps={{
                  "aria-label": "next page"
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
          </CardBody>
        </Card>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {(selectedAd && selectedAd != "")
              ?
              <AssetAnalytics
                assetAnalytics={this.state.assetAnalytics}
                loading={this.state.loading}
                selectedAd={selectedAd}
                assetsList={this.state.assetsData}
              />
              : <span className={'text-select'}>Please select advertisement from table to view assets analytics</span>
            }
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AdvertisementAnalytics.propTypes = {
  classes: PropTypes.object
  // openChangePasswordDialog:
};

export default withStyles(useStyles)(AdvertisementAnalytics);
