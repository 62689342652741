import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Popper from "@material-ui/core/Popper";
// @material-ui/icons
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link as MaterialLink,
  makeStyles,
  CircularProgress,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  Input,
  Grow,
  ClickAwayListener,
  MenuList,
  LinearProgress,
  Tooltip
} from "@material-ui/core";

import sortJsonArray from "sort-json-array";
// core components
import classNames from "classnames";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import edit from "assets/img/square-edit.png";
import del from "assets/img/del.png";
import dropdown from "assets/img/dropdown.png";
import clock from "assets/img/clock.png";
import Icon from "@material-ui/core/Icon";
import done from "assets/img/done.svg";
import search from "assets/img/search.svg";

import { Link } from "react-router-dom";
import { baseRoutes, basePath } from "base-routes";
import { apiPath } from "api";

import { store } from "react-notifications-component";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  CampaginStatus,
  STORED_ADNETWORK,
  ADNETWORK_ID_SLUG,
  CAMPAIGN_ID_SLUG,
  PER_PAGE_OPTIONS,
  RECORD_PER_PAGE
} from "__helpers/constants";
import { userService } from "_services/user.service";
import { NotificationContainer } from "react-notifications";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { STORED_CAMPAIGN } from "__helpers/constants";
import { FETCH_CAMPAIGN_STATUS } from "__helpers/constants";
import { connect } from "react-redux";
import {
  addCampaigns,
  reduxLoad,
} from "../../js/actions/index";

const columns = [
  { id: "name", label: "Name" },
  {
    id: "description",
    label: "Description",
    align: "left",
    format: value => value.toLocaleString()
  },
  // {
  //   id: "type",
  //   label: "Type",
  //   align: "left",
  //   format: value => value.toLocaleString()
  // },
  {
    id: "bus_name",
    label: "Business Name",
    align: "left",
    format: value => value.toFixed(2)
  },
  {
    id: "status",
    label: "Status",
    align: "left",
    format: value => value.toLocaleString()
  },
  {
    id: "advertisements",
    label: "Advertisement",
    align: "left",
    format: value => value.toFixed(2)
  },
  {
    id: "Actions",
    label: "Actions",
    align: "right",
    format: value => value.toFixed(2)
  }
];

const useStyles = {
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto"
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addCampaigns: addCampaignsVal => dispatch(addCampaigns(addCampaignsVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class CampaignListClass extends React.Component {
  stroedAdNetwork = [];
  localClasses = {
    cust_table_cover: "custom-table-cover",
    camph: "campaign-header",
    tableh: "table-header",
    cust_table: "custom-table",
    camp_btn_cover: "campaign-btn-cover",
    camp_btn: "campaign-btn",
    icon_cover: "icon-cover"
  };
  constructor(props) {
    super(props);

    let campaigns = [];
    let campaignStatus = [];
    if (this.props.campaings) {
      let jsonData = this.props.campaings;
      campaigns = (jsonData) ? jsonData : [];
    }
    if (this.props.campaignStatus) {
      let jsonData = this.props.campaignStatus;
      campaignStatus = (jsonData) ? jsonData : [];
    }

    let stroedAdNetwork = [];
    try {
      stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));      
    } catch (error) {
      stroedAdNetwork = [];
    }
    let selectedAdnetworkData = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    let selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
    // let campaigns = JSON.parse(localStorage.getItem(STORED_CAMPAIGN));
    // let campaignStatus = JSON.parse(localStorage.getItem(FETCH_CAMPAIGN_STATUS));
    this._isMounted = false;
    this.adNetworkSelectBox = React.createRef();

    this.notificationID = null;

    this.state = {
      page: 0,
      selectedadNetworkID: "",
      selectedCampaignID: null,
      selectedAdNetworkJsonID: (selectedAdnetwork) ? selectedAdnetwork : "",
      selectedAdnetworkData: (selectedAdnetworkData) ? selectedAdnetworkData : [],
      selectedCampaignStatus: null,
      adNetworkJson: stroedAdNetwork,
      campaignJson: (campaigns) ? campaigns : [],
      originalFetchData: (campaigns) ? campaigns : [],
      rowsPerPage: RECORD_PER_PAGE,
      openMenu: null,
      openAlertBox: false,
      openAlertBoxDelete: false,
      loading: false,
      openMenu: null,
      openAlertBox: false,
      openAlertBoxDelete: false,
      campaignShort: "",
      sortOrder: "asc",
      anchorElVal: "",
      campaignStatusList: (campaignStatus) ? campaignStatus : [],
      reduxLoadFlag: false,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.changeCampaignStatus = this.changeCampaignStatus.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.searchCampaign = this.searchCampaign.bind(this);
    this.fetchCampagin = this.fetchCampagin.bind(this);
    this.sortAndFilter = this.sortAndFilter.bind(this);
    // this.handleCampaignSorting = this.handleCampaignSorting.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    let campaignData = [];
    let campaignStatusData = []
    if (this.props.campaings) {
      let jsonData = this.props.campaings;
      campaignData = (jsonData) ? jsonData : [];
    }
    if (this.props.campaignStatus) {
      let jsonData = this.props.campaignStatus;
      campaignStatusData = (jsonData) ? jsonData : [];
    }
    this.setState({
      // reduxLoadFlag: false,
      // selectedadNetworkID: (selectedAdnetwork) ? selectedAdnetwork : "",
      // selectedCampaignID: null,
      // selectedAdNetworkJsonID: (selectedAdnetwork) ? selectedAdnetwork : "",
      // selectedAdnetworkData: (adnetworkData) ? adnetworkData : [],
      // selectedCampaignStatus: null,
      campaignJson: (campaignData) ? campaignData : [],
      originalFetchData: (campaignData) ? campaignData : [],
      campaignStatusList: (campaignStatusData) ? campaignStatusData : [],
    })
    if(!this.state.selectedAdNetworkJsonID){
      let showNotification = {
        title: enMsg.selectAdnetworkTitle,
        message: enMsg.selectAdnetwork,
        type: "quickInfoAlert"
      };
      userService.showNotification(showNotification)
    }
    // reauthorize token request...
    userService.refreshClientAouth();
  }

  componentWillUnmount() {
    this._isMounted = false;
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  componentDidUpdate(){
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = this.state.selectedAdNetworkJsonID;
      let adnetworkData = [];
      let campaignData = [];
      let campaignStatusData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      if (this.props.campaings) {
        let jsonData = this.props.campaings;
        campaignData = (jsonData) ? jsonData : [];
      }
      if (this.props.campaignStatus) {
        let jsonData = this.props.campaignStatus;
        campaignStatusData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        selectedadNetworkID: (selectedAdnetwork) ? selectedAdnetwork : "",
        selectedCampaignID: null,
        selectedAdNetworkJsonID: (selectedAdnetwork) ? selectedAdnetwork : "",
        selectedAdnetworkData: (adnetworkData) ? adnetworkData : [],
        selectedCampaignStatus: null,
        campaignJson: (campaignData) ? campaignData : [],
        originalFetchData: (campaignData) ? campaignData : [],
        campaignStatusList: (campaignStatusData) ? campaignStatusData : [],
      })
    }
  }

  // componentWillUnmount() {
  // }
  UNSAFE_componentWillMount() {
    this.setState(Object.getPrototypeOf(this).constructor.STATE || {});
    if (Object.getPrototypeOf(this).constructor.STATE) {
      var stataData = Object.getPrototypeOf(this).constructor.STATE;
      var selectedVal = null;
      Object.keys(stataData).map((v, i) => {
        if (v == "selectedAdNetworkJsonID") {
          selectedVal = stataData[v];
          // this.setState({ selectedAdNetworkJsonID: stataData[v] });
        }
      });
      if (selectedVal && selectedVal != undefined) {
        // this.fetchCampagin();
      }
    }
  }

  handleChangePage = async (event, newPage) => {
    this.setState({
      page: newPage
    });
    // setPage(newPage);
  };

  searchCampaign = e => {
    let allCampaign = this.state.originalFetchData;
    const searchText = e.target.value.trim();

    if (searchText) {
      allCampaign = allCampaign.filter((v, i) => {
        if (v.name) {
          return v.name.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1;
        }
      });
      this.setState({
        campaignJson: allCampaign
      });
    }
    this.setState({
      campaignJson: allCampaign
    });
  };
  /* change cmapaign status */
  changeCampaignStatus = async () => {
    const {
      selectedadNetworkID,
      selectedCampaignID,
      selectedCampaignStatus
    } = this.state;

    let originalFetchData = this.state.originalFetchData;

    let campaignData = await userService.fetchCampaignDetail(
      selectedCampaignID,
      selectedadNetworkID
    );
    // let originalFetchData = this.state.originalFetchData;
    this.setState({ loading: true });
    let showNotification = {};

    if (
      selectedadNetworkID &&
      selectedCampaignID &&
      selectedCampaignStatus &&
      campaignData.status
    ) {
      let apiPathString = apiPath.addEditCampaign;

      apiPathString = apiPathString.replace(
        ADNETWORK_ID_SLUG,
        selectedadNetworkID
      );
      apiPathString = apiPathString.replace(
        CAMPAIGN_ID_SLUG,
        selectedCampaignID
      );

      // return;
      try {
        campaignData.status = selectedCampaignStatus;
        const response = await fetch(`${apiPathString}`, {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(campaignData)
        })
          .then(response => {
            const contentType = response.headers.get("content-type");
            if (response.status === 400) {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            } else if (response.ok) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.campaginStatusUpdated,
                type: "success"
              };
            }else{
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            }
            return response.json();
          })
          .then(response => {
            let jsonDataVal = response;
            this.props.reduxLoad(false);
            this.fetchData(this.state.selectedadNetworkID);
            if(("errorMessage" in response) && response["errorMessage"] != undefined){
              showNotification = {
                title: enMsg.failedTitle,
                message: response.errorMessage,
                type: "warning"
              };
            } else {
              if (response && response.campaignId) {
                originalFetchData = originalFetchData.map((v, i) => {
                  if (v.campaignId == response.campaignId) {
                    return response;
                  }
                  return v;
                });
              }
            }
          })
          .catch(error => {
            originalFetchData = originalFetchData.map((v, i) => {
              if (v.campaignId == selectedCampaignID) {
                return campaignData;
              }
              return v;
            });

            showNotification = {
              title: enMsg.connectionFailed,
              message: enMsg.networkFailedError,
              type: "danger"
            };
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
        // notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        // this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification)
      //window.location.reload();
    }

    this._isMounted &&
      this.setState({
        campaignJson: originalFetchData,
        originalFetchData: originalFetchData,
        selectedadNetworkID: null,
        selectedCampaignID: null,
        openMenu: null,
        openAlertBox: false,
        loading: false
      });
  };

  handleClickOpen = (event, adNetworkId, campaignId) => {};
  async fetchData(selectedadNetworkID) {
    let campaigns = await userService.fetchCampagin(selectedadNetworkID);
    this.props.addCampaigns(campaigns);
    setTimeout(function(){
      this.props.reduxLoad(true);
    }.bind(this),500);
  }
  deleteCampaign = async () => {
    this.setState({ loading: true });
    const {
      selectedCampaignID,
      selectedadNetworkID,
    } = this.state;
    let {originalFetchData} = this.state;
    let apiPathString = apiPath.addEditCampaign;

    apiPathString = apiPathString.replace(
      ADNETWORK_ID_SLUG,
      selectedadNetworkID
    );
    apiPathString = apiPathString.replace(CAMPAIGN_ID_SLUG, selectedCampaignID);
    {
      let showNotification = {};
      try {
        const response = await fetch(apiPathString, {
          method: "DELETE",
          headers: authHeader(),
          body: JSON.stringify({ campaignId: selectedCampaignID })
        })
          .then(response => {
            const contentType = response.headers.get("content-type");
            if (response.status === 400) {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            } else if (response.ok) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.campaginDeleted,
                type: "success"
              };
              return response;
            } else{
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            }
            return response;
          })
          .then(data => {
            this.props.reduxLoad(false);
            this.fetchData(this.state.selectedadNetworkID);
            let jsonDataVal = data;
            if(("errorMessage" in data) && data["errorMessage"] != undefined){
              showNotification = {
                title: enMsg.failedTitle,
                message: data.errorMessage,
                type: "warning"
              };
            }else{
              originalFetchData = originalFetchData.filter((v, i) => {
                return !(v.campaignId == selectedCampaignID);
              });
            }
          })
          .catch(error => {
            originalFetchData = originalFetchData.filter((v, i) => {
              return !(v.campaignId == selectedCampaignID);
            });
            showNotification = {
              title: enMsg.connectionFailed,
              message: enMsg.networkFailedError,
              type: "danger"
            };
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
      if (
        showNotification !== undefined &&
        showNotification.title !== undefined &&
        showNotification.message !== undefined &&
        showNotification.type !== undefined
      ) {
        this.notificationID = store.removeNotification(this.notificationID);
        if (this.notificationID == undefined) {
          let notifiaciton = {
            title: showNotification.title,
            message: showNotification.message,
            type: showNotification.type
          };
          // notifiaciton = Object.assign(NotificationOptions, notifiaciton);
          // this.notificationID = store.addNotification(notifiaciton);
        }
        userService.showNotification(showNotification)
        //window.location.reload();
      }
    }
    this.setState({
      campaignJson: originalFetchData,
      originalFetchData: originalFetchData,
      selectedadNetworkID: null,
      selectedCampaignID: null,
      openAlertBoxDelete: false,
      loading: false
    });
  };

  fetchCampagin = async () => {
    if (1) {
      const { selectedAdNetworkJsonID, adNetworkJson } = this.state;
      let adNetwork = await userService.fetchAdNetwork();
      let selectedAdNetworkJsonIDLocal = null;
      this._isMounted &&
        this.setState({
          adNetworkJson: adNetwork ? adNetwork : []
        });
      let adNetworkSelectBox = this.adNetworkSelectBox;
      if (selectedAdNetworkJsonID === null) {
        selectedAdNetworkJsonIDLocal = adNetwork
          ? adNetwork[0]
            ? adNetwork[0].adNetworkId
            : 0
          : 0;
        this._isMounted &&
          this.setState({
            selectedAdNetworkJsonID: selectedAdNetworkJsonIDLocal
          });
      } else {        
        selectedAdNetworkJsonIDLocal = selectedAdNetworkJsonID;
      }

      if (selectedAdNetworkJsonIDLocal) {
        let rampaginRows = await userService.fetchCampagin(
          selectedAdNetworkJsonIDLocal
        );
        try {
          rampaginRows = rampaginRows
            ? rampaginRows.map(value => {
                value = Object.assign(value, {
                  status: CampaginStatus[value.status],
                  advertisement: value.advertisement
                    ? value.advertisement.length
                    : 0
                });

                return value;
              })
            : [];
        } catch {}
        this._isMounted &&
          this.setState({
            originalFetchData: rampaginRows,
            campaignJson: rampaginRows
          });
      }
    }
  };

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    });
  }
  handleCloseNotification = event => {
    this.setState({ openMenu: false });
  };
  handleCampaignSorting(fieldName, order){
    this.setState(
      {
        campaignShort: fieldName,
        sortOrder: order,
        loading: true,
      },
      () => this.sortAndFilter()
    );
  };
  sortAndFilter() {
    let advertisementsJson = this.state.originalFetchData;
    if (this.state.campaignShort) {
      sortJsonArray(advertisementsJson, this.state.campaignShort, this.state.sortOrder); // default is 'asc'
    }
    this.setState({
      campaignJson: advertisementsJson,
      loading: false,
    });
  }
  handleStatusPopup(event){
    // setAnchorEl(anchorEl ? null : event.currentTarget);
    this.setState({
      anchorElVal : event.currentTarget,
    });
  }
  render = () => {
    //   this.prop.classes;
    const deleteIcon = (
      <Icon>
        <span color="secondary">
          <img src={del} alt="" />
        </span>
      </Icon>
    );

    const editIcon = (
      <Icon>
        <span color="primary">
          <img src={edit} alt="" />
        </span>
      </Icon>
    );

    const dropDownIcon = (
      <Icon
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => this.handleClickOpen(event)}
      >
        <img src={dropdown} className="campaign-status-img" alt="" />
      </Icon>
    );
    // const { classes } = this.props;
    const {
      cust_table_cover,
      camph,
      tableh,
      cust_table,
      camp_btn_cover,
      camp_btn,
      icon_cover
    } = this.localClasses;
    const {
      page,
      rowsPerPage,
      openMenu,
      adNetworkJson,
      campaignJson,
      selectedCampaignStatus,
      setSelectedCampaignStatus,
      selectedAdNetworkJsonID,
      openAlertBox,
      openAlertBoxDelete,
      loading,
      anchorElVal,
    } = this.state;

    const classes = this.props.classes;
    return (
      <div>
        {/* <NotificationContainer/> */}
        <Popper
          open={Boolean(openMenu)}
          anchorEl={anchorElVal}  
          className={"dsdsdsdsds hide hidden"}
          style={{position:"relative !important"}}
          // id={column.id}
          placement="bottom"
          disablePortal={false}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={this.handleCloseNotification}>
              <MenuList role="menu" className={"status-dropdown-menu"}>
                <MenuItem value="">Change Status</MenuItem>
                {Object.keys(this.state.campaignStatusList).map(status => {
                  return (
                    <Tooltip
                  title={<span className="customTooltipClass">{this.state.campaignStatusList[status]['description']}</span>} key={this.state.campaignStatusList[status]['status']}
                      value={this.state.campaignStatusList[status]['status']}
                      >
                    <MenuItem
                      onClick={event =>
                        selectedCampaignStatus !== status
                          ? this.setState({
                              selectedCampaignStatus: this.state.campaignStatusList[status]['status'],
                              openAlertBox: true
                            })
                          : this.setState({
                              openAlertBox: false,
                              selectedadNetworkID: null,
                              selectedCampaignID: null,
                              openAlertBoxDelete: false,
                              loading: false
                            })
                      }
                      key={this.state.campaignStatusList[status]['status']}
                      value={this.state.campaignStatusList[status]['status']}
                      className={
                        selectedCampaignStatus !== this.state.campaignStatusList[status]['status'] ? "capmpaign-items" : ""
                      }
                    >
                      {selectedCampaignStatus === this.state.campaignStatusList[status]['status'] ? (
                        <img src={done} alt="" className="pop-check" />
                      ) : (
                        ""
                      )}
                      {this.state.campaignStatusList[status]['status']}
                    </MenuItem>
                    </Tooltip>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
        <Menu
          hidden={true}
          id="simple-menu"
          anchorEl={this.anchorNotification}
          keepMounted
          open={Boolean(openMenu)}
          className={"status-dropdown-menu"}
        >
          {Object.keys(this.state.campaignStatusList).map(status => {
            return (
              <MenuItem
                onClick={event =>
                  selectedCampaignStatus !== status
                    ? this.setState({
                        selectedCampaignStatus: status,
                        openAlertBox: true
                      })
                    : this.setState({
                        openAlertBox: false,
                        selectedadNetworkID: null,
                        selectedCampaignID: null,
                        openAlertBoxDelete: false,
                        loading: false
                      })
                }
                key={this.state.campaignStatusList[status]['status']}
                value={this.state.campaignStatusList[status]['status']}
                className={
                  selectedCampaignStatus !== status ? "capmpaign-items" : ""
                }
              >
                {selectedCampaignStatus === status ? (
                  <img src={done} alt="" className="pop-check" />
                ) : (
                  ""
                )}
                {CampaginStatus[status]}
              </MenuItem>
            );
          })}
        </Menu>

        {/* Alert box dialog for Change campaign status  */}
        <Dialog
          open={openAlertBox}
          disableEscapeKeyDown={true}
          onClose={event => {
            this.setState({
              openMenu: false,
              selectedCampaignID: null,
              selectedadNetworkID: null,
              openAlertBoxDelete: false,
              loading: false,
              openAlertBox: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Caution!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {enMsg.alertForChangeStatus}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="form-button-cover mt-0 mb-10">
              <Button
                className={loading ? "buttonSuccess" : ""}
                disabled={loading}
                type="button"
                onClick={this.changeCampaignStatus}
              >
                Confirm
                {loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button
                className="back-btn"
                color="secondary"
                type="button"
                onClick={event => {
                  this.setState({
                    openMenu: false,
                    selectedCampaignID: null,
                    selectedadNetworkID: null,
                    openAlertBoxDelete: false,
                    loading: false,
                    openAlertBox: false,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        {/* Alert box dialog for Delete campaign */}
        <Dialog
          open={openAlertBoxDelete}
          onClose={event => {
            this.setState({
              openMenu: null,
              selectedCampaignID: null,
              selectedadNetworkID: null
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Caution!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {enMsg.alertForDeleteCampaign}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="form-button-cover mt-0 mb-10">
              <Button
                className={loading ? "buttonSuccess" : ""}
                disabled={loading}
                type="button"
                onClick={event => this.deleteCampaign()}
              >
                Confirm
                {loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button
                className="back-btn"
                color="secondary"
                type="button"
                onClick={event => this.setState({ openAlertBoxDelete: false })}
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" className={"cusCardTitle"}>
                <h4 className={camph}>Campaigns</h4>
                {/* <p className={classes.cardCategoryWhite}>
                Here is a subtitle for this table
              </p> */}
              </CardHeader>
              <GridItem xs={12} sm={12} md={12}>
                <div className="search-btn-cover">
                  <input
                    type="text"
                    placeholder="Campaign Name"
                    className="search-btn"
                    onChange={event => this.searchCampaign(event)}
                  />
                  {/* <CustomInput
                        labelText="Last Name"
                        id="city"
                        formControlProps={{
                          fullWidth: true
                        }}
                        /> */}
                  <i className="material-icons">search</i>
                </div>
                <div className="button-cover">
                  <Link
                    to={`${baseRoutes.addCampaign.useLink}`} ///${user.username}
                    color="textSecondary"
                    className="add-campaign-btn"
                    underline="none"
                  >
                    <button className={camp_btn}>new campaign</button>
                  </Link>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {/* <p className="selectedAdnetwork">Selected Adnetwork: {this.state.selectedAdnetworkData.companyName ? this.state.selectedAdnetworkData.companyName : "Please select adnetwork"}</p> */}
                <div className="form-cover" style={{display:"none"}}>
                  <FormGroup>
                    <FormControl className={"mat_select"}>
                      <InputLabel shrink htmlFor="campaigns">
                        AdNetowrk
                      </InputLabel>
                      <Select
                        id="campaigns"
                        color="secondary"
                        name="selectedAdNetworkJsonID"
                        // value={
                        //   selectedAdNetworkJsonID ? selectedAdNetworkJsonID : 0
                        // }
                        value={selectedAdNetworkJsonID}
                        width="100px"
                        disabled={true}
                        onChange={event => {
                          let name = event.target.name;
                          this.setState(
                            {
                              [name]: event.target.value
                            },
                            () => this.fetchCampagin()
                          );
                          // selectedAdNetworkJsonID;
                        }}
                        ref={this.adNetworkSelectBox}
                        // input={
                        //   <Input
                        //     value={
                        //       selectedAdNetworkJsonID
                        //         ? selectedAdNetworkJsonID
                        //         : 0
                        //     }
                        //     id="adNetworkInput"
                        //   />
                        // }
                        input={
                          <Input
                            value={selectedAdNetworkJsonID}
                            id="campaignsInput"
                          />
                        }
                        displayEmpty
                      >
                        <MenuItem value="">Select AdNetwork</MenuItem>
                        {(typeof adNetworkJson === "object" && adNetworkJson.length)
                          ? adNetworkJson.map((adNetwork, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  value={adNetwork.adNetworkId}
                                >
                                  {adNetwork.companyName}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </FormGroup>
                </div>
              </GridItem>
              <CardBody>
                <Paper className={(classes.root, cust_table_cover)}>
                  <div className={(classes.tableWrapper, cust_table)}>
                    <Table>
                      <TableHead className={tableh}>
                        <TableRow>
                          {columns.map(column => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {(column.id != "Actions" && column.id != "type") 
                                ?
                                  <div>{column.label} <span className="shortingMain"><i className="fa fa-long-arrow-up" onClick={this.handleCampaignSorting.bind(this, column.id, "asc")} style={{position:"absolute"}}></i><i className="fa fa-long-arrow-down" onClick={this.handleCampaignSorting.bind(this, column.id, "des")}></i></span></div>
                                : 
                                  <div>{column.label}</div>
                              }
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody key="TableBody">
                        {typeof campaignJson === "object" && campaignJson.length
                          ? campaignJson
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(row => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.campaignId}
                                  >
                                    {columns.map(column => {
                                      const value = row[column.id];
                                      if (column.id == "camp_ad") {
                                        return (
                                          <TableCell key={column.id}>
                                            {row.campaignId}
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "type") {
                                        return (
                                          <TableCell key={column.id}>
                                            --
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "bus_name") {
                                        return (
                                          <TableCell key={column.id}>
                                            AdNetWork
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "advertisements") {
                                        return (
                                          <TableCell key={column.id}>
                                            {row.advertisements.length}
                                          </TableCell>
                                        );
                                      }

                                      if (column.id == "status") {
                                        return (
                                          <TableCell key={column.id}>
                                            {row.status}
                                          </TableCell>
                                        );
                                      }

                                      if (column.id == "description") {
                                        return (
                                          <TableCell key={column.id}>
                                            {value.length > 80
                                              ? row.description.substr(0, 80) +
                                                "..."
                                              : row.description}
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "Actions") {
                                        {
                                          var link =
                                            basePath +
                                            baseRoutes.editCampaign.path
                                              .replace(
                                                ADNETWORK_ID_SLUG,
                                                row.adNetworkId
                                              )
                                              .replace(
                                                CAMPAIGN_ID_SLUG,
                                                row.campaignId
                                              );
                                        }
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={"right"}
                                          >
                                            <Link
                                              to={link}
                                              className={icon_cover}
                                            >
                                              {editIcon}
                                            </Link>
                                            <Button
                                              datavalue={row.id}
                                              className={
                                                icon_cover + " del-btn"
                                              }
                                              onClick={event => {
                                                this.setState({
                                                  selectedCampaignID:
                                                    row.campaignId,
                                                  selectedadNetworkID:
                                                    row.adNetworkId,
                                                  openAlertBoxDelete: true
                                                });
                                              }}
                                            >
                                              {deleteIcon}
                                            </Button>
                                            <Button
                                              className={`${icon_cover} campaign-status`}                                              
                                              // buttonRef={node => {
                                              //   this.anchorNotification = node;
                                              // }}
                                              aria-owns={anchorElVal ? row.campaignId : row.campaignId}
                                              value={row.campaignId}
                                              onClick={this.handleStatusPopup.bind(this)}
                                              aria-describedby={row.campaignId}
                                              // onClick={handleCampaignStatus}
                                            >
                                              <Icon
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={event => {
                                                  this.setState({
                                                    selectedCampaignID:
                                                      row.campaignId,
                                                    selectedadNetworkID:
                                                      row.adNetworkId,
                                                    selectedCampaignStatus: String(
                                                      row.status
                                                    ).toLowerCase(),
                                                    openMenu:
                                                      event.currentTarget
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={dropdown}
                                                  className="campaign-status-img"
                                                  alt=""
                                                />
                                              </Icon>
                                              {/* {dropDownIcon} */}
                                            </Button>
                                          </TableCell>
                                        );
                                      }
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {column.format &&
                                          typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })
                          : null}
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTIONS}
                    component="div"
                    count={
                      typeof this.state.campaignJson === "object" &&
                      this.state.campaignJson.length
                        ? this.state.campaignJson.length
                        : 0
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page"
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Paper>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  };
}

CampaignListClass.propTypes = {
  classes: PropTypes.object
  // openChangePasswordDialog:
};
const CampaignList = connect(
  mapStateToProps, mapDispatchToProps
)(CampaignListClass);
export default withStyles(useStyles)(CampaignList);
