import React, { useState, useEffect, PureComponent } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link as MaterialLink,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Canvas } from 'react-canvas-js'



// import { Bar } from 'react-chartjs-2';
// import { HorizontalBar } from 'react-chartjs-2';
// import Chartkick from "chartkick";
// import 'chart.js';
//import { BarChart } from 'react-chartkick';
//import { BarChart, Grid } from 'react-native-svg-charts'
// import ApexCharts from 'apexcharts';
// import ReactApexChart from 'react-apexcharts'
// import * as ChartDataLabels from 'chartjs-plugin-datalabels';
//import {BarChart,Grid} from 'react-native-svg-charts';
// import * as shape from 'd3-shape';
// import * as wjChart from '@grapecity/wijmo.react.chart';
// import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation';
// import '@grapecity/wijmo.styles/wijmo.css';
// import * as d3 from "d3";
// import { linear } from "d3-scale";



//require("d3-scale");
// export default {
//   scale: {
//     linear: linear
//   }
// };
import { Chart } from "react-google-charts";
// import { dataSource } from './data.js';
// import {
//   Chart as Rea,
//   CommonSeriesSettings,
//   ValueAxis,
//   Label,
//   Legend,
//   Series,
//   Tooltip
// } from 'devextreme-react/chart';
// import {
//   Chart,
//   ChartTitle,
//   ChartSeries,
//   ChartSeriesItem,
//   ChartCategoryAxis,
//   ChartCategoryAxisTitle,
//   ChartCategoryAxisItem
// } from '@progress/kendo-react-charts';

import {
  STORED_ADNETWORK,
  PER_PAGE_OPTIONS,
  RECORD_PER_PAGE,
  SELECTED_ADNETWORK,
  STORED_CAMPAIGN_ANALYTICS
} from "__helpers/constants";
import { SELECTED_CAMPAIGN } from "__helpers/constants";
import { campaignClaims } from "__helpers/constants";
import { campaignClicks } from "__helpers/constants";
import { campaignRedeems } from "__helpers/constants";
import { campaignReached } from "__helpers/constants";
import { campaignImpression } from "__helpers/constants";
import { CSVLink } from "react-csv";
import { array } from "joi";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// then all Highcharts modules you will need
import HighchartsMore from 'highcharts/highcharts-more';
import dataModule from 'highcharts/modules/data';


// import { getData } from './data';
//import { BarChart } from 'react-charts-d3';


// adNetworkId: "f5148973-26b7-494c-9218-36c9fc1e18eb"
// adNetworkRoles: [{id: "050852b6-b28d-4af6-8d2d-31e385e03110", email: "tttbill3@gmail.com", adNetworkRole: "owner",…}]
// address: "jdh"
// city: "jdh"
// companyName: "HeroHonda"


// const BarGraph = (props) =>{
// const data = {
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   datasets: [
//     {

//       label: 'My First dataset',
//       backgroundColor: 'rgba(255,99,132,0.2)',
//       borderColor: 'rgba(255,99,132,1)',
//       borderWidth: 1,
//       hoverBackgroundColor: 'rgba(255,99,132,0.4)',
//       hoverBorderColor: 'rgba(255,99,132,1)',
//       data: [120, 159, 180, 281, 256, 155, 100]
//     }
//   ]
// };


//   return (
//     <Bar data={data} width={150}
//     height={100}/>
//   )
// }

HighchartsMore(Highcharts);
dataModule(Highcharts);
const columns = [
  // { id: "campaignId", label: "Id"},
  { id: "name", label: "Name" },
  { id: "totalAdImpressions", label: "Impressions" },
  { id: "totalAdPostImpressions", label: "Post Impressions" },
  { id: "totalAdViews", label: "Views" },
  { id: "totalAdClicks", label: "Clicks" },
  { id: "totalAdSaves", label: "Saves" },
  { id: "totalAdShares", label: "Shares" },
  { id: "totalSharedAdContentViews", label: "Shared Views" },
  { id: "totalAdClaims", label: "Claims" },
  { id: "totalAdDeletes", label: "Deletes" },
  { id: "totalAdRedeems", label: "Redeems" },
  { id: "totalApplicationsReached", label: "Application Reached" },
  { id: "totalPresenceReached", label: "Presence Reached" },
  { id: "totalDevicesReached", label: "Devices Reached" },
  { id: "totalExternalApplicationsReached", label: "External Applications Reached" },
  { id: "totalExternalDevicesReached", label: "External Devices Reached" },
];
let csvHeaders = [
  { key: "type", label: "Data Type" },
  { key: "name", label: "Name" },
  { key: "id", label: "Id" },
  { key: "adNetworkId", label: "Ad Network Id" },
  { key: "status", label: "Status" },
  { key: "adType", label: "Ad Type" },
  { key: "adMedium", label: "Ad Medium" },
  { key: "assetType", label: "Asset Type" },
  { key: "displayType", label: "Display Type" },
  { key: "adStartDate", label: "Ad StartDate" },
  { key: "adExpirationDate", label: "Ad ExpirationDate" },
  { key: "totalImpressions", label: "Impressions" },
  { key: "totalPresenceReached", label: "Presence Reached" },
  { key: "totalDevicesReached", label: "Devices Reached" },
  { key: "totalExternalApplicationsReached", label: "External Applications Reached" },
  { key: "totalExternalDevicesReached", label: "External Devices Reached" },
  { key: "ViewActionRate", label: "View Action Rate" },
  { key: "ClickActionRate", label: "Click Action Rate" },
  { key: "SaveActionRate", label: "Save Action Rate" },
  { key: "RedeemActionRate", label: "Redeem Action Rate" },
  { key: "DeleteActionRate", label: "Delete Action Rate" },
  { key: "ShareActionRate", label: "Share Action Rate" },
  { key: "SharedViewActionRate", label: "Shared View Action Rate" },
  { key: "ClaimActionRate", label: "Claim Action Rate" },
  { key: "ViewConversionRate", label: "View Conversio Rate" },
  { key: "ClickConversionRate", label: "Click Conversin Rate" },
  { key: "SaveConversionRate", label: "Save Conversion Rae" },
  { key: "DeleteConversionRate", label: "Delete Conversion Rate" },
  { key: "ShareConversionRate", label: "Share Conversion Rate" },
  { key: "SharedViewConversionRate", label: "Shared View Conversion Rate" },
  { key: "ClaimConversionRate", label: "Claim Conversion Rate" },
  { key: "ClickThroughRate", label: "Click Through Rate" },
  // { Key:"ShareConversionRate", label: "ClickThroughRate"},

];

const useStyles = {
  root: {
    width: "100%"

  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto"
  }
};

class CampaignAnalytics extends React.Component {
  cust_table_cover = "custom-table-cover";
  camph = "campaign-header";
  tableh = "table-header";
  cust_table = "custom-table"; ViewActionRate
  camp_btn_cover = "campaign-btn-cover";
  camp_btn = "campaign-btn";
  icon_cover = "icon-cover";

  constructor(props) {
    super(props);
    this.stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let selectedAdnetwork = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    selectedAdnetwork = (selectedAdnetwork) ? selectedAdnetwork.adNetworkId : "";
    let campaignAnalytics = JSON.parse(localStorage.getItem(STORED_CAMPAIGN_ANALYTICS));
    let selectedCampaign = JSON.parse(localStorage.getItem(SELECTED_CAMPAIGN));

    this._isMounted = false;
    this.state = {
      //data: getData(),
      barPercentVal: 0,
      page: 0,
      rowsPerPage: RECORD_PER_PAGE,
      loading: false,
      csvSheetData: [],
      selectedCampaign: (selectedCampaign) ? selectedCampaign : [],
      campaignAnalytics: (campaignAnalytics) ? (campaignAnalytics.campaigns) ? campaignAnalytics.campaigns : [] : [],
    };
  }
  componentDidMount() {

  }
  componentWillReceiveProps(nextProps) {
    console.log("props...caling")
    let selectedCampaignData = nextProps.selectedCampaignData
    let campaignAnalytics = nextProps.campaignAnalytics
    let csvSheetData = nextProps.csvSheetData;
    //console.log(csvSheetData)
    campaignAnalytics = (campaignAnalytics) ? (campaignAnalytics.campaigns) ? campaignAnalytics.campaigns : [] : [];
    this.setState({
      campaignAnalytics: campaignAnalytics,
      loading: nextProps.loading,
      selectedCampaign: selectedCampaignData,
      csvSheetData: csvSheetData,
    })
  }
  getPercentageCategoryVales(percentageVal, valueArr, roundFlag) {
    let rangeValArr = [];
    valueArr.map((arrVal, key) => {
      let localRangeValArr = []
      if (arrVal < 100) {
        if (arrVal == 0) {
          arrVal = arrVal + 1;
        }
        localRangeValArr.push(arrVal)
        localRangeValArr.push(100)
      } else {
        localRangeValArr.push(100)
        localRangeValArr.push(arrVal)
      }
      rangeValArr.push(localRangeValArr)
    });
    // console.log(dataArr)
    return rangeValArr;
  }
  render() {

    const classes = this.props.classes;
    const {
      campaignAnalytics,
      selectedCampaign,
      csvSheetData
    } = this.state;

    let campaignClaimsVal = (campaignAnalytics.campaignId) ? campaignAnalytics[campaignClaims[0]] : 0;
    let campaignClicksVal = (campaignAnalytics.campaignId) ? campaignAnalytics[campaignClicks[0]] : 0;
    let campaignRedeemsVal = (campaignAnalytics.campaignId) ? campaignAnalytics[campaignRedeems[0]] : 0;
    let campaignReachedVal = 0;
    let campaignImpressionVal = 0;

    campaignReached.map(campaignR => {
      campaignReachedVal = (campaignAnalytics.campaignId) ? campaignReachedVal + campaignAnalytics[campaignR] : 0;
    });
    campaignImpression.map(campaignI => {
      campaignImpressionVal = (campaignAnalytics.campaignId) ? campaignImpressionVal + campaignAnalytics[campaignI] : 0;
    });


    let chartDataOne = [['Life Cycle', 'Impressions', 'Post Impressions', 'Views', 'Clicks', 'Saves', 'Shares', 'Shared Content Views', 'Claims', 'Deletes', 'Redeems']];
    let Impressions = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdImpressions : 0;
    let postImpressions = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdPostImpressions : 0;
    let Views = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdViews : 0;
    let Clicks = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdClicks : 0;
    let Saves = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdSaves : 0;
    let Deletes = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdDeletes : 0;
    let Redeems = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdRedeems : 0;
    let Shares = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdShares : 0;
    let sharedContentViews = (campaignAnalytics.campaignId) ? campaignAnalytics.totalSharedAdContentViews : 0;
    let Claims = (campaignAnalytics.campaignId) ? campaignAnalytics.totalAdClaims : 0;
    // let ClickThroughRate=(campaignAnalytics.campaignId)? campaignAnalytics.ClickThroughRate:0;
    let adNameOne = (campaignAnalytics.campaignId) ? selectedCampaign.name : "Uknown";
    let localDataOne = [adNameOne, Impressions, postImpressions, Views, Clicks, Saves, Shares, sharedContentViews, Claims, Deletes, Redeems];
    chartDataOne.push(localDataOne);

    let chartDataTwo = [['Outreach', 'Applications Reached', 'Presence Reached', 'Devices Reached', 'External Applications Reached', 'External Devices Reached']];
    let devicesReached = (campaignAnalytics.campaignId) ? campaignAnalytics.totalDevicesReached : 0;
    let applicationsReached = (campaignAnalytics.campaignId) ? campaignAnalytics.totalApplicationsReached : 0;
    let presenceReached = (campaignAnalytics.campaignId) ? campaignAnalytics.totalPresenceReached : 0;
    let externaldevicesReached = (campaignAnalytics.campaignId) ? campaignAnalytics.totalExternalDevicesReached : 0;
    let externalApplicationsReached = (campaignAnalytics.campaignId) ? campaignAnalytics.totalExternalApplicationsReached : 0;

    let adNameTwo = (campaignAnalytics.campaignId) ? selectedCampaign.name : "Uknown";
    let localDataTwo = [adNameTwo, applicationsReached, presenceReached, devicesReached, externalApplicationsReached, externaldevicesReached];
    chartDataTwo.push(localDataTwo);

    //for conversion rate
    let chartDataConversion = [['Conversion Rate', 'ViewConversionRate', 'ClickConversionRate', 'SaveConversionRateave', 'DeleteConversionRate', 'ShareConversionRate', 'SharedViewConversionRate', 'ClaimConversionRate']];

    let ViewConversionRate = (campaignAnalytics.campaignId) ? campaignAnalytics.ViewConversionRate : 0;
    let ClickConversionRate = (campaignAnalytics.campaignId) ? campaignAnalytics.ClickConversionRate : 0;
    let SaveConversionRate = (campaignAnalytics.campaignId) ? campaignAnalytics.SaveConversionRate : 0;
    //let RedeemActionRate =(campaignAnalytics.campaignId)? campaignAnalytics.RedeemActionRate:0;
    let DeleteConversionRate = (campaignAnalytics.campaignId) ? campaignAnalytics.DeleteActionRate : 0;
    let ShareConversionRate = (campaignAnalytics.campaignId) ? campaignAnalytics.ShareConversionRate : 0;
    let SharedViewConversionRate = (campaignAnalytics.campaignId) ? campaignAnalytics.SharedViewConversionRate : 0;
    let ClaimConversionRate = (campaignAnalytics.campaignId) ? campaignAnalytics.ClaimConversionRate : 0;
    let camConversionOne = (campaignAnalytics.campaignId) ? selectedCampaign.name : "Uknown";
    var conversationValArr = [ViewConversionRate, ClickConversionRate, SaveConversionRate, DeleteConversionRate, ShareConversionRate, SharedViewConversionRate, ClaimConversionRate]             //[-10,200,100000,-50,5000]; 
    // var conversationValArr = [2, 30000, 1978, 90, 11180, 77, 13]
    const displayConvertedVals = this.getPercentageCategoryVales(1, conversationValArr, true);
    var chartLabels = ['View Conversion Rate', 'Click Conversion Rate', 'Save Conversion Rate', 'Delete Conversion Rate', 'Share Conversion Rate', 'Shared View Conversion Rate', 'Claim Conversion Rate']
    const highChartOption = {
      chart: {
        type: 'columnrange',
        inverted: true,
        zoom: "xy"
      },
      title: {
        text: 'Conversion Rate'
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        title: {
          text: 'User Action'
        },
        type: 'category',
        categories: chartLabels,
        labels: {
          formatter: function () {
            return chartLabels[this.pos]
          }
        }
      },
      yAxis: {
        title: {
          text: 'values'
        },
        type: 'logarithmic',
        min: 1,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          console.log(this, this.point.low); // just to see , what data you can access
          let barValue = this.point.high == 100 ? this.point.low : this.point.high
          return '<b>' + this.x +
            '</b>: <b>' + barValue + ' %</b>';
        }
      },
      colors: ['#b0120a', '#ffab91', '#ED5534', '#433C39', '#545150', '#B09187', '#B087A0'],
      plotOptions: {
        columnrange: {
          dataLabels: {
            // enabled: true,
            //  format: '{y}°C'
          },
          colorByPoint: true
        },
        series: {
          animation: false
        },
        column: {
          // colorByPoint: true
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Action Rates',
        data: displayConvertedVals,
      }]
    };


    //for Action Rate
    let chartUserRate = [['User Action Rate', 'ViewActionRate', 'ClickActionRate', 'SaveActionRate', 'RedeemActionRate', 'DeleteActionRate', 'ShareActionRate', 'SharedViewActionRate', 'ClaimActionRate']];
    let View = (campaignAnalytics.campaignId) ? campaignAnalytics.ViewActionRate : 0;
    let Click = (campaignAnalytics.campaignId) ? campaignAnalytics.ClickActionRate : 0;
    let Save = (campaignAnalytics.campaignId) ? campaignAnalytics.SaveActionRate : 0;
    let Redeem = (campaignAnalytics.campaignId) ? campaignAnalytics.RedeemActionRate : 0;
    let Delete = (campaignAnalytics.campaignId) ? campaignAnalytics.DeleteActionRate : 0;
    let Share = (campaignAnalytics.campaignId) ? campaignAnalytics.ShareActionRate : 0;
    let SharedView = (campaignAnalytics.campaignId) ? campaignAnalytics.SharedViewActionRate : 0;
    let Claim = (campaignAnalytics.campaignId) ? campaignAnalytics.ClaimActionRate : 0;
    let camActionOne = (campaignAnalytics.campaignId) ? selectedCampaign.name : "Uknown";
    let localActionRate = [camActionOne, View, Click, Save, Redeem, Delete, Share, SharedView, Claim];
    chartUserRate.push(localActionRate);

    //for clickThrough Rate
    let chartClickThrough = [['ClickThroughRate', 'ClickThroughRate']];
    let ClickThroughRate = (campaignAnalytics.campaignId) ? campaignAnalytics.ClickThroughRate : 0;
    let camThroughOne = (campaignAnalytics.campaignId) ? selectedCampaign.name : "Uknown";
    let localClickThroughConversion = [camThroughOne, ClickThroughRate];
    chartClickThrough.push(localClickThroughConversion);
    // console.log(chartClickThrough)


    let chartDataThree = [['Campaign External Reached', 'External Devices Reached', 'External Applications Reached']];
    let externalPresenceReached = (campaignAnalytics.campaignId) ? campaignAnalytics.totalPresenceReached : 0;
    let adNameThree = (campaignAnalytics.campaignId) ? selectedCampaign.name : "Uknown";
    let localDataThree = [adNameThree, externaldevicesReached, externalApplicationsReached];
    chartDataThree.push(localDataThree);

    let chartDataTop = [
      ["Campaign", "Advertisement"],
      ["View", View],
      ["Click", Click],
      ["Save", Save],
      ["Redeem", Redeem],
      ["Delete", Delete],
      ["Share", Share],
      ["SharedView", SharedView],
      ["Claim", Claim]

    ];
    if (!(View + Click + Save + Redeem + Delete + Share + SharedView + Claim)) {
      chartDataTop = null;
    }
    const chartEmpty = [
      ["Campaign", "Advertisement"],
      ["", 1]
    ];
    const chartDataSecond = [
      ["Campaign", "Advertisement"],
      ['Applications Reached', applicationsReached],
      ['Presence Reached', presenceReached],
      ['Devices Reached', devicesReached],
      ['External Applications Reached', externalApplicationsReached],
      ['External Devices Reached', externaldevicesReached]
    ];
    // console.log( chartDataTop >=0 ?chartDataTop:chartEmpty)
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" className={"cusCardTitle"}>
                <h4 className={this.camph}>Campaign Analytics {(csvSheetData && csvSheetData.length) ? <CSVLink data={csvSheetData} headers={csvHeaders} className="tblDownloadBtn" filename={"analyticsData.csv"}><i className="fa fa-download"></i></CSVLink> : ""}</h4>
              </CardHeader>
              <CardBody>
                {this.state.loading && (
                  <LinearProgress
                    color="primary"
                  />
                )}
                {(campaignAnalytics.campaignId)
                  ?
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6} lg={6} className="mb-30">
                        <span className={'text-select chart-title'}>Life Cycle</span>
                        <Chart
                          width={'100%'}
                          height={'500px'}
                          className="chart-css"
                          chartType="Bar"
                          loader={<LinearProgress color="primary" />}
                          data={chartDataOne}
                          options={{
                            // Material design options
                            chart: {
                              // title: 'Life Cycle',
                              // subtitle: 'Impressions, Post Impressions, Views, Clicks, Saves, Shares, Shared Content Views, Claims, Deletes, Redeems',
                            },
                          }}
                          // For tests
                          rootProps={{ 'data-testid': '1' }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={6} className="mb-30">
                        <span className={'text-select chart-title'}>Outreach</span>
                        <Chart
                          width={'100%'}
                          height={'500px'}
                          className="chart-css"
                          chartType="Bar"
                          loader={<LinearProgress color="primary" />}
                          data={chartDataTwo}
                          options={{
                            // Material design options
                            chart: {
                              // title: 'Outreach',
                              // subtitle: 'Applications Reached, Presence Reached, Devices Reached, Applications External Reached, Device External Reached',
                            },
                          }}
                          // For tests
                          rootProps={{ 'data-testid': '2' }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6} lg={6} className="mb-30">
                        <span className={'text-select chart-title'}>User Action Rate</span>
                        {/* <div style={{width:'80px',height:"80px",backgroundColor:"red",borderRadius:"40px"}}>

                        </div> */}
                        <Chart
                          width={'100%'}
                          height={'500px'}
                          chartType="PieChart"
                          loader={<div>Loading Chart</div>}
                          className="chart-css"
                          // data={[['Task','Hours per day'],["",1]]}
                          data={chartDataTop ? chartDataTop : chartEmpty}
                          options={{

                            // title: 'User Action Rate',
                            // Just add this option
                            is3D: true,

                          }}
                          rootProps={{ 'data-testid': '7' }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={6} className="mb-30">
                        <span className={'text-select chart-title'}>Click Through Rate</span>
                        <Chart
                          width={'100%'}
                          height={'500px'}
                          className="chart-css"
                          chartType="BarChart"
                          loader={<div>Loading Chart</div>}
                          data={chartClickThrough}
                          options={{
                            title: 'Click Through Rate',
                            chartArea: { width: '50%' },
                            colors: ['#ffab91', '#b0120a'],
                            hAxis: {
                              // title: 'Click Through Rate',
                              minValue: 0,
                              // maxValue: 100,
                            },
                            vAxis: {
                              // title: 'Value',
                            },
                          }}
                          // For tests
                          rootProps={{ 'data-testid': '4' }}
                        />


                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12} className="mb-30">
                        <span className={'text-select chart-title'}>Conversion Rate</span>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={highChartOption}
                          className="chart-css"
                        />
                      </GridItem>

                    </GridContainer>

                  </GridItem>
                  :
                  ""
                }
                <Paper className={(classes.root, this.cust_table_cover)}>
                  <div className={(classes.tableWrapper, this.cust_table)}>
                    <Table>
                      <TableHead className={this.tableh}>
                        <TableRow>
                          {columns.map(column => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {(column.id == "campaignId") ?
                                <div>
                                  {column.label}
                                </div>
                                :
                                <div>
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {column.label}
                                  </span>
                                </div>
                              }
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody key="TableBody">
                        {(campaignAnalytics) ?
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            {/* <TableCell>{campaignAnalytics.campaignId ? campaignAnalytics.campaignId : 0}</TableCell> */}
                            <TableCell>{selectedCampaign.name ? selectedCampaign.name : ""}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdImpressions ? campaignAnalytics.totalAdImpressions : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdPostImpressions ? campaignAnalytics.totalAdPostImpressions : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdViews ? campaignAnalytics.totalAdViews : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdClicks ? campaignAnalytics.totalAdClicks : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdSaves ? campaignAnalytics.totalAdSaves : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdShares ? campaignAnalytics.totalAdShares : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalSharedAdContentViews ? campaignAnalytics.totalSharedAdContentViews : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdClaims ? campaignAnalytics.totalAdClaims : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdDeletes ? campaignAnalytics.totalAdDeletes : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalAdRedeems ? campaignAnalytics.totalAdRedeems : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalApplicationsReached ? campaignAnalytics.totalApplicationsReached : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalPresenceReached ? campaignAnalytics.totalPresenceReached : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalDevicesReached ? campaignAnalytics.totalDevicesReached : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalExternalApplicationsReached ? campaignAnalytics.totalExternalApplicationsReached : 0}</TableCell>
                            <TableCell>{campaignAnalytics.totalExternalDevicesReached ? campaignAnalytics.totalExternalDevicesReached : 0}</TableCell>
                          </TableRow>
                          :
                          null
                        }
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTIONS}
                    component="div"
                    count={
                      1
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page"
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Paper>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
// console.log(chartDataTop?chartDataTop:chartEmpty);
CampaignAnalytics.propTypes = {
  classes: PropTypes.object
  // openChangePasswordDialog:
};


export default withStyles(useStyles)(CampaignAnalytics)



