/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, FormGroup, Hidden, CircularProgress } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { FormErrors } from "./../../components/Login/FormErrors";
import { apiPath } from "api";

import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { userService } from "_services/user.service";
import { authHeader } from "__helpers/auth-header";

import {
  ZIPCODE_REGEX,
  EMAIL_REGEX,
  NotificationOptions,
  ZIP_CODE_LENGTH,
  DIGIT_ONLY
} from "__helpers/constants";
import enMsg from "__helpers/locale/en/en";
import baseRoutes from "base-routes";
import { NotificationContainer } from "react-notifications";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class AdNetwork extends React.Component {
  constructor(props) {
    super(props);
    const {adnetworkID} = this.props.match.params;
    this._isMounted = false;
    this.state = {
      adnetworkID:adnetworkID?adnetworkID:'',
      companyName: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      businessType: "",
      email: "",
      formErrors: {
        companyName: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        businessType: "",
        email: ""
      },
      emailValid: false,
      companyNameValid: false,
      addressValid: false,
      cityValid: false,
      stateValid: false,
      zipcodeValid: false,
      businessTypeValid: false,
      formValid: false,
      apiPath: apiPath.adnetwork,
      showNotification: {},
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleZipCodeInput = this.handleZipCodeInput.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  handleZipCodeInput = e => {    
    const value = e.target.value;
    if (value.length > ZIP_CODE_LENGTH) {
      return true;
    }

    if (value.trim() == "" || value.match(DIGIT_ONLY)) {
      this.setState({ zipcode: value }, () => {
        this.validateField("zipcode", value);
      });
    }
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let companyNameValid = this.state.companyNameValid;
    let addressValid = this.state.addressValid;
    let cityValid = this.state.cityValid;
    let stateValid = this.state.stateValid;
    let zipcodeValid = this.state.zipcodeValid;
    let businessTypeValid = this.state.businessTypeValid;
    let emailValid = this.state.emailValid;

    switch (fieldName) {
      case "companyName":
        companyNameValid = value.trim().length ? true : false;
        fieldValidationErrors.companyName = enMsg.companyNameRequired;
        break;
      case "address":
        addressValid = value.trim().length ? true : false;
        fieldValidationErrors.address = enMsg.addressRequired;
        break;
      case "city":
        cityValid = value.trim().length ? true : false;
        fieldValidationErrors.city = enMsg.cityRequired;
        break;
      case "state":
        stateValid = value.trim().length ? true : false;
        fieldValidationErrors.state = enMsg.stateRequired;
        break;
      case "zipcode":
        zipcodeValid = true;
        zipcodeValid = value.match(ZIPCODE_REGEX);
        if (value.match(DIGIT_ONLY) && value.trim().length != ZIP_CODE_LENGTH) {
          fieldValidationErrors.zipcode = enMsg.zipCodeMinLengthError;
        } else if (!value.match(ZIPCODE_REGEX)) {
          fieldValidationErrors.zipcode = enMsg.invalidZipCode;
        }
        break;
      case "businessType":
        businessTypeValid = value.trim().length ? true : false;
        fieldValidationErrors.businessType = enMsg.businessNameeRequired;
        break;
      case "email":
        emailValid = value.match(EMAIL_REGEX);
        fieldValidationErrors.email = emailValid ? "" : enMsg.inValidEmail;
        break;

      default:
        companyNameValid = true;
        addressValid = true;
        cityValid = true;
        stateValid = true;
        zipcodeValid = true;
        businessTypeValid = true;
        emailValid = true;
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        companyNameValid: companyNameValid,
        addressValid: addressValid,
        cityValid: cityValid,
        stateValid: stateValid,
        zipcodeValid: zipcodeValid,
        businessTypeValid: businessTypeValid,
        emailValid: emailValid
      },
      this.validateForm
    );
  }

  validateForm() {
    return (
      this.state.companyNameValid &&
      this.state.addressValid &&
      this.state.cityValid &&
      this.state.stateValid &&
      this.state.zipcodeValid &&
      this.state.businessTypeValid
      // && this.state.emailValid
    );
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    this._isMounted = false;
    this.fetchAll();
    const req = null;
    const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
  }
  handleChange(event) {
    this.setState({ username: event.target.value });
  }
  componentDidMount() {
    this._isMounted = true;
    userService.refreshClientAouth();
    let adnetworkdata =   userService.fetchAdNetwork(this.state.adnetworkID);    
    // this.setState({
    //   user: JSON.parse(localStorage.getItem("user")),
    //   users: { loading: true }
    // });
    // userService.getAll().then(users => this.setState({ users }));
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //    logErrorToMyService(error, info);
  }
  resetForm = () => {
    const node = ReactDOM.findDOMNode(this);
    node.querySelector("#postAdNetworkForm").reset();
    const labelNodes = node.querySelectorAll(
      ".MuiInputLabel-shrink.MuiFormLabel-filled"
    );

    this.setState(
      {
        companyName: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        businessType: "",        
        companyNameValid: false,
        addressValid: false,
        cityValid: false,
        stateValid: false,
        zipcodeValid: false,
        businessTypeValid: false        
      }
      // ,
      // () => {
      //   for (let fields in this.state.formErrors) {
      //     this.state.formErrors[fields] = "";
      //   }
      // }
    );    

    const errorNodes = node.querySelectorAll("p.error-class");
    if (typeof errorNodes == "object") {
      errorNodes.forEach(function(currentValue, currentIndex, listObj) {
        currentValue.innerHtml = "&nbsp;";
      });
    }

    if (typeof labelNodes == "object") {
      labelNodes.forEach(function(currentValue, currentIndex, listObj) {
        return currentValue.classList.remove(
          "MuiInputLabel-shrink",
          "MuiFormLabel-filled"
        );
      });
    }
  };
  async fetchAll(){
    await userService.fetchAdNetwork();
  }
  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();
    const data = {
      companyName: this.state.companyName,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zipcode: this.state.zipcode,
      busbusinessType: this.state.businessType,
      email: this.state.email,
      campaigns: []
    };
    let showNotification = {};
    try {
      const response = await fetch(this.state.apiPath, {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
      })
        .then(response => {
          const contentType = response.headers.get("content-type");
          if (response.status === 400) {
            if(contentType && contentType.indexOf("application/json") !== -1){
              return response.json();
            }else{
              showNotification = {
                title: enMsg.createAdnetworkFailed,
                message: enMsg.invalidData400,
                type: "danger"
              };
            }
          } else if (response.ok) {
            showNotification = {
              title: enMsg.successTitle,
              message: enMsg.adnetworkCreate,
              type: "success"
            };
            this.resetForm();
            userService.refreshClientAdNetwork();
            setTimeout(function() {
              window.history.back();
            }, 2000);
            return response.json();
          } else {
            if(contentType && contentType.indexOf("application/json") !== -1){
              return response.json();
            }else{
              showNotification = {
                title: enMsg.createAdnetworkFailed,
                message: response.statusText,
                type: "danger"
              };
            }
          }
        })
        .then(data => {
          if(("errorMessage" in data) && data["errorMessage"] != undefined){
            showNotification = {
              title: enMsg.failedTitle,
              message: data.errorMessage,
              type: "warning"
            };
          }
        })
        .catch(error => {
          showNotification = {
            title: enMsg.createAdnetworkFailed,
            message: enMsg.networkFailedError,
            type: "danger"
          };
          return response;
        });
        
        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.createAdnetworkFailed,
          message: enMsg.networkFailedError,
          type: "danger"
        };
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
        // notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        // this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification)
      //window.location.reload();
    }
    this._isMounted && this.setState({ loading: false });
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        {/* <NotificationContainer/> */}
        <Card key="adNetowrkCard">
          <CardHeader color="success" className={"cusCardTitle"}>
            <h4 className={classes.cardTitleWhite + " cusCardTitle"}>
              Create Ad Network
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify={"center"}>
              <GridItem md={12} xs={12}>
                {/* <div className="green-header">Login</div> */}
                <div className="adnetwork-content">
                  <div className="adnetwork">
                    <form
                      onSubmit={this.handleSubmit}
                      noValidate
                      id="postAdNetworkForm"
                    >
                      <GridContainer>
                        <GridItem md={3} xs={12}>
                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="Company Name"
                              labelProps={{
                                className: "required-label"
                              }}
                              id="comp-name"
                              className="cusInputCl"
                              inputProps={{
                                type: "text",
                                name: "companyName",
                                autoComplete: "off",
                                required: true,
                                value: this.state.companyName,
                                inputRef: input => (this.companyName = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.companyNameValid}
                              formErrors={this.state.formErrors}
                              fieldName="companyName"
                            />
                          </FormGroup>

                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              color="primary"
                              labelText="City"
                              labelProps={{
                                className: "required-label"
                              }}
                              id="city"
                              inputProps={{
                                type: "text",
                                name: "city",
                                autoComplete: "off",
                                required: true,
                                value: this.state.city,
                                inputRef: input => (this.city = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.cityValid}
                              formErrors={this.state.formErrors}
                              fieldName="city"
                            />
                          </FormGroup>

                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              color="primary"
                              labelText="ZipCode"
                              labelProps={{
                                className: "required-label"
                              }}
                              id="zipcode"
                              inputProps={{
                                type: "text",
                                name: "zipcode",
                                autoComplete: "off",
                                maxLength: ZIP_CODE_LENGTH,
                                required: true,
                                // value: this.state.zipcode,
                                value: this.state.zipcode,
                                inputRef: input => (this.zipcode = input),
                                onChange: event =>
                                  this.handleZipCodeInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.zipcodeValid}
                              formErrors={this.state.formErrors}
                              fieldName="zipcode"
                            />
                          </FormGroup>

                          {/* <FormGroup className={"cusForm"}>
                          <CustomInput
                            labelText="Email*"
                            id="email"
                            inputProps={{
                              type: "email",
                              name: "email",
                              autoComplete: "off",
                              required: true,
                              value: this.state.email,
                              inputRef: input => (this.email = input),
                              onChange: event => this.handleUserInput(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.emailValid}
                            formErrors={this.state.formErrors}
                            fieldName="email"
                          />
                        </FormGroup> */}
                        </GridItem>
                        <GridItem md={3} xs={12}>
                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="Address"
                              labelProps={{
                                className: "required-label"
                              }}
                              id="address"
                              inputProps={{
                                type: "text",
                                name: "address",
                                autoComplete: "off",
                                required: true,
                                value: this.state.address,
                                inputRef: input => (this.address = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.addressValid}
                              formErrors={this.state.formErrors}
                              fieldName="address"
                            />
                          </FormGroup>

                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="State"
                              labelProps={{
                                className: "required-label"
                              }}
                              id="state"
                              inputProps={{
                                type: "text",
                                name: "state",
                                autoComplete: "off",
                                required: true,
                                value: this.state.state,
                                inputRef: input => (this.address = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.stateValid}
                              formErrors={this.state.formErrors}
                              fieldName="state"
                            />
                            {/* <TextField label="State" name="name" value="" data-validators="isRequired,isAlpha" onChange={this.handleChange}/>  */}
                          </FormGroup>

                          <FormGroup className={"cusForm"}>
                            <CustomInput
                              labelText="BusinessType"
                              labelProps={{
                                className: "required-label"
                              }}
                              id="business"
                              inputProps={{
                                type: "text",
                                name: "businessType",
                                autoComplete: "off",
                                required: true,
                                value: this.state.business,
                                inputRef: input => (this.business = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.businessTypeValid}
                              formErrors={this.state.formErrors}
                              fieldName="businessType"
                            />
                            {/* <TextField label="Business Type" type="password"  name="name" value="" data-validators="isRequired,isAlpha" onChange={this.handleChange}/>  */}
                          </FormGroup>
                        </GridItem>
                      </GridContainer>
                      <div className="form-button-cover">
                        <Button
                          // variant="contained"
                          // color="primary"
                          className={this.state.loading ? "buttonSuccess" : ""}
                          disabled={this.state.loading || !this.validateForm()}
                          type="submit"
                          id="submitBtn"
                        >
                          Create
                          {this.state.loading && (
                            <CircularProgress
                              size={24}
                              className="buttonProgress"
                            />
                          )}
                        </Button>
                        <Button
                          //disabled={!this.validateForm()}
                          onClick={this.resetForm}
                          type="button"
                        >
                          Reset
                        </Button>
                        <NavLink
                          color="textSecondary"
                          underline="none"
                          className="cancel-back-btn"
                          to={baseRoutes.adNetworkList.useLink}
                        >
                          <Button
                            className="back-btn"
                            color="secondary"
                            type="button"
                          >
                            Cancel
                          </Button>
                        </NavLink>
                      </div>
                    </form>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

AdNetwork.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(AdNetwork);
