import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";

// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {
  NotificationOptions,
  CampaginStatus,
  STORED_ADNETWORK,
  ADNETWORK_ID_SLUG,
  CAMPAIGN_ID_SLUG,
  VENDOR_ID_SLUG,
  PER_PAGE_OPTIONS,
  RECORD_PER_PAGE,
  SELECTED_ADNETWORK,
  STORED_CAMPAIGN_ANALYTICS,
  assetsClaims,
  assetsClicks,
  assetsReached,
  assetsImpression,
  assetsRedeems,
} from "__helpers/constants";
import { STORED_ADVERTISEMENT_ANALYTICS } from "__helpers/constants";
import { STORED_ASSETS_ANALYTICS } from "__helpers/constants";
import { CSVLink, CSVDownload } from "react-csv";
import { STORED_ASSETS } from "__helpers/constants";
import Chart from "react-google-charts";
import { array } from "joi";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// then all Highcharts modules you will need
import HighchartsMore from 'highcharts/highcharts-more';
import dataModule from 'highcharts/modules/data';


HighchartsMore(Highcharts);
dataModule(Highcharts);
const columns = [
  // { id: "assetId", label: "Id"},
  // { id: "name", label: "Name"},
  // { id: "numClaims", label: "Claims" },
  // { id: "numClicks", label: "Clicks" },
  // { id: "numApplicationsReached", label: "Applications" },
  // { id: "numImpressions", label: "Impressions" },
  // { id: "numPostImpressions", label: "Post Impressions" },
  // { id: "numSaves", label: "Saves" },
  // { id: "numShares", label: "Shares" },
  // { id: "numViews", label: "Views" },
  // { id: "numDevicesReached", label: "Devices" },
  // { id: "numRedeems", label: "Redeems" },
  // { id: "numDeletes", label: "Deletes" },
  // { id: "numExternalApplicationsReached", label: "External Applications" },
  // { id: "numExternalDevicesReached", label: "External Devices" },
  // { id: "numPresenceReached", label: "Presence" },
  // { id: "numSharedContentViews", label: "Shared Content" },

  { id: "assetName", label: "Name" },
  { id: "numImpressions", label: "Impressions" },
  { id: "numPostImpressions", label: "Post Impressions" },
  { id: "numViews", label: "Views" },
  { id: "numClicks", label: "Clicks" },
  { id: "numSaves", label: "Saves" },
  { id: "numShares", label: "Shares" },
  { id: "numSharedContentViews", label: "Shared Views" },
  { id: "numClaims", label: "Claims" },
  { id: "numDeletes", label: "Deletes" },
  { id: "numRedeems", label: "Redeems" },
  { id: "numApplicationsReached", label: "Application Reached" },
  { id: "numPresenceReached", label: "Presence Reached" },
  { id: "numDevicesReached", label: "Devices Reached" },
  { id: "numExternalApplicationsReached", label: "External Applications Reached" },
  { id: "numExternalDevicesReached", label: "External Devices Reached" },
];
let csvHeaders = [
  { key: "assetName", label: "Assets Name" },
  { key: "assetId", label: "Assets Id" },
  { key: "assetType", label: "Asset Type" },
  { key: "displayType", label: "Display Type" },
  { key: "numImpressions", label: "Impressions" },
  { key: "numPostImpressions", label: "Post Impressions" },
  { key: "numViews", label: "Views" },
  { key: "numClicks", label: "Clicks" },
  { key: "numSaves", label: "Saves" },
  { key: "numShares", label: "Shares" },
  { key: "numSharedContentViews", label: "Shared Views" },
  { key: "numClaims", label: "Claims" },
  { key: "numDeletes", label: "Deletes" },
  { key: "numRedeems", label: "Redeems" },
  { key: "numApplicationsReached", label: "Application Reached" },
  { key: "numPresenceReached", label: "Presence Reached" },
  { key: "numDevicesReached", label: "Devices Reached" },
  { key: "numExternalApplicationsReached", label: "External Applications Reached" },
  { key: "numExternalDevicesReached", label: "External Devices Reached" },
  { key: "ViewActionRate", label: "View Action Rate" },
  { key: "ClickActionRate", label: "Click Action Rate" },
  { key: "SaveActionRate", label: "Save Action Rate" },
  { key: "RedeemActionRate", label: "Redeem Action Rate" },
  { key: "DeleteActionRate", label: "Delete Action Rate" },
  { key: "ShareActionRate", label: "Share Action Rate" },
  { key: "SharedViewActionRate", label: "Shared View Action Rate" },
  { key: "ClaimActionRate", label: "Claim Action Rate" },
  { key: "ViewConversionRate", label: "View Conversio Rate" },
  { key: "ClickConversionRate", label: "Click Conversin Rate" },
  { key: "SaveConversionRate", label: "Save Conversion Rae" },
  { key: "DeleteConversionRate", label: "Delete Conversion Rate" },
  { key: "ShareConversionRate", label: "Share Conversion Rate" },
  { key: "SharedViewConversionRate", label: "Shared View Conversion Rate" },
  { key: "ClaimConversionRate", label: "Claim Conversion Rate" },
  { key: "ClickThroughRate", label: "Click Through Rate" },
];


const useStyles = {
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto"
  }
};

class AssetAnalytics extends React.Component {
  cust_table_cover = "custom-table-cover";
  camph = "campaign-header";
  tableh = "table-header";
  cust_table = "custom-table";
  camp_btn_cover = "campaign-btn-cover";
  camp_btn = "campaign-btn";
  icon_cover = "icon-cover";

  constructor(props) {
    super(props);
    this.stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let selectedAdnetwork = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    selectedAdnetwork = (selectedAdnetwork) ? selectedAdnetwork.adNetworkId : "";
    let assetAnalytics = JSON.parse(localStorage.getItem(STORED_ASSETS_ANALYTICS));
    let assetsData = JSON.parse(localStorage.getItem(STORED_ASSETS));
    assetsData = props.assetsList ? props.assetsList : [];
    this._isMounted = false;
    this.state = {
      chartEmpty: [],

      chartDataTop: [],
      page: 0,
      rowsPerPage: RECORD_PER_PAGE,
      selectedAssetId: "",
      selectedAd: "",
      recyleChartData: [],
      reachedChartData: [],
      reachedConversion: [],
      recycleClickThrough: [],
      assetsData: (assetsData) ? assetsData : [],
      loading: (assetAnalytics) ? false : true,
      assetAnalytics: (assetAnalytics) ? (assetAnalytics.assets) ? assetAnalytics.assets : [] : [],
      conversationValArr: [],
      displayConvertedVals: [],
      percentVal: 0,
      highChartOption: {},
    };

    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  async componentDidMount() {
    let newAssetsData = []
    this.setState({
      loading: true,
    })
    if (this.state.assetAnalytics) {
      this.state.assetAnalytics.map(assetAnalytics => {
        this.state.assetsData.map(assetData => {
          if (assetAnalytics.assetId == assetData.assetId) {
            assetAnalytics.name = (assetData.name) ? assetData.name : "unknown";
          }
        });
        newAssetsData.push(assetAnalytics);
      });
    }
    this.setState({
      assetAnalytics: newAssetsData,
      loading: false,
    })
  }
  getPercentageCategoryVales(percentageVal, valueArr, roundFlag) {
    let rangeValArr = [];
    valueArr.map((arrVal, key) => {
      let localRangeValArr = []
      if (arrVal < 100) {
        if (arrVal == 0) {
          arrVal = arrVal + 1;
        }
        localRangeValArr.push(arrVal)
        localRangeValArr.push(100)
      } else {
        localRangeValArr.push(100)
        localRangeValArr.push(arrVal)
      }
      rangeValArr.push(localRangeValArr)
    });
    console.log(rangeValArr)
    return rangeValArr;
  }
  componentWillReceiveProps(nextProps) {
    let selectedAd = nextProps.selectedAd ? nextProps.selectedAd : "";
    if (selectedAd != this.state.selectedAd) {
      // console.log("selected advertisement");
      let assetAnalytics = nextProps.assetAnalytics
      assetAnalytics = (assetAnalytics) ? (assetAnalytics.assets) ? assetAnalytics.assets : [] : [];
      let newAssetsData = []
      let assetsList = nextProps.assetsList ? nextProps.assetsList : [];
      let selectedAssetId = (nextProps.selectedAd == this.state.selectedAd) ? this.state.selectedAssetId : "";
      this.setState({
        assetAnalytics: assetAnalytics,
        loading: nextProps.loading,
        selectedAd: nextProps.selectedAd,
        selectedAssetId: selectedAssetId,
        assetsData: assetsList
      })
    }
  }
  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    });
  }
  handleChangePage = async (event, newPage) => {
    this.setState({
      page: newPage
    });
  };
  filterAssetAnalytics(selectedAssetId) {
    this.setState({
      loading: true,
    });

    let tempAssetArr = {};
    let assetsData = [];
    let assetAnalytics = [];
    let assets = [];
    let chartDataOne = [['Life Cycle', 'Impressions', 'Post Impressions', 'Views', 'Clicks', 'Saves', 'Shares', 'Shared Content Views', 'Claims', 'Deletes', 'Redeems']];
    let chartDataTwo = [['Outreach', 'Applications Reached', 'Presence Reached', 'Devices Reached', 'External Applications Reached', 'External Devices Reached']];
    let chartDataConversion = [['Conversion Rate', 'ViewConversionRate', 'ClickConversionRate', 'SaveConversionRate', 'DeleteConversionRate', 'ShareCoversionRate', 'SharedViewConversionRate', 'ClaimConversionRate']];
    let chartClickThrough = [['Click Through Rate', 'ClickThroughRate']];
    let chartUserRate = [['UserActionRate', 'ViewActionRate', 'ClickActionRate', 'SaveActionRate', 'RedeemActionRate', 'DeleteActionRate', 'ShareActionRate', 'SharedViewActionRate', 'ClaimActionRate']];
    let chartDataTop = [];
    let chartEmpty = [];
    let highChartOption = {};
    try {
      assetAnalytics = this.state.assetAnalytics;
      if (assetAnalytics) {
        console.log(assetAnalytics);
        assetAnalytics.map((localAnalyticVar, key) => {
          console.log(localAnalyticVar);
          if (localAnalyticVar.assetId == selectedAssetId) {
            let Impressions = (localAnalyticVar.assetId) ? localAnalyticVar.numImpressions : 0;
            let postImpressions = (localAnalyticVar.assetId) ? localAnalyticVar.numPostImpressions : 0;
            let Views = (localAnalyticVar.assetId) ? localAnalyticVar.numViews : 0;
            let Clicks = (localAnalyticVar.assetId) ? localAnalyticVar.numClicks : 0;
            let Saves = (localAnalyticVar.assetId) ? localAnalyticVar.numSaves : 0;
            let Deletes = (localAnalyticVar.assetId) ? localAnalyticVar.numDeletes : 0;
            let Redeems = (localAnalyticVar.assetId) ? localAnalyticVar.numRedeems : 0;
            let Shares = (localAnalyticVar.assetId) ? localAnalyticVar.numShares : 0;
            let sharedContentViews = (localAnalyticVar.assetId) ? localAnalyticVar.numSharedContentViews : 0;
            let Claims = (localAnalyticVar.assetId) ? localAnalyticVar.numClaims : 0;

            let devicesReached = (localAnalyticVar.assetId) ? localAnalyticVar.numDevicesReached : 0;
            let applicationsReached = (localAnalyticVar.assetId) ? localAnalyticVar.numApplicationsReached : 0;
            let presenceReached = (localAnalyticVar.assetId) ? localAnalyticVar.numPresenceReached : 0;
            let externaldevicesReached = (localAnalyticVar.assetId) ? localAnalyticVar.numExternalDevicesReached : 0;
            let externalApplicationsReached = (localAnalyticVar.assetId) ? localAnalyticVar.numExternalApplicationsReached : 0;
            let ViewActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ViewActionRate : 0;
            let ClickActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ClickActionRate : 0;
            let SaveActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.SaveActionRate : 0;
            let RedeemActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.RedeemActionRate : 0;
            let DeleteActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.DeleteActionRate : 0;
            let ShareActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ShareActionRate : 0;
            let SharedViewActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.SharedViewActionRate : 0;
            let ClaimActionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ClaimActionRate : 0;

            let ViewConversionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ViewConversionRate : 0;
            let ClickConversionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ClickConversionRate : 0;
            let SaveConversionRate = (localAnalyticVar.assetId) ? localAnalyticVar.SaveConversionRate : 0;
            //let RedeemActionRate =(localAnalyticVar.campaignId)? localAnalyticVar.RedeemActionRate:0;
            let DeleteConversionRate = (localAnalyticVar.assetId) ? localAnalyticVar.DeleteConversionRate : 0;
            let ShareConversionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ShareConversionRate : 0;
            let SharedViewConversionRate = (localAnalyticVar.assetId) ? localAnalyticVar.SharedViewConversionRate : 0;
            let ClaimConversionRate = (localAnalyticVar.assetId) ? localAnalyticVar.ClaimConversionRate : 0;
            
            
            let ClickThroughRate = (localAnalyticVar.assetId) ? localAnalyticVar.ClickThroughRate : 0;

            let adName = (localAnalyticVar.assetName) ? localAnalyticVar.assetName : "Uknown";

            // User Action Rate
            let localUserActionRate = [adName, ViewActionRate, ClickActionRate, SaveActionRate, RedeemActionRate, DeleteActionRate, ShareActionRate, SharedViewActionRate, ClaimActionRate];
            chartUserRate.push(localUserActionRate);

            let localClickThroughConversion = [adName, ClickThroughRate];
            chartClickThrough.push(localClickThroughConversion);

            let conversationValArr = [ViewConversionRate, ClickConversionRate, SaveConversionRate, DeleteConversionRate, ShareConversionRate, SharedViewConversionRate, ClaimConversionRate]             //[-10,200,100000,-50,5000]; 
            // let conversationValArr = [9, 300, 100780, 1.1, 81180, 0, 7.5]
            let displayConvertedVals = this.getPercentageCategoryVales(1, conversationValArr, true);
            var chartLabels = ['View Conversion Rate', 'Click Conversion Rate', 'Save Conversion Rate', 'Delete Conversion Rate', 'Share Conversion Rate', 'Shared View Conversion Rate', 'Claim Conversion Rate']
            highChartOption = {
              chart: {
                type: 'columnrange',
                inverted: true,
                zoom: "xy"
              },
              title: {
                text: 'Conversion Rate'
              },
              subtitle: {
                text: ''
              },
              xAxis: {
                title: {
                  text: 'User Action'
                },
                type: 'category',
                categories: chartLabels,
                labels: {
                  formatter: function () {
                    return chartLabels[this.pos]
                  }
                }
              },
              yAxis: {
                title: {
                  text: 'values'
                },
                type: 'logarithmic',
                min: 1,
              },
              tooltip: {
                useHTML: true,
                formatter: function () {
                  console.log(this, this.point.low); // just to see , what data you can access
                  let barValue = this.point.high == 100 ? this.point.low : this.point.high
                  return '<b>' + this.x +
                    '</b>: <b>' + barValue + ' %</b>';
                }
              },
              colors: ['#b0120a', '#ffab91', '#ED5534', '#433C39', '#545150', '#B09187', '#B087A0'],
              plotOptions: {
                columnrange: {
                  dataLabels: {
                    // enabled: true,
                    //  format: '{y}°C'
                  },
                  colorByPoint: true
                },
                series: {
                  animation: false
                },
                column: {
                  // colorByPoint: true
                }
              },
              legend: {
                enabled: false
              },
              credits: {
                enabled: false
              },
              series: [{
                name: 'Action Rates',
                data: displayConvertedVals,
              }]
            };

            let localDataOne = [adName, Impressions, postImpressions, Views, Clicks, Saves, Shares, sharedContentViews, Claims, Deletes, Redeems];
            chartDataOne.push(localDataOne);

            let localDataTwo = [adName, applicationsReached, presenceReached, devicesReached, externalApplicationsReached, externaldevicesReached];
            chartDataTwo.push(localDataTwo);

            chartDataTop = [
              ["Campaign", "Advertisement"],
              ["View", ViewActionRate],
              ["Click", ClickActionRate],
              ["Save", SaveActionRate],
              ["Redeem", RedeemActionRate],
              ["Delete", DeleteActionRate],
              ["Share", ShareActionRate],
              ["SharedView", SharedViewActionRate],
              ["Claim", ClaimActionRate]

            ];
            if (!(ViewActionRate + ClickActionRate + SaveActionRate + RedeemActionRate + DeleteActionRate + ShareActionRate + SharedViewActionRate + ClaimActionRate)) {
              chartDataTop = null;
            }
            chartEmpty = [
              ["Campaign", "Advertisement"],
              ["", 1]
            ];
          }
        });
      }
      assetsData = { statics: tempAssetArr, assets: assets };
      this.setState({
        loading: false,
        selectedAssetId: selectedAssetId,
        recyleChartData: chartDataOne,
        reachedChartData: chartDataTwo,
        reachedConversion: chartDataConversion,
        recycleClickThrough: chartClickThrough,
        chartDataTop: chartDataTop,
        chartEmpty: chartEmpty,
        highChartOption: highChartOption,
      });
    } catch (errors) {
      console.log(errors)
    }
  }
  render() {
    const classes = this.props.classes;
    const {
      assetAnalytics,
      selectedAssetId,
      recyleChartData,
      reachedChartData,
      //recycleUserData,
      chartDataTop,
      reachedConversion,
      recycleClickThrough,
      conversationValArr,
      displayConvertedVals,
      percentVal,
      highChartOption
    } = this.state;
    return (
      <div>
        <Card>
          <CardHeader color="success" className={"cusCardTitle"}>
            <h4 className={this.camph}>Asset Analytics {(assetAnalytics && assetAnalytics.length) ? <CSVLink data={assetAnalytics} headers={csvHeaders} className="tblDownloadBtn" filename={"assetsData.csv"}><i className="fa fa-download"></i></CSVLink> : ""}</h4>
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <LinearProgress
                color="primary"
              />
            )}
            {(selectedAssetId && selectedAssetId != "")
              ?
              <GridContainer  >

                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer >
                    <GridItem xs={12} sm={6} md={6} lg={6} >
                      <span className={'text-select chart-title'}>Life Cycle</span>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        className="chart-css"
                        chartType="Bar"
                        loader={<LinearProgress color="primary" />}
                        data={recyleChartData}
                        options={{
                          // Material design options
                          chart: {
                            // title: 'Life Cycle',
                            // subtitle: 'Impressions, Post Impressions, Views, Clicks, Saves, Shares, Shared Content Views, Claims, Deletes, Redeems',
                          },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6} >
                      <span className={'text-select chart-title'}>Outreach</span>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        className="chart-css"
                        chartType="Bar"
                        loader={<LinearProgress color="primary" />}
                        data={reachedChartData}
                        options={{
                          // Material design options
                          chart: {
                            // title: 'Outreach',
                            // subtitle: 'Applications Reached, Presence Reached, Devices Reached, Applications External Reached, Device External Reached',
                          },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer >
                    <GridItem xs={12} sm={6} md={6} >
                      <span className={'text-select chart-title'}>User Action Rate</span>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        className="chart-css"
                        chartType="PieChart"
                        loader={<LinearProgress color="primary" />}
                        data={chartDataTop ? chartDataTop : this.state.chartEmpty}
                        // options={{
                        //   // Material design options
                        //   chart: {
                        //     title: 'User Action Rates',
                        //     // subtitle: 'Impressions, Post Impressions, Views, Clicks, Saves, Shares, Shared Content Views, Claims, Deletes, Redeems',
                        //   },
                        // }}
                        options={{

                          // title: 'User Action Rate',
                          // Just add this option
                          is3D: true,

                        }}
                        //for test
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <span className={'text-select chart-title'}>Click Through Rate</span>
                      <Chart
                        width={'100%'}
                        height={'500px'}
                        className="chart-css"
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={recycleClickThrough}
                        options={{
                          // title: 'Click Through Rate',
                          chartArea: { width: '50%' },
                          colors: ['#ffab91', '#b0120a'],
                          hAxis: {
                            title: 'Click Through Rate',
                            minValue: 0,
                            // maxValue: 100,
                          },
                          vAxis: {
                            title: 'Value',

                          },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '4' }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer >
                    <GridItem xs={12} sm={12} md={12} lg={12} >
                      <span className={'text-select chart-title'}>Conversion Rate Chart</span>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartOption}
                        className="chart-css"
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              : ""}
            <Paper className={(classes.root, this.cust_table_cover)}>
              <div className={(classes.tableWrapper, this.cust_table)}>
                <Table>
                  <TableHead className={this.tableh}>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {(column.id == "assetId") ?
                            <div>
                              {column.label}
                            </div>
                            :
                            <div>
                              <span style={{ whiteSpace: "nowrap" }}>
                                {column.label}
                              </span>
                            </div>
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody key="TableBody">
                    {typeof assetAnalytics === "object" &&
                      assetAnalytics.length
                      ? assetAnalytics
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              onClick={this.filterAssetAnalytics.bind(this, row.assetId)}
                              className={(selectedAssetId == row.assetId) ? classes.TableRow + " selectedAssetAnalytics" : classes.TableRow}
                            >
                              {columns.map(column => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}>
                                    {column.format &&
                                      typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={PER_PAGE_OPTIONS}
                component="div"
                count={
                  typeof assetAnalytics === "object" &&
                    assetAnalytics.length
                    ? assetAnalytics.length
                    : 0
                }
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{
                  "aria-label": "previous page"
                }}
                nextIconButtonProps={{
                  "aria-label": "next page"
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper>
          </CardBody>
        </Card>


      </div>
    );
  }
}
AssetAnalytics.propTypes = {
  classes: PropTypes.object
  // openChangePasswordDialog:
};

export default withStyles(useStyles)(AssetAnalytics);
