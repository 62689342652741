/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  Link as MaterialLink,
  CircularProgress
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { FormErrors } from "./../../components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { userService } from "_services/user.service";

import DatePicker from "react-date-picker";
import PhoneNumber from "awesome-phonenumber";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  genderType,
  EMAIL_REGEX,
  ALLOWED_PROFILE_IMAGE_SIZE,
  ZIPCODE_REGEX,
  ZIP_CODE_LENGTH,
  MIN_AGE_YEARS,
  ALLOWED_IMAGE_EXTENTION,
  DEFAULT_PROFILE_IMG,
  DIGIT_ONLY,
  PHONE_NO_INVALID_LENGTH,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  INVALID_DATA_POST,
  SECURITY_ERROR
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import ChangePassword from "components/FixedPlugin/ChangePassword";
import fileExtension from "file-extension";
import NotificationContainer from "react-notifications/lib/NotificationContainer";

const mat_select = "material-select disabled-dropdown-black";
const cust_label = "custom-label";
const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF"
    }
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  disabledDropdownBlack: {
    color: "#000 !important"
  }
});

class Profile extends React.Component {
  inputOpenFileRef = React.createRef(null);
  maxDate = new Date();
  constructor(props) {
    super(props);

    /* create Ref for file input  */

    this.maxDate.setYear(this.maxDate.getFullYear() - MIN_AGE_YEARS);
    this.dateUtility = new DateFnsUtils();

    this.maxDateForMessage = this.dateUtility.format(
      this.maxDate,
      "MM/dd/yyyy"
    );
    this.filename;
    this.profileImageBlob = null;
    let userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.state = {
      firstName: userDetail ? (userDetail.fName ? userDetail.fName : "") : "",
      mName: userDetail ? (userDetail.mInit ? userDetail.mInit : "") : "",
      lastName: userDetail ? (userDetail.lName ? userDetail.lName : "") : "",
      phone: userDetail ? (userDetail.phone ? userDetail.phone : "") : "",
      zipCode: userDetail ? (userDetail.zipCode ? userDetail.zipCode : "") : "",
      dob: userDetail ? (userDetail.dob ? userDetail.dob : null) : null,
      email: userDetail ? (userDetail.email ? userDetail.email : "") : "",
      gender: userDetail ? (userDetail.gender ? userDetail.gender : "") : "",
      role: userDetail ? (userDetail.role ? userDetail.role : "merchant") : "",
      profileImage: userDetail
        ? userDetail.imageRef
          ? userDetail.imageRef
          : DEFAULT_PROFILE_IMG
        : DEFAULT_PROFILE_IMG,
      //startDate: new Date(),
      formErrors: {
        firstName: "",
        mName: "",
        lastName: "",
        phone: "",
        dob: "",
        zipCode: "",
        email: "",
        gender: "",
        role: "",
        profileImage: ""
      },
      firstNameValid: true,
      mNameValid: true,
      lastNameValid: true,
      phoneValid: true,
      zipCodeValid: true,
      dobValid: true,
      emailValid: false,
      genderValid: true,
      roleValid: true,
      formValid: true,
      profileImageValid: true,
      loading: false,
      showNotification: {},
      isOpen: false,
      openChangePasswordDialog: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchUsrInfo = this.fetchUsrInfo.bind(this);
    this.handleSelectedFile = this.handleSelectedFile.bind(this);
    this.datePickerFocus = this.datePickerFocus.bind(this);
    this.handleZipCodeInput = this.handleZipCodeInput.bind(this);
    this.handleChangePasswordDialog = this.handleChangePasswordDialog.bind(
      this
    );
    this.updateProfileImage = this.updateProfileImage.bind(this);
  }

  onFileBrowse = event => {
    this.inputOpenFileRef.current.click();
  };

  handleChangePasswordDialog = () => {
    const value = this.state.openChangePasswordDialog;
    this.setState({
      openChangePasswordDialog: !value
    });
  };

  datePickerFocus = isOpen => {
    this.setState({ isOpen: true });
  };

  handleSelectedFile = event => {
    event.preventDefault();
    let file = event.target.files[0];
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let profileImageValid = false;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      error = enMsg.allowedProfileImageSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_IMAGE_EXTENTION.includes(file.type)
    ) {
      error = enMsg.allowedProfileImageType;
    } else {
      profileImageValid = true;
    }

    if (typeof file !== undefined && profileImageValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        this.setState({
          profileImage: result
        });
        this.profileImageBlob = file;
        this.filename = new Date().getTime() + "." + fileExtension(file.name);
      });
      if (file) {
        fileReader.readAsDataURL(file);
      }
    }

    fieldValidationErrors.profileImage = profileImageValid ? "" : error;
    this.setState(
      {
        profileImageValid: profileImageValid,
        formErrors: fieldValidationErrors
      },
      this.validateForm
    );
  };

  handlephoneNumber = e => {
    const name = e.target.name;
    let value = e.target.value;
    const phoneNumberValidation = new PhoneNumber(e.target.value).toJSON();

    let formErrors = this.state.formErrors;
    let phoneValid = false;
    let error = "";

    switch (phoneNumberValidation.possibility) {
      case "is-possible":
        error = "";

        if (
          phoneNumberValidation.number.significant &&
          phoneNumberValidation.number.significant.length ==
            PHONE_NO_INVALID_LENGTH
        ) {
          error = enMsg.phoneTooShort;
        }
        break;
      // case "invalid-country-code":
      //   error = enMsg.phoneInvalidCountryCode;
      //   break;
      case "too-long":
        error = enMsg.phoneTooLong;
        break;
      case "too-short":
        error = enMsg.phoneTooShort;
        break;
      case "unknown":
        error = enMsg.phoneUnknown;
        break;
      default:
        error = "";
    }
    formErrors.phone = error;
    this.setState({
      [name]: value,
      formErrors: formErrors,
      phoneValid: phoneNumberValidation.valid
    });
  };

  handleZipCodeInput = e => {
    const value = e.target.value;
    if (value.length > ZIP_CODE_LENGTH) {
      return true;
    }
    if (value.trim() == "" || value.match(DIGIT_ONLY)) {
      this.setState({ zipCode: value }, () => {
        this.validateField("zipCode", value);
      });
    }
  };
  // to stop the warning of calling setState of unmounted component
  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let firstNameValid = this.state.firstNameValid;
    let mNameValid = this.state.mNameValid;
    let lastNameValid = this.state.lastNameValid;
    let zipCodeValid = this.state.zipCodeValid;
    let dobValid = this.state.dobValid;
    let genderValid = this.state.genderValid;
    let emailValid = this.state.emailValid;
    let roleValid = this.state.roleValid;

    switch (fieldName) {
      case "firstName":
        firstNameValid = value.length;
        fieldValidationErrors.firstName = firstNameValid
          ? ""
          : enMsg.firstNameRequired;
        break;

      case "lastName":
        lastNameValid = value.length;
        fieldValidationErrors.lastName = lastNameValid
          ? ""
          : enMsg.lastNameRequired;
        break;
      case "zipCode":
        zipCodeValid = false;
        if (
          value.trim().length &&
          value.match(DIGIT_ONLY) &&
          value.trim().length != ZIP_CODE_LENGTH
        ) {
          fieldValidationErrors.zipCode = enMsg.zipCodeMinLengthError;
        } else if (value.trim().length && !value.match(ZIPCODE_REGEX)) {
          fieldValidationErrors.zipCode = enMsg.invalidZipCode;
        } else {
          zipCodeValid = true;
        }
        break;
      case "mName":
        mNameValid = true;
        if (value.trim().length) {
          mNameValid = value.length >= 3;
          fieldValidationErrors.mName = "Please enter min 3 chars.";
        }
        break;
      // case "dob":
      //   dobValid = value.length >= 3;
      //   fieldValidationErrors.dob = enMsg.invalidDate;
      //   break;
      case "gender":
        // gender is optional
        // genderValid = value.length >= 3;
        // fieldValidationErrors.gender = "Please select gender";
        break;
      case "role":
        roleValid = value.length >= 3;
        fieldValidationErrors.role = enMsg.roleRequired;
        break;
      case "email":
        //emailValid = value.length >= 3;
        emailValid = value.match(EMAIL_REGEX);
        fieldValidationErrors.email = emailValid ? "" : enMsg.inValidEmail;
        break;
      default:
        firstNameValid = true;
        mNameValid = true;
        lastNameValid = true;
        zipCodeValid = true;
        dobValid = true;
        genderValid = true;
        roleValid = true;
        emailValid = true;
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        firstNameValid: firstNameValid,
        mNameValid: mNameValid,
        lastNameValid: lastNameValid,
        zipCodeValid: zipCodeValid,
        dobValid: dobValid,
        genderValid: genderValid,
        roleValid: roleValid,
        emailValid: emailValid
      },
      this.validateForm
    );
  }

  validateForm() {
    return (
      this.state.firstNameValid &&
      this.state.mNameValid &&
      this.state.lastNameValid &&
      this.state.phoneValid &&
      this.state.zipCodeValid &&
      this.state.dobValid &&
      this.state.genderValid &&
      this.state.roleValid &&
      this.state.emailValid
      // && this.state.profileImageValid
    );
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    const req = null;
    const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
  }
  handleChange(event) {
    this.setState({ username: event.target.value });
  }
  handleDateChange = value => {
    const name = "dob";
    let dobValid = true;

    if (typeof value == "object" && this.dateUtility.isValid(value)) {
      value = this.dateUtility.format(value, "MM/dd/yyyy");
    } else if (value != null && !this.dateUtility.isValid(value)) {
      dobValid = false;
    }
    // dobValid: dobValid,
    this.setState({
      [name]: value,
      dobValid: dobValid
    });
  };
  componentDidMount() {
    // reauthorize token request...
    userService.refreshClientAouth();
    this.fetchUsrInfo();
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //    logErrorToMyService(error, info);
  }
  async fetchUsrInfo() {
    const {
      email,
      firstName,
      mName,
      lastName,
      phone,
      zipCode,
      gender,
      role,
      dob,
      imageRef
    } = await userService.fetchUsrInfo();
    this.setState({
      email: email ? email : "",
      firstName: firstName ? firstName : "",
      mName: mName ? mName : "",
      lastName: lastName ? lastName : "",
      phone: phone ? phone : "",
      zipCode: zipCode ? zipCode : "",
      gender: gender ? gender : "",
      role: role ? role : "",
      dob: dob ? dob : null,
      profileImage: imageRef
    });
    if (email) this.validateField("email", email);
    if (firstName) this.validateField("firstName", firstName);
    if (lastName) this.validateField("lastName", lastName);
  }

  async updateProfileImage(event) {
    let showNotification = {};
    let apiSuccess = true;
    if (this.profileImageBlob) {
      try {
        const data = new FormData();
        data.append("file", this.profileImageBlob);
        data.append("filename", this.filename);

        const response = await fetch(apiPath.profileImage, {
          method: "POST",
          headers: authHeaderMIMETYPE(),
          body: data
        })
          .then(response => {
            if (response.status === INVALID_DATA_POST) {
            } else if (response.status === STATUS_CREATED) {
              // const { token } = await response.json();
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.profileUpdated,
                type: "success"
              };
            } else {
              showNotification = {
                title: enMsg.failUpdateTitle,
                message: response.errorMessage
                  ? response.errorMessage
                  : enMsg.networkFailedError,
                type: "danger"
              };
              apiSuccess = false; // set false on error
            }

            return response.json();
          })
          .then(response => {
            if (response.errorCode) {
              showNotification = {
                title: enMsg.failUpdateTitle,
                message: response.errorMessage
                  ? response.errorMessage
                  : enMsg.invalidData400,
                type: "danger"
              };
              apiSuccess = false; // set false on error
            } else if (response.imageRef) {
              const userDetail = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                profileImage: response.imageRef
              };
              userService.updateUserInfo(
                this.state.firstName,
                this.state.lastName,
                this.state.profileImage,
                data
              );
              this.props.updateUserInfo(userDetail);
            }
          })
          .catch(error => {
            showNotification = {
              title: enMsg.failUpdateTitle,
              message: enMsg.networkFailedError,
              type: "danger"
            };
            apiSuccess = false; // set false on error
            return response;
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.failUpdateTitle,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
        notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification)
      //window.location.reload();
    }

    return apiSuccess;
  }

  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();
    // first call updateProfileImage function to upload profile image, if result is success then
    let showNotification = {};
    let result = await this.updateProfileImage();
    if (result) {
      /* use this function for live API */

      /* use this for  fake API */
      let dob = this.state.dob;
      if (dob && this.dateUtility.isValid(dob)) {
        dob = this.dateUtility.format(new Date(dob), "yyyy-MM-dd");
      }

      const data = {
        email: this.state.email,
        fName: this.state.firstName,
        mInit: this.state.mName,
        lName: this.state.lastName,
        phone: this.state.phone,
        zipCode: this.state.zipCode,
        gender: this.state.gender,
        role: this.state.role,
        dob: dob
      };

      try {
        const response = await fetch(`${apiPath.profile}/user`, {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(data)
        })
          .then(response => {
            const contentType = response.headers.get("content-type");
            if (response.status === INVALID_DATA_POST) {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            } else if (response.ok) {
              // const { token } = await response.json();
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.profileUpdated,
                type: "success"
              };
              const userDetail = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                profileImage: this.state.profileImage
              };
              userService.updateUserInfo(
                this.state.firstName,
                this.state.lastName,
                this.state.profileImage,
                data
              );
              this.props.updateUserInfo(userDetail);
            } else {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failUpdateTitle,
                  message: response.errorMessage
                    ? response.errorMessage
                    : enMsg.networkFailedError,
                  type: "danger"
                };
              }
              let error = new Error(response.statusText);
            }
            return response.json();
          })
          .then(response => {
            if(("errorMessage" in response) && response["errorMessage"] != undefined){
              showNotification = {
                title: enMsg.failedTitle,
                message: response.errorMessage,
                type: "warning"
              };
            }else{
              if (response.errorCode) {
                showNotification = {
                  title: enMsg.failUpdateTitle,
                  message: enMsg.invalidData400,
                  message: response.errorMessage
                    ? response.errorMessage
                    : enMsg.invalidData400,
                  type: "danger"
                };
              }
            }
          })
          .catch(error => {
            showNotification = {
              title: enMsg.failUpdateTitle,
              message: enMsg.networkFailedError,
              type: "danger"
            };
            return response;
          });
      } catch (error) {
        showNotification = {
          title: enMsg.failUpdateTitle,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
    }
    userService.showNotification(showNotification)
    this.setState({ loading: false });
  }
  handleChange(valueF, nameF) {
    if (nameF == "gender") {
      this.setState({ gender: valueF });
    } else {
      this.setState({ role: valueF });
    }
    const name = nameF;
    const value = valueF;

    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleOpen() {
    setOpen(true);
  }

  handleClose() {
    setOpen(false);
  }
  render() {
    const { isOpen, role, hasError } = this.state;
    const { classes } = this.props;
    const stylead = { borderBottom: "" };
    return (
      <div>
        <Card>
        {/* <NotificationContainer/> */}
          <CardHeader color="success" className={"cusCardTitle"}>
            <h4 className={classes.cardTitleWhite}>Profile</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify={"center"}>
              <GridItem md={12} xs={12}>
                {/* <div className="green-header">Login</div> */}
                <div className="profile-content">
                  <div className="profile">
                    <form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem md={3} xs={12}>
                          <FormGroup>
                            <CustomInput
                              labelText="First Name"
                              labelProps={{ className: "required-label" }}
                              id="comp-name"
                              inputProps={{
                                type: "text",
                                name: "firstName",
                                autoComplete: "off",
                                // required: true,
                                value: this.state.firstName,
                                inputRef: input => (this.firstName = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.firstNameValid}
                              formErrors={this.state.formErrors}
                              fieldName="firstName"
                            />
                          </FormGroup>

                          <FormGroup>
                            {/* <InputLabel>Password</InputLabel> */}
                            <CustomInput
                              color="primary"
                              labelText="Last Name"
                              labelProps={{ className: "required-label" }}
                              id="city"
                              inputProps={{
                                type: "text",
                                name: "lastName",
                                autoComplete: "off",
                                required: true,
                                value: this.state.lastName,
                                inputRef: input => (this.lastName = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.lastNameValid}
                              formErrors={this.state.formErrors}
                              fieldName="lastName"
                            />
                          </FormGroup>

                          <FormGroup>
                            <CustomInput
                              labelText="Phone"
                              id="phone"
                              inputProps={{
                                type: "text",
                                name: "phone",
                                autoComplete: "off",
                                disabled: true,
                                // required: true,
                                value: this.state.phone,
                                // inputRef: input => (this.state.phone = input),
                                onChange: event => this.handlephoneNumber(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.phoneValid}
                              formErrors={this.state.formErrors}
                              fieldName="phone"
                            />
                          </FormGroup>

                          <FormGroup>
                            <FormControl>
                              <InputLabel htmlFor="gender">Gender</InputLabel>
                              <Select
                                id="gender"
                                color="secondary"
                                name="gender"
                                autoComplete="off"
                                value={this.state.gender}
                                width="100px"
                                onChange={event => this.handleUserInput(event)}
                                input={<Input id="gender" />}
                              >
                                <MenuItem value="">Select Gender</MenuItem>
                                {genderType
                                  ? Object.keys(genderType).map(item => {
                                      return (
                                        <MenuItem key={item} value={item}>
                                          {genderType[item]}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                              <FormErrors
                                show={!this.state.genderValid}
                                formErrors={this.state.formErrors}
                                fieldName="gender"
                              />
                            </FormControl>
                          </FormGroup>

                          <FormGroup>
                            <FormControl className={mat_select}>
                              <InputLabel
                                className="required-label"
                                htmlFor="role"
                              >
                                Role
                              </InputLabel>
                              <Select
                                id="role"
                                color="secondary"
                                name="role"
                                value={role}
                                width="100px"
                                disabled={true}
                                className={classes.disabledDropdownBlack}
                                onChange={event =>
                                  this.handleChange(event.target.value, "role")
                                }
                                input={<Input id="role" />}
                                inputProps={{
                                  disabled: true
                                }}
                              >
                                <MenuItem value="">Role</MenuItem>
                                <MenuItem value="merchant" selected>
                                  Merchant
                                </MenuItem>
                              </Select>
                              <FormErrors
                                show={!this.state.roleValid}
                                formErrors={this.state.formErrors}
                                fieldName="role"
                              />
                            </FormControl>
                          </FormGroup>
                        </GridItem>
                        <GridItem md={3} xs={12}>
                          <FormGroup>
                            <CustomInput
                              labelText="Middle Name"
                              id="middle_name"
                              inputProps={{
                                type: "text",
                                name: "mName",
                                autoComplete: "off",
                                // required: true,
                                value: this.state.mName,
                                inputRef: input => (this.mName = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.mNameValid}
                              formErrors={this.state.formErrors}
                              fieldName="mName"
                            />
                          </FormGroup>

                          <FormGroup className={"profile-email"}>
                            {/* <InputLabel>Password</InputLabel> */}
                            <CustomInput
                              labelText="Email"
                              labelProps={{ className: "required-label" }}
                              id="email"
                              inputProps={{
                                type: "email",
                                name: "email",
                                readOnly: true,
                                autoComplete: "off",
                                required: true,
                                value: this.state.email,
                                inputRef: input => (this.email = input),
                                onChange: event => this.handleUserInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.emailValid}
                              formErrors={this.state.formErrors}
                              fieldName="email"
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              labelText="Zip Code"
                              id="zip_code"
                              inputProps={{
                                type: "text",
                                name: "zipCode",
                                autoComplete: "off",
                                autoComplete: "off",
                                inputProps: { maxLength: ZIP_CODE_LENGTH },
                                // required: true,
                                value: this.state.zipCode,
                                inputRef: input => (this.zipCode = input),
                                onChange: event =>
                                  this.handleZipCodeInput(event)
                              }}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                            <FormErrors
                              show={!this.state.zipCodeValid}
                              formErrors={this.state.formErrors}
                              fieldName="zipCode"
                            />
                          </FormGroup>
                          <FormGroup>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                // variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-dialog"
                                label="DOB"
                                name="dob"
                                value={this.state.dob}
                                maxDate={this.maxDate}
                                InputProps={{ autoComplete: "off" }}
                                maxDateMessage={`Date should not be after ${this.maxDateForMessage}`}
                                onChange={this.handleDateChange}
                                className="KeyboardDatePicker"
                                invalidDateMessage={enMsg.invalidDate}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                  className: "date-picker-span"
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormGroup>
                        </GridItem>

                        <GridItem md={12} xs={12}>
                          <div className="image-ref">
                            <label htmlFor="">Image Ref.</label>
                            <div className="image-ref-img">
                              <img
                                className={"pointer"}
                                onClick={event => this.onFileBrowse(event)}
                                src={this.state.profileImage}
                                alt=""
                              />
                              <span onClick={event => this.onFileBrowse(event)}>
                                <MaterialLink
                                  className="pointer"
                                >
                                  Change
                                </MaterialLink>
                                <input
                                  type="file"
                                  id="file"
                                  ref={this.inputOpenFileRef}
                                  onChange={event =>
                                    this.handleSelectedFile(event)
                                  }
                                  accept="image/x-png, image/jpg, image/jpeg"
                                  style={{ display: "none" }}
                                />
                              </span>
                              <FormErrors
                                show={!this.state.profileImageValid}
                                formErrors={this.state.formErrors}
                                fieldName="profileImage"
                              />
                            </div>
                          </div>
                          <div className="change-pass">
                            <div
                              onClick={this.handleChangePasswordDialog}
                              className="pointer change-pass-text"
                            >
                              change password
                            </div>
                          </div>
                        </GridItem>

                        <GridItem md={3} xs={12} style={{ display: "none" }}>
                          <FormGroup>
                            <CustomInput
                              labelText="Current Password"
                              id="curr_pass"
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </FormGroup>
                        </GridItem>

                        <GridItem md={3} xs={12} style={{ display: "none" }}>
                          <FormGroup>
                            <CustomInput
                              labelText="New Password"
                              id="new_pass"
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </FormGroup>
                        </GridItem>

                        <GridItem md={3} xs={12} style={{ display: "none" }}>
                          <FormGroup>
                            <CustomInput
                              labelText="Confirm Passowrd"
                              id="conf_pass"
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </FormGroup>
                        </GridItem>
                      </GridContainer>
                      <div className="form-button-cover">
                        <Button
                          className={this.state.loading ? "buttonSuccess" : ""}
                          disabled={this.state.loading || !this.validateForm()}
                          type="submit"
                        >
                          Update
                          {this.state.loading && (
                            <CircularProgress
                              size={24}
                              className="buttonProgress"
                            />
                          )}
                        </Button>
                        <Button
                          className="back-btn"
                          color="secondary"
                          type="button"
                        >
                          <Link
                            color="textSecondary"
                            underline="none"
                            className="cancel-back-btn"
                            to={baseRoutes.dashboard.path}
                          >
                            Cancel
                          </Link>
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <ChangePassword
          id="change-pssword-dialog"
          keepMounted
          open={this.state.openChangePasswordDialog}
          handleChangePasswordDialog={this.handleChangePasswordDialog}
          onClose={this.handleClose}
          value={""}
        />
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Profile);
