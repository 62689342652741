export const USER_INFO_REDUX = "USER_INFO_REDUX";
export const STORED_ADNETWORK_REDUX = "STORED_ADNETWORK_REDUX";
export const FETCHED_ADNETWORK_LIST_REDUX = "FETCHED_ADNETWORK_LIST_REDUX";
export const DATA_LOADING_REDUX = "DATA_LOADING_REDUX";
export const FETCH_CATEGORIES_REDUX = "FETCH_CATEGORIES_REDUX";
export const FETCH_BRANDS_REDUX = "FETCH_BRANDS_REDUX";
export const FETCH_AD_STATUS_REDUX = "FETCH_AD_STATUS_REDUX";
export const FETCH_AD_MEDIUM_REDUX = "FETCH_AD_MEDIUM_REDUX";
export const FETCH_AD_TYPE_REDUX = "FETCH_AD_TYPE_REDUX";
export const FETCH_ASSET_TYPE_REDUX = "FETCH_ASSET_TYPE_REDUX";
export const FETCH_ASSET_DISPLAY_TYPE_REDUX = "FETCH_ASSET_DISPLAY_TYPE_REDUX";
export const FETCH_CAMPAIGN_STATUS_REDUX = "FETCH_CAMPAIGN_STATUS_REDUX";
export const STORED_ADVERTISEMENT_REDUX = "STORED_ADVERTISEMENT_REDUX";
export const STORED_CAMPAIGN_REDUX = "STORED_CAMPAIGN_REDUX";
export const STORED_ASSET_REDUX = "STORED_ASSET_REDUX";
export const STORED_VENDOR_REDUX = "STORED_VENDOR_REDUX";
export const STORED_TIMESTAMP = "STORED_TIMESTAMP";
export const REDUX_LOAD = "REDUX_LOAD";

export const ADD_ARTICLE = "ADD_ARTICLE";