import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // styles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress
} from "@material-ui/core";

// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import edit from "assets/img/square-edit.png";
import del from "assets/img/del.png";
import dropdown from "assets/img/dropdown.png";
import clock from "assets/img/clock.png";
import Icon from "@material-ui/core/Icon";
import done from "assets/img/done.svg";
import search from "assets/img/search.svg";

import { NavLink } from "react-router-dom";
import { baseRoutes, basePath } from "base-routes";
import { apiPath } from "api";

import { store } from "react-notifications-component";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  CampaginStatus,
  STORED_ADNETWORK,
  ADNETWORK_ID_SLUG,
  PER_PAGE_OPTIONS,
  RECORD_PER_PAGE
} from "__helpers/constants";
import { userService } from "_services/user.service";
import { NotificationContainer } from "react-notifications";
import AdnetworkSelect from "components/Sidebar/AdnetworkSelect";

const columns = [
  { id: "companyName", label: "Company Name" },
  { id: "address", label: "Address" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "dateCreated", label: "Creation Date" },
  // {
  //   id: "description",
  //   label: "Description",
  //   align: "left",
  //   format: value => value.toLocaleString()
  // },
  // {
  //   id: "bus_name",
  //   label: "Business Name",
  //   align: "left",
  //   format: value => value.toFixed(2)
  // },
  // {
  //   id: "Actions",
  //   label: "Actions",
  //   align: "right",
  //   format: value => value.toFixed(2)
  // }
];

const useStyles = {
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto"
  }
};

// function deleteAdNetwork(event) {
// }
class AdNetworkList extends React.Component {
  cust_table_cover = "custom-table-cover";
  camph = "campaign-header";
  tableh = "table-header";
  cust_table = "custom-table";
  camp_btn_cover = "campaign-btn-cover";
  camp_btn = "campaign-btn";
  icon_cover = "icon-cover";

  constructor(props) {
    super(props);
    let stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));    

    this._isMounted = false;
    this.adNetworkSelectBox = React.createRef();
    this.selectedAdNetworkJsonID = null;
    this.selectedadNetworkID = null;
    this.notificationID = null;
    this.selectedCampaignStatus = null;

    this.state = {
      page: 0,
      selectedCampaignID: null,
      adNetworkJson: stroedAdNetwork,
      originalFetchData: [],
      rowsPerPage: RECORD_PER_PAGE,
      openMenu: null,
      openAlertBox: false,
      openAlertBoxDelete: false,
      loading: false
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.fetchAdNetwork = this.fetchAdNetwork.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    // reauthorize token request...
    // userService.refreshClientAouth();
    this.fetchAdNetwork();
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    this._isMounted = false;
  }
  async fetchAdNetwork() {
    let adNetwork = await userService.fetchAdNetwork();
    this._isMounted &&
      this.setState({
        originalFetchData: adNetwork,
        adNetworkJson: adNetwork
      });
  }
  handleChangePage = async (event, newPage) => {
    this.setState({
      page: newPage
    });
    // setPage(newPage);
  };

  searchAdNetwork = e => {
    let allAdNetwork = this.state.originalFetchData;
    const searchText = e.target.value.trim();

    if (searchText) {
      allAdNetwork = allAdNetwork.filter((v, i) => {
        if (v.companyName) {
          return (
            v.companyName.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1
          );
        }
      });
    }
    this.setState({
      adNetworkJson: allAdNetwork
    });
  };

  // componentDidCatch(error, info) {
  //   // Display fallback UI
  //   this.setState({ hasError: true });
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, info);
  // }

  deleteAdNetwork = async () => {
    this.setState({ loading: true });

    let apiPathString = apiPath.manageAdnetwork;

    apiPathString = apiPathString.replace(
      ADNETWORK_ID_SLUG,
      this.state.selectedadNetworkID
    );

    // if (window.confirm("Are you sure want to delete this campaign?"))
    {
      let showNotification = {};
      try {
        const response = await fetch(apiPathString, {
          method: "DELETE",
          headers: authHeader()
          // body: JSON.stringify({
          //   adNetworkId: this.state.selectedadNetworkID
          // })
        })
          .then(response => {
            if (response.status === 400) {
              showNotification = {
                title: enMsg.fetchCampaignFailed,
                message: response.statusText,
                type: "danger"
              };
            } else if (response.ok) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.campaginDeleted,
                type: "success"
              };
            }
            return response;
          })
          .then(data => {
            data = this.state.adNetworkJson.filter((v, i) => {
              return !(v.adNetworkId == this.state.selectedadNetworkID);
            });
            this._isMounted &&
              this.setState({
                originalFetchData: data,
                selectedadNetworkID: null
              });
          })
          .catch(error => {
            const data = this.state.adNetworkJson.filter((v, i) => {
              return !(v.adNetworkId == this.state.selectedadNetworkID);
            });
            this._isMounted && this.setState({ originalFetchData: data });
            showNotification = {
              title: enMsg.connectionFailed,
              message: enMsg.networkFailedError,
              type: "danger"
            };
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
      if (
        showNotification !== undefined &&
        showNotification.title !== undefined &&
        showNotification.message !== undefined &&
        showNotification.type !== undefined
      ) {
        this.notificationID = store.removeNotification(this.notificationID);
        if (this.notificationID == undefined) {
          let notifiaciton = {
            title: showNotification.title,
            message: showNotification.message,
            type: showNotification.type
          };
          // notifiaciton = Object.assign(NotificationOptions, notifiaciton);
          // this.notificationID = store.addNotification(notifiaciton);
        }
        userService.showNotification(showNotification)
        //window.location.reload();
      }
    }
    this._isMounted &&
      this.setState({
        loading: false,
        openAlertBoxDelete: false
      });
  };

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    });
  }

  render() {
    var deleteIcon = (
      <Icon>
        <span color="secondary">
          <img src={del} alt="" />
        </span>
      </Icon>
    );

    const editIcon = (
      <Icon>
        <span color="primary">
          <img src={edit} alt="" />
        </span>
      </Icon>
    );

    const dropDownIcon = (
      <Icon
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => this.handleClickOpen(event)}
      >
        <img src={dropdown} className="campaign-status-img" alt="" />
      </Icon>
    );
    const classes = this.props.classes;
    return (
      <div>
        {/* <NotificationContainer/> */}
        <Menu
          id="simple-menu"
          anchorEl={this.state.openMenu}
          keepMounted
          open={Boolean(this.state.openMenu)}
          className={"status-dropdown-menu"}
        >
          {Object.keys(CampaginStatus).map(status => {
            return (
              <MenuItem
                onClick={event =>
                  this.state.selectedCampaignStatus !== status
                    ? this.setState({
                        selectedCampaignStatus: status,
                        openAlertBox: true
                      })
                    : this.setState({
                        openAlertBox: false
                      })
                }
                key={status}
                value={status}
                className={
                  this.state.selectedCampaignStatus !== status
                    ? "capmpaign-items"
                    : ""
                }
              >
                {this.state.selectedCampaignStatus === status ? (
                  <img src={done} alt="" className="pop-check" />
                ) : (
                  ""
                )}
                {CampaginStatus[status]}
              </MenuItem>
            );
          })}
        </Menu>

        {/* Alert box dialog for Change campaign status  */}
        <Dialog
          open={this.state.openAlertBox}
          onClose={event => {
            this.setState({
              selectedadNetworkID: null,
              openMenu: null
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Caution!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {enMsg.alertForChangeStatus}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="form-button-cover">
              <Button
                className={this.state.loading ? "buttonSuccess" : ""}
                disabled={this.state.loading}
                type="button"
                onClick={this.changeCampaignStatus}
              >
                Confirm
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button
                className="back-btn"
                color="secondary"
                type="button"
                onClick={event => {
                  this.setState({
                    openAlertBox: false,
                    openMenu: null
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        {/* Alert box dialog for Delete campaign */}
        <Dialog
          open={this.state.openAlertBoxDelete}
          onClose={event => {
            this.setState({
              selectedadNetworkID: null,
              openMenu: null
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Caution!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {enMsg.alertForDeleteAdNetwork}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="form-button-cover">
              <Button
                className={this.state.loading ? "buttonSuccess" : ""}
                disabled={this.state.loading}
                type="button"
                onClick={event => this.deleteAdNetwork()}
              >
                Confirm
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button
                className="back-btn"
                color="secondary"
                type="button"
                onClick={event => {
                  this.setState({
                    openAlertBoxDelete: false
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" className={"cusCardTitle"}>
                <h4 className={this.camph}>Ad Networks</h4>
              </CardHeader>
              <GridItem xs={12} sm={12} md={12}>
                <div className="search-btn-cover">
                  <input
                    type="text"
                    placeholder="Company Name"
                    className="search-btn"
                    onChange={event => this.searchAdNetwork(event)}
                  />
                  <i className="material-icons">search</i>
                </div>
                <div className="button-cover">
                  <NavLink
                    to={`${basePath}${baseRoutes.addAdNetwork.path}`}
                    // to={`${this.state.addAdvertisementLink}`}
                    color="textSecondary"
                    className="add-campaign-btn"
                    underline="none"
                  >
                    <button className={this.camp_btn}>new Ad Network</button>
                  </NavLink>
                </div>
              </GridItem>
              <CardBody>
                <Paper className={(classes.root, this.cust_table_cover)}>
                  <div className={(classes.tableWrapper, this.cust_table)}>
                    <Table>
                      <TableHead className={this.tableh}>
                        <TableRow>
                          {columns.map(column => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody key="TableBody">
                        {typeof this.state.adNetworkJson === "object" &&
                        this.state.adNetworkJson.length
                          ? this.state.adNetworkJson
                              .slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                  this.state.rowsPerPage
                              )
                              .map(row => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.adNetworkId}
                                  >
                                    {columns.map(column => {
                                      const value = row[column.id];
                                      if (column.id == "dateCreated") {
                                        let newDate = new Date(value).toUTCString(); 
                                        return (
                                          <TableCell key={column.id}>
                                            {newDate}
                                          </TableCell>
                                        );
                                      }
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {column.format &&
                                          typeof value === "number"
                                            ? column.format(value)
                                            : (value) ? value : "unknown"}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })
                          : null}
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTIONS}
                    component="div"
                    count={
                      typeof this.state.adNetworkJson === "object" &&
                      this.state.adNetworkJson.length
                        ? this.state.adNetworkJson.length
                        : 0
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page"
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Paper>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AdNetworkList.propTypes = {
  classes: PropTypes.object
  // openChangePasswordDialog:
};

export default withStyles(useStyles)(AdNetworkList);
