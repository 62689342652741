/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { FormErrors } from "./../../components/Login/FormErrors";
import { apiPath } from "api";
import { store } from "react-notifications-component";
import vendorImg from "assets/img/vendor.png";
import { userService } from "_services/user.service";
import DateFnsUtils from "@date-io/date-fns";
import upload from "assets/img/upload.png";
import assetsThumbnail from "assets/img/sample_assets.png";
import Dropzone from "react-dropzone";


import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  assetsTypesValues,
  displayTypesValues,
  ALLOWED_IMAGE_EXTENTION,
  URL_PATTERN,
  DIGIT_ONLY,
  IMAGE_MAX_HEIGHT_WIDTH_LENTH,
  ALLOWED_PROFILE_IMAGE_SIZE,
  INVALID_DATA_POST,
  FORBIDDEN_STATUS,
  OK_SUCCESS_STATUS,
  STATUS_CREATED,
  ADNETWORK_ID_SLUG,
  STORED_ADNETWORK,
  ASSET_ID_SLUG
} from "__helpers/constants";
import { authHeaderMIMETYPE } from "__helpers/auth-header";
import baseRoutes from "base-routes";
import { NotificationContainer } from "react-notifications";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import thumbnail from "assets/img/assets-thumbnail.png";
import GetAppIcon from "@material-ui/icons/GetApp";
import Downloader from "js-file-downloader";
import { FETCH_ASSET_DISPLAY_TYPE } from "__helpers/constants";
import { FETCH_ASSET_TYPE } from "__helpers/constants";
import { connect } from "react-redux";
import {
  addAssets,
  reduxLoad,
} from "../../js/actions/index";

function mapDispatchToProps(dispatch) {
  return {
    addAssets: addAssetsVal => dispatch(addAssets(addAssetsVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const mat_select = ""; //"material-select disabled-dropdown-black"
const cust_label = "custom-label";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFF"
    }
  },
  cardTitleWhite: {
    color: "#FFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  disabledDropdownBlack: {
    color: "#000 !important"
  }
};
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

class AssetsClass extends React.Component {
  inputOpenFileRef = React.createRef(null);
  maxDate = new Date();

  constructor(props) {
    super(props);
    const { assetID, adnetworkID } = this.props.match.params;
    let selectedAdnetwork = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    selectedAdnetwork = (selectedAdnetwork) ? selectedAdnetwork.adNetworkId : "";
    let stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    if (!stroedAdNetwork) {
      stroedAdNetwork = [];
    }
    this.dateUtility = new DateFnsUtils();
    this.formRef = React.createRef();
    this.assetsFileBlob = null;
    this._isMounted = false;

    let assetDisplayType = JSON.parse(localStorage.getItem(FETCH_ASSET_DISPLAY_TYPE));
    let assetType = JSON.parse(localStorage.getItem(FETCH_ASSET_TYPE));
    this.state = {
      assetId: assetID ? assetID : "",
      isEditing: assetID ? true : false,
      adNetworkId: adnetworkID ? adnetworkID : (selectedAdnetwork) ? selectedAdnetwork : "",
      apiPath: assetID ? apiPath.manageAssets : apiPath.assets,
      assetTypeList: (assetType) ? assetType : [],
      assetTypeDisplayList: (assetDisplayType) ? assetDisplayType : [],
      name: "",
      filename: "",
      description: "",
      assetUrl: "",
      assetType: "",
      displayType: "",
      width: "",
      height: "",
      files: [],
      uploadFileData: "",
      formErrors: {
        adNetworkId: "",
        name: "",
        filename: "",
        description: "",
        assetUrl: "",
        assetType: "",
        displayType: "",
        width: "",
        height: "",
        uploadingAssets: ""
      },
      adNetworkIdValid: true,
      nameValid: false,
      filenameValid: false,
      descriptionValid: false,
      assetUrlValid: false,
      assetTypeValid: false,
      displayTypeValid: false,
      // uploadingAssetsValid: false,
      uploadingAssetsValid : assetID ? true : false,
      widthValid: true,
      heightValid: true,
      formValid: true,
      adNetWorkJson: stroedAdNetwork,
      loading: false,
      imagePreview: "",
      showNotification: {},
      reduxLoadFlag: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectedFile = this.handleSelectedFile.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.fetchAdNetwork = this.fetchAdNetwork.bind(this);
    this.handleDigits = this.handleDigits.bind(this);
    this.fetchAssetDetail = this.fetchAssetDetail.bind(this);
    this.fetchData = this.fetchData.bind(this);
    // this.onPreviewDrop = this.onPreviewDrop.bind(this);
  }
  componentDidUpdate(){
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = this.state.adNetworkId;
      let adnetworkData = [];
      let assetTypeData = [];
      let assetDisplayTypeData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      if (this.props.assetType) {
        let jsonData = this.props.assetType;
        assetTypeData = (jsonData) ? jsonData : [];
      }
      if (this.props.assetDisplayType) {
        let jsonData = this.props.assetDisplayType;
        assetDisplayTypeData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        adNetworkId: (selectedAdnetwork) ? selectedAdnetwork : "",
        assetTypeList: (assetTypeData) ? assetTypeData : [],
        assetTypeDisplayList: (assetDisplayTypeData) ? assetDisplayTypeData : [],
      })
    }
  }
  onPreviewDrop = files => {
    this.setState({
      files: this.state.files.concat(files)
    });
  };
  async fetchAdNetwork() {
    let data = await userService.fetchAdNetwork();
    this._isMounted && this.setState({ adNetWorkJson: data });
  }
  async fetchAssetDetail() {
    let data = await userService.fetchAssetDetail(
      this.state.assetId,
      this.state.adNetworkId
    );
    if (data) {
      this.validateField("adNetworkId", this.state.adNetworkId);

      var name = "name" in data ? data.name : "";
      this.validateField("name", name);
      var filename = "filename" in data ? data.filename : "";
      this.validateField("filename", filename);
      var description = "description" in data ? data.description : "";
      this.validateField("description", description);
      var assetUrl = "assetUrl" in data ? data.assetUrl : "";
      this.validateField("assetUrl", assetUrl);
      var assetType = "assetType" in data ? data.assetType : "";
      this.validateField("assetType", assetType);
      var displayType = "displayType" in data ? data.displayType : "";
      this.validateField("displayType", displayType);
      var width = "width" in data ? data.width : "";
      this.validateField("width", width);
      var height = "height" in data ? data.height : "";
      this.validateField("height", height);
      this.setState({
        adNetworkId: this.state.adNetworkId,
        name: name,
        filename: filename,
        description: description,
        assetUrl: assetUrl,
        assetType: assetType,
        displayType: displayType,
        width: width,
        height: height,
        uploadFileData: assetUrl,
      });
    }
  }
  resetForm = () => {
    const node = this.formRef.current;
    node.reset();
    const labelNodes = node.querySelectorAll(
      ".MuiInputLabel-shrink.MuiFormLabel-filled"
    );
    this.setState({
      name: "",
      filename: "",
      description: "",
      assetUrl: "",
      assetType: "",
      displayType: "",
      width: "",
      height: "",
      formValid: false,
      nameValid: false,
      filenameValid: false,
      descriptionValid: false,
      assetUrlValid: false,
      assetTypeValid: false,
      displayTypeValid: false,
      uploadingAssetsValid: false,
      uploadingAssets: "",
      files: []
    });

    if (typeof labelNodes == "object") {
      labelNodes.forEach(function(currentValue, currentIndex, listObj) {
        return currentValue.classList.remove(
          "MuiInputLabel-shrink",
          "MuiFormLabel-filled"
        );
      });
    }
  };

  handleDigits = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (value && value.length > IMAGE_MAX_HEIGHT_WIDTH_LENTH) {
      return true;
    }

    if (value.trim() == "" || value.match(DIGIT_ONLY)) {
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    }
  };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  onFileBrowse = event => {
    this.inputOpenFileRef.current.click();
  };

  handleSelectedFile = selectedFiles => {
    let commonImageFile = "";
    let file = selectedFiles[0];
    if(this.state.assetType == "text"){
      commonImageFile = upload;
    }else if(this.state.assetType == "audio"){
      commonImageFile = upload;
    }else{
      commonImageFile = file;
    }
    let fieldValidationErrors = this.state.formErrors;
    let error = "";
    let uploadingAssetsValid = this.state.uploadingAssetsValid;
    if (typeof file == "object" && file.size > ALLOWED_PROFILE_IMAGE_SIZE) {
      uploadingAssetsValid = false;
      error = enMsg.allowedProfileImageSize;
    } else if (
      typeof file == "object" &&
      !ALLOWED_IMAGE_EXTENTION.includes(file.type)
    ) {
      uploadingAssetsValid = false;
      error = enMsg.allowedProfileImageType;
    } else {
      uploadingAssetsValid = true;
    }
    this.setState({
      uploadFileData: file.name
    })
    if (uploadingAssetsValid) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        let result = fileReader.result;
        var img = new Image();

        img.onload = () => {
          this.setState({
            height: img.height,
            width: img.width
          });
        };

        img.src = fileReader.result;
        this.setState({
          files: selectedFiles,
          imagePreview: result
        });
        var data = result.split(",")[1];
        var binaryBlob = atob(data);
        this.state.filename = file.name;
        this.assetsFileBlob = file; //result;
      });
      if (file) {
        if(file.type.includes('video')){
          fileReader.readAsDataURL(file);
        }else{
          fileReader.readAsDataURL(file);
        }
      }
    }

    fieldValidationErrors.uploadingAssets = uploadingAssetsValid ? "" : error;
    this.setState(
      {
        uploadingAssetsValid: uploadingAssetsValid,
        formErrors: fieldValidationErrors
      },
      this.validateForm
    );
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let nameValid = this.state.nameValid;
    let adNetworkIdValid = this.state.adNetworkIdValid;
    let descriptionValid = this.state.descriptionValid;
    let displayTypeValid = this.state.displayTypeValid;
    let assetTypeValid = this.state.assetTypeValid;

    let assetUrlValid = this.state.assetUrlValid;
    let filenameValid = this.state.filenameValid;
    let widthValid = this.state.widthValid;
    let heightValid = this.state.heightValid;

    switch (fieldName) {
      case "adNetworkId":
        adNetworkIdValid = value ? true : false;
        fieldValidationErrors.adNetworkId = !adNetworkIdValid
          ? enMsg.adNetworkIdRequiredAssets
          : "";
        break;
      case "filename":
        filenameValid = value.trim().length ? true : false;
        fieldValidationErrors.filename = !filenameValid
          ? enMsg.fileNameRequiredAssets
          : "";
        break;

      case "name":
        nameValid = value.trim().length ? true : false;
        fieldValidationErrors.name = !nameValid ? enMsg.nameRequiredAssets : "";
        break;

      case "description":
        descriptionValid = value.trim().length ? true : false;
        fieldValidationErrors.description = !descriptionValid
          ? enMsg.descriptionRequiredAssets
          : "";
        break;
      case "assetUrl":
        assetUrlValid = false;
        let errorMessage = "";
        if (!value.trim().length) {
          errorMessage = enMsg.assetUrlRequiredAssets;
        } else if (value.trim().length && !value.match(URL_PATTERN)) {
          errorMessage = enMsg.invalidassetUrlAssets;
        } else {
          assetUrlValid = true;
        }
        fieldValidationErrors.assetUrl = errorMessage;
        break;
      case "assetType":
        assetTypeValid = value ? true : false;
        fieldValidationErrors.assetType = !assetTypeValid
          ? enMsg.assetTypeRequiredAssets
          : "";
        break;
      case "displayType":
        displayTypeValid = value ? true : false;
        fieldValidationErrors.displayType = !displayTypeValid
          ? enMsg.displayTypeRequiredAssets
          : "";
        break;
    }
    const formValid =
      nameValid && descriptionValid && assetTypeValid && displayTypeValid;

    this.setState(
      {
        formErrors: fieldValidationErrors,
        formValid: formValid,
        adNetworkIdValid: adNetworkIdValid,
        nameValid: nameValid,
        filenameValid: filenameValid,
        descriptionValid: descriptionValid,
        assetUrlValid: assetUrlValid,
        assetTypeValid: assetTypeValid,
        displayTypeValid: displayTypeValid,
        widthValid: widthValid,
        heightValid: heightValid
      },
      this.validateForm
    );
  }

  validateForm() {
    return (
      this.state.formValid &&
      this.state.adNetworkIdValid &&
      this.state.nameValid &&
      this.state.uploadingAssetsValid &&
      this.state.descriptionValid &&
      this.state.assetTypeValid &&
      this.state.displayTypeValid
    );
  }

  handleDateChange = event => {
    const name = "dob";
    let value = event;
    let dobValid = true;

    if (typeof event == "object" && this.dateUtility.isValid(event)) {
      value = this.dateUtility.format(event, "MM/dd/yyyy");
    } else if (event != null && !this.dateUtility.isValid(event)) {
      dobValid = false;
    }
    // dobValid: dobValid,
    this.setState({
      [name]: value,
      dobValid: dobValid
    });
  };
  componentDidMount() {
    userService.refreshClientAouth();
    this._isMounted = true;
    this.fetchAdNetwork();
    if (this.state.assetId) {
      this.fetchAssetDetail();
    }
    if(!this.state.adNetworkId){
      let showNotification = {
        title: enMsg.selectAdnetworkTitle,
        message: enMsg.selectAdnetwork,
        type: "quickInfoAlert"
      };
      userService.showNotification(showNotification)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }
  async fetchData(adnetworkId) {
    let assets = await userService.fetchAllAssets(adnetworkId);
    this.props.addAssets(assets);
    setTimeout(function(){
      this.props.reduxLoad(true);
    }.bind(this),500);
  }
  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();

    /* use this function for live API */
    let data = new FormData();
    let message = "Create";
    let sendMethod = "POST";
    let apiPath = String(this.state.apiPath);

    // data.append("adNetworkId", this.state.adNetworkId);
    data.append("filename", this.state.filename);
    // data.append("name", this.state.name);
    data.append("name", this.state.name);
    data.append("description", this.state.description);
    // data.append("assetUrl", this.state.assetUrl);
    data.append("assetType", this.state.assetType);
    data.append("displayType", this.state.displayType);
    data.append("width", this.state.width);
    data.append("height", this.state.height);
    if (this.state.assetId) {
      // it will be use with Live API , do not remove this code
      apiPath = apiPath.replace(ADNETWORK_ID_SLUG, this.state.adNetworkId);
      apiPath = apiPath.replace(ASSET_ID_SLUG, this.state.assetId);

      // data.append("adNetworkId", this.state.adNetworkId);
      data.append("assetId", this.state.assetId);

      sendMethod = "PUT";
      message = "Update";

      if(this.assetsFileBlob){
        data.append("file", this.assetsFileBlob);
      }

    } else {
      data.append("file", this.assetsFileBlob); // assetFile
      apiPath = String(this.state.apiPath);
      apiPath = apiPath.replace(ADNETWORK_ID_SLUG, this.state.adNetworkId);
      apiPath = apiPath.replace(ASSET_ID_SLUG, "");
    };
    /* use this for  fake API */

    let showNotification = {};
    try {
      const response = await fetch(apiPath, {
        method: sendMethod,
        headers: authHeaderMIMETYPE(),
        body: data // JSON.stringify(data)
      })
        .then(response => {
          const contentType = response.headers.get("content-type");
          if ([FORBIDDEN_STATUS, INVALID_DATA_POST].includes(response.status)) {
            if(contentType && contentType.indexOf("application/json") !== -1){
              return response.json();
            }else{
              showNotification = {
                title: enMsg.failedTitle,
                message: enMsg.invalidData400,
                type: "danger"
              };
            }
          } else if (response.ok) {
            // const { token } = await response.json();
            this.fetchData(this.state.adNetworkId);
            if (response.status === OK_SUCCESS_STATUS) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.assetsUpdated,
                type: "success"
              };
            } else if (response.status === STATUS_CREATED) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.assetsCreated,
                type: "success"
              };
              this.resetForm();
            }
            this.setState({
              uploadFileData: ""
            });
            setTimeout(function() {
              window.history.back();
            }, 2000);
            return response.json();
          }
          return response.json();
        })
        .then(data => {
          if(("errorMessage" in data) && data["errorMessage"] != undefined){
            showNotification = {
              title: enMsg.failedTitle,
              message: data.errorMessage,
              type: "warning"
            };
          }
        })
        .catch(error => {
          showNotification = {
            title: enMsg.failedTitle,
            message: enMsg.networkFailedError,
            type: "danger"
          };
          return response;
        });

      // throw new Error(error);
    } catch (error) {
      showNotification = {
        title: enMsg.failedTitle,
        message: enMsg.networkFailedError,
        type: "danger"
      };
    }
    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      this.notificationID = store.removeNotification(this.notificationID);
      if (this.notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
        notifiaciton = Object.assign(NotificationOptions, notifiaciton);
        this.notificationID = store.addNotification(notifiaciton);
      }
      userService.showNotification(showNotification)
    }
    this.setState({ loading: false });
  }

  handleChange(value, name) {
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleOpen() {
    setOpen(true);
  }

  handleClose() {
    setOpen(false);
  }
  checkURL(url) {
    return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
  downloadMedia(url, type) {
    new Downloader({
      url: url
    })
      .then(function() {
        // Called when download ended
      })
      .catch(function(error) {
        // Called when an error occurred
      });
  }
  render() {
    const {
      adNetworkId,
      filename,
      description,
      assetUrl,
      assetType,
      displayType,
      width,
      height,
      assetTypeDisplayList,
      assetTypeList
    } = this.state;
    const { classes } = this.props;
    const stylead = { borderBottom: "" };

    return (
      <Card>
        {/* <NotificationContainer/> */}
        <CardHeader color="success" className={"cusCardTitle"}>
    <h4 className={classes.cardTitleWhite}>{this.state.isEditing ? "Update " : "Create "}Assets</h4>
        </CardHeader>
        <CardBody>
          <GridContainer justify={"center"}>
            <GridItem md={12} xs={12}>
              {/* <div className="green-header">Login</div> */}
              <div className="profile-content">
                <div className="profile">
                  <form
                    ref={this.formRef}
                    id="example-form"
                    noValidate
                    onSubmit={this.handleSubmit}
                    encType="multipart/form-data"
                  >
                    <GridContainer>
                      <GridItem md={3} xs={12}>
                        <FormGroup style={{display:"none"}}>
                          <FormControl className={mat_select}>
                            <InputLabel
                              className="required-label"
                              htmlFor="adnetwork"
                            >
                              AdNetwork
                            </InputLabel>
                            <Select
                              id="adNetworkId"
                              color="secondary"
                              name="adNetworkId"
                              value={adNetworkId}
                              className="select-dropdown-network-data"
                              width="100px"
                              disabled = {this.state.isEditing ? true : false}
                              disabled = {true}
                              onChange={this.handleUserInput}
                            >
                              <MenuItem value="">Select AdNetwork</MenuItem>
                              {this.state.adNetWorkJson
                                ? this.state.adNetWorkJson.map(
                                    adNetWorkData => {
                                      return (
                                        <MenuItem
                                          key={adNetWorkData.adNetworkId}
                                          value={adNetWorkData.adNetworkId}
                                          selected={
                                            adNetWorkData.adNetworkId ==
                                            this.state.selectedAd
                                          }
                                        >
                                          {adNetWorkData.companyName}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                : null}
                            </Select>
                            <FormErrors
                              show={!this.state.adNetworkIdValid}
                              formErrors={this.state.formErrors}
                              fieldName="adNetworkId"
                            />
                          </FormControl>
                        </FormGroup>
                        <FormGroup className="cusForm">
                          <CustomInput
                            labelText="Name"
                            labelProps={{
                              className: "required-label"
                            }}
                            id="name"
                            inputProps={{
                              type: "text",
                              name: "name",
                              autoComplete: "off",
                              required: true,
                              value: this.state.name,
                              
                              onChange: event => this.handleUserInput(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.nameValid}
                            formErrors={this.state.formErrors}
                            fieldName="name"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormControl className={mat_select}>
                            <InputLabel
                              className={"required-label"}
                              htmlFor="assetType"
                            >
                              Assets Type
                            </InputLabel>
                            <Select
                              id="assetType"
                              color="secondary"
                              name="assetType"
                              value={assetType}
                              width="100px"
                              
                              onChange={event =>
                                this.handleChange(
                                  event.target.value,
                                  "assetType"
                                )
                              }
                              input={<Input id="assetTypeInput" />}
                            >
                              <MenuItem value="">Select Assets Type</MenuItem>
                              {assetTypeList
                                  ? Object.keys(assetTypeList).map(key => {
                                    return (
                                      <Tooltip
                                       className={"custom-tooltip"}
                                       title={<span className="customTooltipClass">{assetTypeList[key]['description']}</span>} key={assetTypeList[key]['name']} 
                                       value={assetTypeList[key]['name']}
                                       >
                                        <MenuItem key={assetTypeList[key]['name']} value={assetTypeList[key]['name']}>
                                            {assetTypeList[key]['name']}
                                        </MenuItem>
                                      </Tooltip>
                                    );
                                  })
                                  : null}
                            </Select>
                            <FormErrors
                              show={!this.state.assetTypeValid}
                              formErrors={this.state.formErrors}
                              fieldName="assetType"
                            />
                          </FormControl>
                        </FormGroup>
                      </GridItem>
                      <GridItem md={3} xs={12}>
                      <FormGroup className="cusForm">
                          <CustomInput
                            color="primary"
                            labelText="Description"
                            labelProps={{
                              className: "required-label"
                            }}
                            id="description"
                            inputProps={{
                              type: "text",
                              name: "description",
                              autoComplete: "off",
                              required: true,
                              value: this.state.description,
                              inputRef: input => (this.description = input),
                              onChange: event => this.handleUserInput(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.descriptionValid}
                            formErrors={this.state.formErrors}
                            fieldName="description"
                          />
                        </FormGroup>
                        
                        {/* <FormGroup className="cusForm">
                          <CustomInput
                            labelText="Asset Url"
                            labelProps={{
                              className: "required-label"
                            }}
                            id="assetUrl"
                            inputProps={{
                              type: "text",
                              name: "assetUrl",
                              autoComplete: "off",
                              required: true,
                              value: this.state.assetUrl,
                              // inputRef: input => (this.assetUrl = input),
                              onChange: event => this.handleUserInput(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.assetUrlValid}
                            formErrors={this.state.formErrors}
                            fieldName="assetUrl"
                          />
                        </FormGroup> */}
                        <FormGroup>
                          <FormControl className={mat_select}>
                            <InputLabel
                              className={"required-label"}
                              htmlFor="displayType"
                            >
                              Display Type
                            </InputLabel>
                            <Select
                              id="displayType"
                              color="secondary"
                              name="displayType"
                              value={displayType}
                              width="100px"
                              onChange={event =>
                                this.handleChange(
                                  event.target.value,
                                  "displayType"
                                )
                              }
                              input={<Input id="displayType" />}
                            >
                              <MenuItem value="">Select Display Type</MenuItem>
                              {assetTypeDisplayList
                                  ? Object.keys(assetTypeDisplayList).map(key => {
                                    return (
                                      <Tooltip
                                       title={<span className="customTooltipClass">{assetTypeDisplayList[key]['description']}</span>} key={assetTypeDisplayList[key]['name']} 
                                       value={assetTypeDisplayList[key]['name']}
                                       >
                                        <MenuItem key={assetTypeDisplayList[key]['name']} value={assetTypeDisplayList[key]['name']}>
                                            {assetTypeDisplayList[key]['name']}
                                        </MenuItem>
                                      </Tooltip>
                                    );
                                  })
                                  : null}
                            </Select>
                            <FormErrors
                              show={!this.state.displayTypeValid}
                              formErrors={this.state.formErrors}
                              fieldName="displayType"
                            />
                          </FormControl>
                        </FormGroup>
                        {/* <FormGroup className="cusForm">
                          <CustomInput
                            color="primary"
                            labelText="Height"
                            labelProps={
                              {
                                // className: "required-label"
                              }
                            }
                            id="height"
                            inputProps={{
                              type: "text",
                              name: "height",
                              autoComplete: "off",
                              // required: true,
                              value: height,
                              inputRef: input => (this.height = input),
                              onChange: event => this.handleDigits(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.height}
                            formErrors={this.state.formErrors}
                            fieldName="height"
                          />
                        </FormGroup> */}
                        {/* <FormGroup className="cusForm">
                          <CustomInput
                            labelText="Asset File"
                            labelProps={{
                              className: "required-label"
                            }}
                            id="assetFile"
                            inputProps={{
                              type: "text",
                              name: "assetFile",

                              autoComplete: "off",
                              // required: true,
                              value: this.state.assetFile,
                              inputRef: input => (this.assetFile = input),
                              onChange: event => this.handleUserInput(event)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                          <FormErrors
                            show={!this.state.assetFileValid}
                            formErrors={this.state.formErrors}
                            fieldName="assetFile"
                          />
                        </FormGroup> */}
                      </GridItem>

                      <div className="upload-cover">
                        <GridItem md={6} sm={6} xs={12}>
                          <Dropzone
                            multiple={false}
                            onDrop={acceptedFiles =>
                              this.handleSelectedFile(acceptedFiles)
                            }
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive
                            }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div
                                  className={
                                    "upload-box " +
                                    (isDragActive ? "upload-box-active" : "")
                                  }
                                >
                                  {!this.state.files.length ? (
                                    <>
                                      <img src={upload} alt="" />
                                      <p className="drag-text">
                                        {isDragActive
                                          ? "Drag & Drop File Here" //"Drop File Here"
                                          : "Drag & Drop File Here"}
                                      </p>
                                      <Button
                                        className={
                                          isDragActive
                                            ? "green-upload-btn" //visible-none
                                            : "green-upload-btn"
                                        }
                                        // onClick={this.resetForm}
                                        type="button"
                                      >
                                        Upload
                                        {/* Browse */}
                                      </Button>
                                    </>
                                  ) : (
                                    <div className={"thumb"} key="file.name">
                                      <div className={"thumb-inner"}>
                                        {(this.state.assetType == "image") ? 
                                        <img
                                          className={"img-preview"}
                                          src={this.state.imagePreview}
                                          alt="click to chnage"
                                        />
                                        :
                                        (this.state.assetType == "video") 
                                          ? 
                                          <video className={"img-preview"}
                                          src={this.state.imagePreview}
                                          alt="click to chnage" />
                                          :
                                          (this.state.assetType == "audio" || this.state.assetType == "text") 
                                            ? <img
                                            className={"img-preview"}
                                            src={assetsThumbnail}
                                            alt="click to chnage"
                                          />
                                            : null
                                        }
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <FormErrors
                            show={!this.state.uploadingAssetsValid}
                            formErrors={this.state.formErrors}
                            fieldName="imagePreview"
                          />
                          {(this.state.isEditing) ? 
                            <GridItem xs={12}>
                              <p className="advert-content-label">Attached Asset Preview: <a
                                  href={this.state.uploadFileData}
                                  target="_blank"
                                >Link</a>
                              </p>
                              <img className={"img-preview"} src={(this.checkURL(this.state.assetUrl) ? this.state.assetUrl : assetsThumbnail)} alt="" />
                                <div className="advert-icons-cover">
                                  <GetAppIcon
                                    className={"pointer"}
                                    onClick={event =>
                                      window.open(this.state.assetUrl, "_blank")
                                      // this.downloadMedia(
                                      //   this.state.uploadFileData,
                                      //   thumbnail
                                      // )
                                    }
                                  />
                                </div>
                            </GridItem>
                            : null
                          }
                          
                        </GridItem>
                      </div>
                    </GridContainer>
                    <div className="form-button-cover">
                      <Button
                        // variant="contained"
                        // color="primary"
                        className={this.state.loading ? "buttonSuccess" : ""}
                        disabled={this.state.loading || !this.validateForm()}
                        type="submit"
                      >
                        {this.state.isEditing ? "Update" : "Create"}
                        {this.state.loading && (
                          <CircularProgress
                            size={24}
                            className="buttonProgress"
                          />
                        )}
                      </Button>
                      <Button
                        //disabled={!this.validateForm()}
                        onClick={this.resetForm}
                        type="button"
                      >
                        Reset
                      </Button>
                      {/* <Button
                        className="back-btn"
                        color="secondary"
                        type="button"
                      >
                        <Link
                          color="textSecondary"
                          underline="none"
                          className="cancel-back-btn"
                          // to={baseRoutes.dashboard.path}
                          to={baseRoutes.listAsset.useLink}
                        >
                          Cancel
                        </Link>
                      </Button> */}
                      <NavLink
                        color="textSecondary"
                        underline="none"
                        className="cancel-back-btn"
                        to={baseRoutes.listAsset.useLink}
                      >
                        <Button
                          className="back-btn"
                          color="secondary"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

AssetsClass.propTypes = {
  classes: PropTypes.object
};
const Assets = connect(
  mapStateToProps, mapDispatchToProps
)(AssetsClass);
export default withStyles(styles)(Assets);
