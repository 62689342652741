import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // styles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link as MaterialLink,
  CircularProgress,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  Input
} from "@material-ui/core";

import sortJsonArray from "sort-json-array";
// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import edit from "assets/img/square-edit.png";
import del from "assets/img/del.png";
import dropdown from "assets/img/dropdown.png";
import clock from "assets/img/clock.png";
import Icon from "@material-ui/core/Icon";
import done from "assets/img/done.svg";
import search from "assets/img/search.svg";

import { Link } from "react-router-dom";
import { baseRoutes, basePath } from "base-routes";
import { apiPath } from "api";

import { store } from "react-notifications-component";
import { authHeader } from "__helpers/auth-header";
import enMsg from "__helpers/locale/en/en";
import {
  NotificationOptions,
  CampaginStatus,
  STORED_ADNETWORK,
  ADNETWORK_ID_SLUG,
  CAMPAIGN_ID_SLUG,
  VENDOR_ID_SLUG,
  PER_PAGE_OPTIONS,
  RECORD_PER_PAGE,
  SELECTED_ADNETWORK
} from "__helpers/constants";
import { userService } from "_services/user.service";
import { NotificationContainer } from "react-notifications";
import { STORED_VENDORS } from "__helpers/constants";
import { connect } from "react-redux";
import {
  addVendors,
  reduxLoad,
} from "../../js/actions/index";

// adNetworkId: "f5148973-26b7-494c-9218-36c9fc1e18eb"
// adNetworkRoles: [{id: "050852b6-b28d-4af6-8d2d-31e385e03110", email: "tttbill3@gmail.com", adNetworkRole: "owner",…}]
// address: "jdh"
// city: "jdh"
// companyName: "HeroHonda"

const columns = [
  // { id: "vendorId", label: "Id" },
  { id: "name", label: "Name" },
  { id: "infoUrl", label: "Url" },
  {
    id: "Actions",
    label: "Actions",
    align: "right",
    format: value => value.toFixed(2)
  }
];

const useStyles = {
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: "auto"
  }
};
function mapDispatchToProps(dispatch) {
  return {
    addVendors: addVendorsVal => dispatch(addVendors(addVendorsVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class VendorListClass extends React.Component {
  cust_table_cover = "custom-table-cover";
  camph = "campaign-header";
  tableh = "table-header";
  cust_table = "custom-table";
  camp_btn_cover = "campaign-btn-cover";
  camp_btn = "campaign-btn";
  icon_cover = "icon-cover";

  constructor(props) {
    super(props);
    this.stroedAdNetwork = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let selectedAdnetworkData = JSON.parse(localStorage.getItem(SELECTED_ADNETWORK));
    let selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
    let vendors = JSON.parse(localStorage.getItem(STORED_VENDORS));
    // stroedAdNetwork = [];

    this.adNetworkSelectBox = React.createRef(null);
    this.selectedAdNetworkJsonID = null;
    this.selectedadNetworkID = null;
    this.notificationID = null;
    this.selectedCampaignStatus = null;
    this._isMounted = false;
    this.state = {
      page: 0,
      selectedCampaignID: null,
      selectedadVendorID: null,
      adNetworkJson: this.stroedAdNetwork,
      originalFetchData: (vendors) ? vendors : [],
      adnetwork: selectedAdnetwork ? selectedAdnetwork : "",
      selectedAdnetworkData: selectedAdnetworkData ? selectedAdnetworkData : [],
      vendorListData: (vendors) ? vendors : [],
      rowsPerPage: RECORD_PER_PAGE,
      openMenu: null,
      openAlertBox: false,
      openAlertBoxDelete: false,
      loading: false,
      vendorShort: "",
      sortOrder: "asc",
      reduxLoadFlag: false,
    };
    this.adNetworkChange = this.adNetworkChange.bind(this);
    // this.sortAndFilter = this.sortAndFilter.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchVendorData = this.fetchVendorData.bind(this);
    this.fetchAdNetwork = this.fetchAdNetwork.bind(this);
    this.loadVendors = this.loadVendors.bind(this);
    this.sortAndFilter = this.sortAndFilter.bind(this);
  }
  componentDidUpdate(){
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let selectedAdnetwork = this.state.adnetwork;
      let adnetworkData = [];
      let vendorsData = [];
      if (this.props.adNetwork) {
        let selectedAdnetworkData = this.props.adNetwork;
        selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
        adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
      }
      if (this.props.vendors) {
        let jsonData = this.props.vendors;
        vendorsData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        adnetwork: (selectedAdnetwork) ? selectedAdnetwork : "",
        selectedAdnetworkData: (adnetworkData) ? adnetworkData : [],
        originalFetchData: (vendorsData) ? vendorsData : [],
        vendorListData: (vendorsData) ? vendorsData : [],
      })
    }
  }
  async componentDidMount() {
    this._isMounted = true;
    // this.fetchAdNetwork();
    if(!this.state.adnetwork){
      let showNotification = {
        title: enMsg.selectAdnetworkTitle,
        message: enMsg.selectAdnetwork,
        type: "quickInfoAlert"
      };
      userService.showNotification(showNotification)
    }
    let selectedAdnetwork = this.state.adnetwork;
    let adnetworkData = [];
    let vendorsData = [];
    if (this.props.adNetwork) {
      let selectedAdnetworkData = this.props.adNetwork;
      selectedAdnetwork = (selectedAdnetworkData) ? selectedAdnetworkData.adNetworkId : "";
      adnetworkData = (selectedAdnetworkData) ? selectedAdnetworkData : [];
    }
    if (this.props.vendors) {
      let jsonData = this.props.vendors;
      vendorsData = (jsonData) ? jsonData : [];
    }
    this.setState({
      // reduxLoadFlag: false,
      adnetwork: (selectedAdnetwork) ? selectedAdnetwork : "",
      selectedAdnetworkData: (adnetworkData) ? adnetworkData : [],
      originalFetchData: (vendorsData) ? vendorsData : [],
      vendorListData: (vendorsData) ? vendorsData : [],
    })
    // reauthorize token request...
    userService.refreshClientAouth();
    // this.loadVendors();
  }
  fetchAdNetwork = async () => {
    let adNetwork = await userService.fetchAdNetwork();

    this._isMounted &&
      this.setState({
        adNetworkJson: adNetwork
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  UNSAFE_componentWillMount() {
    this.setState(Object.getPrototypeOf(this).constructor.STATE || {});
    if (Object.getPrototypeOf(this).constructor.STATE) {
      var stataData = Object.getPrototypeOf(this).constructor.STATE;
      var selectedVal = null;
    }
  }
  fetchVendorData = async selectedVal => {
    let vendorVals = await userService.fetchVendors(selectedVal);
    this.setState({
      vendorListData: vendorVals,
      originalFetchData: vendorVals
    });
  };
  async loadVendors(){
    // this.fetchVendorData(this.state.adnetwork);
  }
  async adNetworkChange(e) {
    let selectedVal = e.target.value;
    if (selectedVal) {
      this.setState({ adnetwork: selectedVal });
      this.fetchVendorData(selectedVal);
    }
  }
  handleChangePage = async (event, newPage) => {
    this.setState({
      page: newPage
    });
    // setPage(newPage);
  };

  searchVendor = e => {
    let vendorListDataVal = this.state.originalFetchData;
    const searchText = e.target.value;
    try {
      if (searchText) {
        vendorListDataVal = vendorListDataVal.filter((v, i) => {
          if (v.name) {
            return (
              v.name.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1
            );
          }
        });
      }
    } catch {
    }
    this.setState({
      vendorListData: vendorListDataVal
    });
  };
  async fetchData() {
    let vendors =  await userService.fetchAllVendors(this.state.adnetwork);
    this.props.addVendors(vendors);
    setTimeout(function(){
      this.props.reduxLoad(true);
    }.bind(this),500);
  }
  handleClickOpen = (event, adNetworkId, campaignId) => {};
  deleteVendor = async () => {
    this.setState({ loading: true });
    let apiPathString = apiPath.manageVendor;
    apiPathString = apiPathString.replace(
      ADNETWORK_ID_SLUG,
      this.state.adnetwork
    );
    apiPathString = apiPathString.replace(
      VENDOR_ID_SLUG,
      this.state.selectedadVendorID
    );
    {
      let showNotification = {};
      try {
        const response = await fetch(apiPathString, {
          method: "DELETE",
          headers: authHeader(),
          body: JSON.stringify({
            vendorId: this.state.selectedadVendorID
            // vendorId: this.state.selectedadVendorID
          })
        })
          .then(response => {
            const contentType = response.headers.get("content-type");
            if (response.status === 400) {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            } else if (response.ok) {
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.vendorDeleted,
                type: "success"
              };
            }else{
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            }
            return response;
          })
          .then(data => {
            if(data.errorMessage && data.errorMessage != undefined){
              showNotification = {
                title: enMsg.failedTitle,
                message: data.errorMessage,
                type: "warning"
              };
            }else{
              this.fetchData();
              data = this.state.vendorListData.filter((v, i) => {
                return !(v.vendorId == this.state.selectedadVendorID);
              });
              this.setState({
                originalFetchData: data,
                vendorListData: data,
                selectedadVendorID: null
              });
            }
          })
          .catch(error => {
            const data = this.state.vendorListData.filter((v, i) => {
              return !(v.adNetworkId == this.state.selectedadVendorID);
            });
            this.setState({ originalFetchData: data });
            showNotification = {
              title: enMsg.connectionFailed,
              message: enMsg.networkFailedError,
              type: "danger"
            };
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.connectionFailed,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
      if (
        showNotification !== undefined &&
        showNotification.title !== undefined &&
        showNotification.message !== undefined &&
        showNotification.type !== undefined
      ) {
        this.notificationID = store.removeNotification(this.notificationID);
        if (this.notificationID == undefined) {
          let notifiaciton = {
            title: showNotification.title,
            message: showNotification.message,
            type: showNotification.type
          };
        }
        userService.showNotification(showNotification)
        //window.location.reload();
      }
    }
    this.setState({
      loading: false,
      openAlertBoxDelete: false
    });
  };

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    });
  }
  handleVendorSorting(fieldName, order){
    this.setState(
      {
        vendorShort: fieldName,
        sortOrder: order,
      },
      () => this.sortAndFilter()
    );
  };
  sortAndFilter() {
    let vendorListData = this.state.originalFetchData;
    if (this.state.vendorShort) {
      sortJsonArray(vendorListData, this.state.vendorShort, this.state.sortOrder); // default is 'asc'
    }
    this.setState({
      vendorListData: vendorListData,
    });
  }
  render() {
    var deleteIcon = (
      <Icon>
        <span color="secondary">
          <img src={del} alt="" />
        </span>
      </Icon>
    );

    const editIcon = (
      <Icon>
        <span color="primary">
          <img src={edit} alt="" />
        </span>
      </Icon>
    );

    const dropDownIcon = (
      <Icon
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => this.handleClickOpen(event)}
      >
        <img src={dropdown} className="campaign-status-img" alt="" />
      </Icon>
    );
    const classes = this.props.classes;
    const {
      adnetwork,
      sorting,
      vendorListData,
      originalFetchData,
      rowsPerPage,
      page,
      defaultMessage
    } = this.state;
    return (
      <div>
        {/* <NotificationContainer/> */}
        <Menu
          id="simple-menu"
          anchorEl={this.state.openMenu}
          keepMounted
          open={Boolean(this.state.openMenu)}
          className={"status-dropdown-menu"}
        >
          {Object.keys(CampaginStatus).map(status => {
            return (
              <MenuItem
                onClick={event =>
                  this.state.selectedCampaignStatus !== status
                    ? this.setState({
                        selectedCampaignStatus: status,
                        openAlertBox: true
                      })
                    : this.setState({
                        openAlertBox: false
                      })
                }
                key={status}
                value={status}
                className={
                  this.state.selectedCampaignStatus !== status
                    ? "capmpaign-items"
                    : ""
                }
              >
                {this.state.selectedCampaignStatus === status ? (
                  <img src={done} alt="" className="pop-check" />
                ) : (
                  ""
                )}
                {CampaginStatus[status]}
              </MenuItem>
            );
          })}
        </Menu>

        {/* Alert box dialog for Change campaign status  */}
        <Dialog
          open={this.state.openAlertBox}
          onClose={event => {
            this.setState({
              selectedadNetworkID: null,
              openMenu: null
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Caution!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {enMsg.alertForChangeStatus}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="form-button-cover">
              <Button
                className={this.state.loading ? "buttonSuccess" : ""}
                disabled={this.state.loading}
                type="button"
                onClick={this.changeCampaignStatus}
              >
                Confirm
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button
                className="back-btn"
                color="secondary"
                type="button"
                onClick={event => {
                  this.setState({
                    openAlertBox: false,
                    openMenu: null
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        {/* Alert box dialog for Delete campaign */}
        <Dialog
          open={this.state.openAlertBoxDelete}
          onClose={event => {
            this.setState({
              selectedadNetworkID: null,
              openMenu: null
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Caution!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {enMsg.alertForDeleteVendor}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="form-button-cover mt-0 mb-10">
              <Button
                className={this.state.loading ? "buttonSuccess" : ""}
                disabled={this.state.loading}
                type="button"
                onClick={event => this.deleteVendor()}
              >
                Confirm
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
              <Button
                className="back-btn"
                color="secondary"
                type="button"
                onClick={event => {
                  this.setState({
                    openAlertBoxDelete: false
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" className={"cusCardTitle"}>
                <h4 className={this.camph}>Vendors</h4>
              </CardHeader>
              <GridItem xs={12} sm={12} md={12}>
                <div className="search-btn-cover">
                  <input
                    type="text"
                    placeholder="Vendor Name"
                    className="search-btn"
                    onChange={event => this.searchVendor(event)}
                  />
                  {/* <CustomInput
                    labelText="Last Name"
                    id="city"
                    formControlProps={{
                      fullWidth: true
                    }}
                    /> */}
                  <i className="material-icons">search</i>
                </div>
                <div className="button-cover">
                  <Link
                    // to={`${basePath}${baseRoutes.addCampaign.path}`} ///${user.username}
                    to={`${basePath}${baseRoutes.addVendor.path}`}
                    color="textSecondary"
                    className="add-campaign-btn"
                    underline="none"
                  >
                    <button className={this.camp_btn}>new vendor</button>
                  </Link>
            </div>
                {/* <p className="selectedAdnetwork">Selected Adnetwork: <span> {this.state.selectedAdnetworkData.companyName ? this.state.selectedAdnetworkData.companyName : "Please select adnetwork"}</span></p> */}
                <div className="form-cover" style={{display:"none"}}>
                  <FormGroup>
                    <FormControl className={"mat_select"}>
                      <InputLabel shrink htmlFor="campaigns">
                        Select AdNetWork
                      </InputLabel>
                      <Select
                        id="adnetwork"
                        color="secondary"
                        name="adnetwork"
                        value={adnetwork}
                        width="100px"
                        onChange={this.adNetworkChange}
                        disabled={true}
                        input={<Input value={adnetwork} id="campaignsInput" />}
                        inputProps={{
                          required: true,
                          inputRef: this.adNetworkSelectBox
                        }}
                        displayEmpty
                      >
                        <MenuItem value="">Select AdNetwork</MenuItem>
                        {typeof this.state.adNetworkJson === "object" &&
                        this.state.adNetworkJson.length
                          ? this.state.adNetworkJson.map((adNetwork, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  value={adNetwork.adNetworkId}
                                >
                                  {adNetwork.companyName}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </FormGroup>
                </div>
                <div className="button-cover" style={{ display: "none" }}>
                  <Link
                    to={`${this.state.addAdvertisementLink}`}
                    color="textSecondary"
                    className="add-campaign-btn"
                    underline="none"
                  >
                    <button className="pointer">create advertisement</button>
                  </Link>
                </div>
              </GridItem>
              <CardBody>
                <Paper className={(classes.root, this.cust_table_cover)}>
                  <div className={(classes.tableWrapper, this.cust_table)}>
                    <Table>
                      <TableHead className={this.tableh}>
                        <TableRow>
                          {columns.map(column => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {(column.id != "Actions") 
                                ?
                                  <div>{column.label} <span className="shortingMain"><i className="fa fa-long-arrow-down" onClick={this.handleVendorSorting.bind(this, column.id, "asc")} style={{position:"absolute"}}></i><i className="fa fa-long-arrow-up" onClick={this.handleVendorSorting.bind(this, column.id, "des")}></i></span></div>
                                : 
                                  <div>{column.label}</div>
                              }
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody key="TableBody">
                        {typeof this.state.vendorListData === "object" &&
                        this.state.vendorListData.length
                          ? this.state.vendorListData
                              .slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                  this.state.rowsPerPage
                              )
                              .map(row => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.vendorId}
                                  >
                                    {columns.map(column => {
                                      const value = row[column.id];
                                      if (column.id == "camp_ad") {
                                        return (
                                          <TableCell key={column.id}>
                                            {row.campaignId}
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "type") {
                                        return (
                                          <TableCell key={column.id}>
                                            --
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "bus_name") {
                                        return (
                                          <TableCell key={column.id}>
                                            AdNetWork
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "advertisement") {
                                        return (
                                          <TableCell key={column.id}>
                                            {row.advertisement}
                                          </TableCell>
                                        );
                                      }

                                      if (column.id == "status") {
                                        return (
                                          <TableCell key={column.id}>
                                            {row.status}
                                          </TableCell>
                                        );
                                      }

                                      if (column.id == "description") {
                                        return (
                                          <TableCell key={column.id}>
                                            {value.length > 80
                                              ? row.description.substr(0, 80) +
                                                "..."
                                              : row.description}
                                          </TableCell>
                                        );
                                      }
                                      if (column.id == "Actions") {
                                        {
                                          var link = baseRoutes.editVendor.useLink
                                            .replace(
                                              ADNETWORK_ID_SLUG,
                                              this.state.adnetwork
                                            )
                                            .replace(
                                              VENDOR_ID_SLUG,
                                              row.vendorId
                                            );
                                        }
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={"right"}
                                          >
                                            <Link
                                              to={link}
                                              className={this.icon_cover}
                                            >
                                              {editIcon}
                                            </Link>
                                            <Button
                                              datavalue={row.id}
                                              className={
                                                this.icon_cover + " del-btn"
                                              }
                                              onClick={event => {
                                                this.setState({
                                                  selectedadVendorID:
                                                    row.vendorId,
                                                  openAlertBoxDelete: true
                                                });
                                              }}
                                            >
                                              {deleteIcon}
                                            </Button>
                                            {/* <Button
                                              className={`${this.icon_cover} campaign-status`}
                                              // onClick={handleCampaignStatus}
                                            >
                                              <Icon
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={event => {
                                                  this.setState({
                                                    selectedadNetworkID:
                                                      row.adNetworkId,
                                                    openAlertBoxDelete: true,
                                                    selectedCampaignID:
                                                      row.campaignId,
                                                    selectedadNetworkID:
                                                      row.adNetworkId,
                                                    selectedCampaignStatus: String(
                                                      row.status
                                                    ).toLowerCase(),
                                                    openMenu:
                                                      event.currentTarget
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={dropdown}
                                                  className="campaign-status-img"
                                                  alt=""
                                                />
                                              </Icon>
                                              {dropDownIcon}
                                            </Button> */}
                                          </TableCell>
                                        );
                                      }
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {column.format &&
                                          typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })
                          : null}
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={PER_PAGE_OPTIONS}
                    component="div"
                    count={
                      typeof this.state.vendorListData === "object" &&
                      this.state.vendorListData.length
                        ? this.state.vendorListData.length
                        : 0
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page"
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Paper>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
VendorListClass.propTypes = {
  classes: PropTypes.object
  // openChangePasswordDialog:
};
const VendorList = connect(
  mapStateToProps, mapDispatchToProps
)(VendorListClass);
export default withStyles(useStyles)(VendorList);
