import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Downloader from "js-file-downloader";
import {
  SHOW_LOADER,
  AdvertisementStatus,
  categoriesJson,
  brandsJson,
  redemptionTypeValues,
  savingsTypeValues,
  adMediumTypes,
  advertisementTypes,
  ADVERTISEMENT_ID_SLUG,
  STORED_ADNETWORK,
  STORED_ADVERTISEMENT,
  ADNETWORK_ID_SLUG

  /*eslint-disable*/
} from "__helpers/constants";

// core components
import { InputLabel } from "@material-ui/core";
import { Link } from "react-router-dom";
// import PropTypes from 'prop-types';
import CoolTabs from "react-cool-tabs";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import thumbnail from "assets/img/assets-thumbnail.png";
import thumbnail2 from "assets/img/assets-thumbnail-2.png";
import thumbnail3 from "assets/img/assets-thumbnail-3.png";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import edit from "assets/img/edit-icon.png";
import cross from "assets/img/cross.png";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { userService } from "_services/user.service";
import VendorList from "./VendorList";
import AssetsList from "./AssetsList";
import { NONAME } from "dns";
import baseRoutes from "base-routes";
import DateFnsUtils from "@date-io/date-fns";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import assetsThumbnail from "assets/img/sample_assets.png";
import { FETCH_CATEGORIES } from "__helpers/constants";
import { FETCH_BRANDS } from "__helpers/constants";
import SearchIcon from '@material-ui/icons/Search';
import { basePath } from "base-routes";
import { connect } from "react-redux";
import {
  addAdvertisements,
  addCampaigns,
  reduxLoad,
} from "../../js/actions/index";

function mapDispatchToProps(dispatch) {
  return {
    addAdvertisements: addAdvertisementsVal => dispatch(addAdvertisements(addAdvertisementsVal)),
    addCampaigns: addCampaignsVal => dispatch(addCampaigns(addCampaignsVal)),
    reduxLoad: reduxLoadVal => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = state => {
  // console.log("StateVal "+JSON.stringify(state));
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    adNetworkList: state.adNetworkList,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
class AdvertisementDetailsClass extends React.Component {
  openMenuButton = React.createRef(null);
  menuButton = React.createRef(null);

  constructor(props) {
    super(props);
    this.stroedAdDetails = JSON.parse(localStorage.getItem(STORED_ADVERTISEMENT));
    const { adnetworkID, advertisementID } = this.props.match.params;
    let storedAdIdVals = null
    for (var propertyVal in this.stroedAdDetails) {
      if (this.stroedAdDetails.hasOwnProperty(propertyVal)) {
        let tempData = this.stroedAdDetails[propertyVal]
        if(advertisementID == tempData.adId){
          storedAdIdVals = tempData
        }
      }
    }
    this.dateUtility = new DateFnsUtils();
    this._isMounted = false;
    this.state = {
      // adDataJson: storedAdIdVals,
      adDataJson: [],
      advertisementID: advertisementID,
      adnetworkID: adnetworkID,
      value: 0,
      user: {},
      gender: "",
      users: [],
      openMenu: false,
      advertisementDetail: {},
      categoriesJson: categoriesJson,
      brandsJson: brandsJson,
      searchContent: "",
      reduxLoadFlag: false,
    };
    this.advertisementDetailCategories = [];
    this.advertisementDetailBrands = [];
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.fetchAdvertisementDetail = this.fetchAdvertisementDetail.bind(this);
  }
  forceDownload(url, fileName){
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Access-Control-Allow-Origin","https://asgcloudplatformint.blob.core.windows.net/");
    // xhr.setRequestHeader("Content-Type", "application/json");
    xhr.responseType = "blob";
    xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
  }
  downloadMedia(url, type) {
    new Downloader({
      url: url
    })
      .then(function() {
        // Called when download ended
      })
      .catch(function(error) {
          alert("Download not allowed..");
      });
  }

  handleClickOpen = () => {
    this.setState({ openMenu: true });
  };
  handleClose = () => {
    this.setState({ openMenu: false });
  };

  async fetchAdvertisementDetail(storedAdIdVals) {
    let advertisementDetail = this.state.adDataJson
    if(storedAdIdVals){
      advertisementDetail = storedAdIdVals
    }
    if (advertisementDetail) {
      if (
        advertisementDetail.startDate &&
        this.dateUtility.isValid(advertisementDetail.startDate)
      ) {
        
        advertisementDetail.startDateLocal = this.dateUtility.format(
          new Date(advertisementDetail.startDate),
          "MM/dd/yyyy"
        );
      }

      if (
        advertisementDetail.expirationDate &&
        this.dateUtility.isValid(advertisementDetail.expirationDate)
      ) {
        advertisementDetail.expirationDateLocal = this.dateUtility.format(
          new Date(advertisementDetail.expirationDate),
          "MM/dd/yyyy"
        );
      }

      this.setState({ advertisementDetail: advertisementDetail });
    } else {
      let showNotification = {
        title: "Advertisement Detail",
        message: "We are not able to fetch ad data, please select adnetwork again.",
        type: "warning"
      };
      userService.showNotification(showNotification)
      setTimeout(function() {
        window.history.back();
      }, 2100);

      // this.props.history.goBack();
    }
  }
  componentDidUpdate(){
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let advertisementsData = [];
      let storedAdIdVals = [];
      let adCategoriesData = [];
      let adBrandsData = [];
      if (this.props.advertisements) {
        let jsonData = this.props.advertisements;
        advertisementsData = (jsonData) ? jsonData : [];
        for (var propertyVal in advertisementsData) {
          if (advertisementsData.hasOwnProperty(propertyVal)) {
            let tempData = advertisementsData[propertyVal]
            if(this.state.advertisementID == tempData.adId){
              storedAdIdVals = tempData
            }
          }
        }
      }
      if (this.props.adCategories) {
        let jsonData = this.props.adCategories;
        adCategoriesData = (jsonData) ? jsonData : [];
      }
      if (this.props.adBrands) {
        let jsonData = this.props.adBrands;
        adBrandsData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        adDataJson: (storedAdIdVals) ? storedAdIdVals : [],
        categoriesJson: adCategoriesData,
        brandsJson: adBrandsData
      })
    }
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props.reduxLoadFlag != undefined && this.state.reduxLoadFlag != this.props.reduxLoadFlag) {
      let advertisementsData = [];
      let storedAdIdVals = [];
      let adCategoriesData = [];
      let adBrandsData = [];
      if (this.props.advertisements) {
        let jsonData = this.props.advertisements;
        advertisementsData = (jsonData) ? jsonData : [];
        for (var propertyVal in advertisementsData) {
          if (advertisementsData.hasOwnProperty(propertyVal)) {
            let tempData = advertisementsData[propertyVal]
            if(this.state.advertisementID == tempData.adId){
              storedAdIdVals = tempData
            }
          }
        }
      }
      if (this.props.adCategories) {
        let jsonData = this.props.adCategories;
        adCategoriesData = (jsonData) ? jsonData : [];
      }
      if (this.props.adBrands) {
        let jsonData = this.props.adBrands;
        adBrandsData = (jsonData) ? jsonData : [];
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        adDataJson: (storedAdIdVals) ? storedAdIdVals : [],
        categoriesJson: adCategoriesData,
        brandsJson: adBrandsData
      })
      this.fetchAdvertisementDetail(storedAdIdVals);
    }

    userService.refreshClientAouth();
    // let categoryVals = JSON.parse(localStorage.getItem(FETCH_CATEGORIES));
    // let brandVals = JSON.parse(localStorage.getItem(FETCH_BRANDS));
    // this.setState({
    //   categoriesJson: categoryVals,
    //   brandsJson: brandVals
    // });
  }

  updateVendorDataCallback = data => {
    let advertisementDetail = this.state.advertisementDetail;
    advertisementDetail.vendors = data;
    this.advertisementDetailCategories = [];
    this.advertisementDetailBrands = [];
    this.setState({
      advertisementDetail: advertisementDetail
    });
    this.fetchData();
  };
  async fetchData() {
    let campaigns = [];
    let advertisement = [];
    try {
      campaigns = await userService.fetchCampagin(this.state.adnetworkID);
      if (campaigns) {
        this.props.addCampaigns(campaigns);
        campaigns.map((campaign, key) => {
          if (campaign.advertisements && campaign.advertisements.length) {
            Array.prototype.push.apply(advertisement, campaign.advertisements);
          }
        });
      }
      // localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisement));
      this.props.addAdvertisements(advertisement);
      setTimeout(function(){
        this.props.reduxLoad(true);
      }.bind(this),1000);
    } catch (errors) {
      console.log("error: "+errors);
      window.history.back();
    }
  }
  updateAssetsDataCallback = data => {
    let advertisementDetail = this.state.advertisementDetail;
    advertisementDetail.assets = data;
    this.advertisementDetailCategories = [];
    this.advertisementDetailBrands = [];
    this.fetchData();
    this.setState({
      advertisementDetail: advertisementDetail
    });
  };

  checkURL(url) {
    return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
  searchString = e => {
    const searchText = e.target.value;
    this.setState({
      searchContent: searchText
    });
  };
  render() {
    const { advertisementDetail } = this.state;
    this.advertisementDetailCategories = [];
    const { classes } = this.props;
    return (
      <div>
        {/* <NotificationContainer/> */}
        {advertisementDetail.campaignId ? (
          <GridContainer>
            <Card className={"advertdetails-cover"}>
              <GridItem xs={12} sm={12} md={12}>
                <CardHeader
                  color="success"
                  className={"cusCardheader" + " cusCardTitle"}
                >
                  <h4 className={classes.cardTitleWhite}>
                    Advertisement Detail
                  </h4>
                </CardHeader>
              </GridItem>
              <GridContainer>
                <GridItem xs={12} sm={8} md={8}>
                  <div className="advertdetail-large-cover">
                    <div className="campaign-id">
                      <h5>
                        campaign id: #
                        <span>
                          {advertisementDetail.campaignId
                            ? advertisementDetail.campaignId
                            : ""}
                        </span>
                      </h5>
                      <div className="campaign-edit-img">
                        <Link
                          color="textSecondary"
                          underline="none"
                          className="cancel-back-btn"
                          to={String(
                            baseRoutes.editAdvertisement.useLink
                          ).replace(
                            ADVERTISEMENT_ID_SLUG,
                            advertisementDetail.adId
                          ).replace(ADNETWORK_ID_SLUG, this.state.adnetworkID)}
                        >
                          <img src={edit} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="advert-content-header">
                      <h4>{advertisementDetail.name}</h4>
                      {/* Lorem ipsum dolor sit amet, consectetur adipis */}
                    </div>
                    <div className="advert-content-2">
                      <p className="advert-p">
                        {advertisementDetail.description
                          ? advertisementDetail.description
                          : ""}
                      </p>
                    </div>
                    <div className="advert-columns">
                      <GridContainer>
                        <GridItem md={6} sm={6} xs={6}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            advertisement type
                          </InputLabel>
                          <p className="advert-p">
                            {advertisementTypes[
                              advertisementDetail.adType
                            ]
                              ? advertisementTypes[
                                  advertisementDetail.adType
                                ]
                              : "N/A"}
                          </p>
                        </GridItem>
                        <GridItem md={6} sm={6} xs={6}>
                          {/* <InputLabel htmlFor="" className="advert-content-label">
                          type
                        </InputLabel>
                        <p className="advert-p">
                          {advertisementTypes[
                            advertisementDetail.advertisementType
                          ]
                            ? advertisementTypes[
                                advertisementDetail.advertisementType
                              ]
                            : "N/A"}
                        </p> */}
                        </GridItem>
                        <GridItem md={6} sm={6} xs={6}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            Start Date
                          </InputLabel>
                          <p className="advert-p">
                            {advertisementDetail.startDateLocal}
                          </p>
                        </GridItem>
                        <GridItem md={6} sm={6} xs={6}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            Expiration Date
                          </InputLabel>
                          <p className="advert-p">
                            {advertisementDetail.expirationDateLocal}
                          </p>
                        </GridItem>

                        <GridItem md={6} sm={6} xs={6}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            categories
                          </InputLabel>
                          <p className="advert-p">
                            {/* {(this.advertisementDetailCategories = [])} */}
                            {typeof advertisementDetail.categories ===
                              "object" &&
                            advertisementDetail.categories.length &&
                            this.state.categoriesJson
                              ? this.state.categoriesJson.map(categoriesData => {
                                  if (
                                    advertisementDetail.categories.includes(
                                      categoriesData.categoryId
                                    )
                                  ) {
                                    this.advertisementDetailCategories.push(
                                      categoriesData.name
                                    );
                                    {categoriesData.name}
                                  }
                                })
                              : null}
                            {this.advertisementDetailCategories.join(", ")}
                          </p>
                        </GridItem>
                        <GridItem md={6} sm={6} xs={6} style={{disable:"none"}}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            brands
                          </InputLabel>
                          <p className="advert-p">
                            {/* {(this.advertisementDetailBrands = [])} */}
                            {typeof advertisementDetail.brands === "object" &&
                            advertisementDetail.brands.length &&
                            brandsJson
                              ? brandsJson.map(brandsData => {
                                  if (
                                    advertisementDetail.brands.includes(
                                      brandsData.name
                                    )
                                  ) {
                                    this.advertisementDetailBrands.push(
                                      brandsData.name
                                    );
                                  }
                                })
                              : null}
                            {this.advertisementDetailBrands.join(", ")}
                          </p>
                        </GridItem>
                        <GridItem md={12} sm={12} xs={12}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            purchase <span>url</span>
                          </InputLabel>
                          <p className="advert-p">
                            {advertisementDetail.purchaseUrl
                              ? advertisementDetail.purchaseUrl
                              : "N/A"}
                          </p>
                        </GridItem>
                        <GridItem md={12} sm={12} xs={12}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            terms
                          </InputLabel>
                          <p className={"advert-p"}>
                            {advertisementDetail.terms
                              ? advertisementDetail.terms
                              : "N/A"}
                          </p>
                        </GridItem>
                        <GridItem md={6} sm={6} xs={6}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            status
                          </InputLabel>
                          <p className="advert-p">
                            {advertisementDetail.status
                              ? AdvertisementStatus[advertisementDetail.status]
                                ? AdvertisementStatus[
                                    advertisementDetail.status
                                  ]
                                : "N/A"
                              : "N/A"}
                          </p>
                        </GridItem>
                        <GridItem md={6} sm={6} xs={6}>
                          <InputLabel
                            htmlFor=""
                            className="advert-content-label"
                          >
                            ad medium
                          </InputLabel>
                          <p className="advert-p">
                            {advertisementDetail.adMedium
                              ? adMediumTypes[advertisementDetail.adMedium]
                                ? adMediumTypes[advertisementDetail.adMedium]
                                : "N/A"
                              : "N/A"}
                          </p>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div className="assets-video">
                      <InputLabel htmlFor="" className={"advert-content-label"}>
                        assets
                      </InputLabel>
                      <GridContainer>
                        {typeof advertisementDetail.assets === "object" &&
                        advertisementDetail.assets.length ? (
                          advertisementDetail.assets.map((assets, key) => {
                            return (
                              <React.Fragment key={key}>
                                <GridItem
                                  className="assets-media-container"
                                  md={3}
                                  sm={3}
                                  xs={6}
                                >

                                  <img className={"img-preview-ad-detail"} src={(this.checkURL(assets.assetUrl) ? assets.assetUrl : assetsThumbnail)} alt="" />
                                  <div className="advert-icons-cover">
                                    <GetAppIcon
                                      className={"pointer"}
                                      onClick={event =>
                                        window.open(assets.assetUrl, "_blank")
                                        // this.forceDownload(
                                        //   assets.assetUrl,
                                        //   assets.name
                                        // )
                                      }
                                    />
                                  </div>
                                </GridItem>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <GridItem md={3} sm={3} xs={12}>
                            No Assets Attached
                          </GridItem>
                        )}
                      </GridContainer>
                    </div>
                    <div className="vendors-list">
                      <InputLabel htmlFor="" className="advert-content-label">
                        vendors
                      </InputLabel>
                      <GridContainer>
                        {typeof advertisementDetail.vendor === "object" ? (
                           <GridItem md={3} sm={3} xs={12}>
                           <button className={"vendor-btn"}>
                             {advertisementDetail.vendor ? advertisementDetail.vendor.name : "N/A"}{" "}
                             {/* <img src={cross} alt="" /> */}
                           </button>
                         </GridItem>
                        ) : (
                          <GridItem md={3} sm={3} xs={12}>
                            No Vendor Attached
                          </GridItem>
                        )}
                      </GridContainer>
                    </div>
                    <div className="claim-columns">
                      <GridContainer>
                        <p className="claim-text claim-text-detail">Claims</p>
                        {typeof advertisementDetail.claims === "object" &&
                        advertisementDetail.claims.length
                          ? advertisementDetail.claims.map((value, key) => {
                              return (
                                <React.Fragment key={key}>
                                  {key > 0 &&
                                  advertisementDetail.claims.length > 1 ? (
                                    <GridItem md={12} sm={12} xs={12}>
                                      <div className="add-advert-form50">
                                        <hr></hr>
                                      </div>
                                    </GridItem>
                                  ) : (
                                    ""
                                  )}
                                  <GridItem md={6} sm={6} xs={6}>
                                    <InputLabel
                                      htmlFor=""
                                      className="advert-content-label"
                                    >
                                      saving type
                                    </InputLabel>
                                    <p className="advert-p">
                                      {value.savings
                                        ? savingsTypeValues[value.savingsType]
                                          ? savingsTypeValues[value.savingsType]
                                          : "N/A"
                                        : "N/A"}
                                    </p>
                                  </GridItem>
                                  <GridItem md={6} sm={6} xs={6}>
                                    <InputLabel
                                      htmlFor=""
                                      className="advert-content-label"
                                    >
                                      savings
                                    </InputLabel>
                                    <p className="advert-p">{value.savings}</p>
                                  </GridItem>
                                  <GridItem md={6} sm={6} xs={6}>
                                    <InputLabel
                                      htmlFor=""
                                      className="advert-content-label"
                                    >
                                      redemption type
                                    </InputLabel>
                                    <p className="advert-p">
                                      {value.savings
                                        ? redemptionTypeValues[
                                            value.redemptionType
                                          ]
                                          ? redemptionTypeValues[
                                              value.redemptionType
                                            ]
                                          : "N/A"
                                        : "N/A"}
                                    </p>
                                  </GridItem>
                                  <GridItem md={6} sm={6} xs={6}>
                                    <InputLabel
                                      key={key}
                                      htmlFor=""
                                      className="advert-content-label"
                                    >
                                      redemption code
                                    </InputLabel>
                                    <p className="advert-p">
                                      {(value.redemptionCode ? value.redemptionCode : "Private*")}
                                    </p>
                                  </GridItem>
                                </React.Fragment>
                              );
                            })
                          : <GridItem md={3} sm={3} xs={12}>
                            No Claims Attached
                          </GridItem>}
                      </GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <p className="textalign-right">
                        <Link
                          color="textSecondary"
                          className="ad-back-btn"
                          underline="none"
                          to={
                            basePath + baseRoutes.advertisement.path
                          }
                        >
                         Back
                        </Link>
                        </p>
                      </GridItem>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <GridItem xs={12} style={{margin:5}}>
                      <input
                        type="text"
                        placeholder="Search"
                        className="search-btn"
                        onChange={event => this.searchString(event)}
                      />
                      <i className="material-icons" style={{left:"auto", right:30}}>search</i>
                      
                  </GridItem>
                  <div className={"advertdetail-small-cover"}>
                    <CoolTabs
                      className={"advertdetail-tab"}
                      tabKey={"1"}
                      activeTabStyle={{ background: "white" }}
                      tabsHeaderClassName="adverttabs-header"
                      unActiveTabStyle={{
                        background: "white",
                        color: "#d0d0d0"
                      }}
                      activeLeftTabBorderBottomStyle={{
                        background: "#9bbe4b",
                        height: 5,
                        display: "none"
                      }}
                      activeTabClassName="active-tab"
                      activeRightTabBorderBottomStyle={{
                        background: "#9bbe4b",
                        height: 5,
                        display: "none"
                      }}
                      activeLeftTabBorderBottomClassName="advertactive-tab"
                      activeRightTabBorderBottomClassName="advertactive-tab"
                      // tabsBorderBottomClassName="advertactive-tab"
                      // tabsBorderBottomStyle={{ background:  'orange', height:  4 }}
                      rightContentStyle={{ background: "white" }}
                      rightContentStyle={{ background: "white" }}
                      leftTabTitle="Assets"
                      rightTabTitle="Vendors"
                      rightContent={
                        <VendorList
                          advertisementData={advertisementDetail}
                          vendorData={advertisementDetail.vendor}
                          adnetworkID={this.state.adnetworkID}
                          updateVendorDataCallback={
                            this.updateVendorDataCallback
                          }
                          searchContent={this.state.searchContent}
                        />
                      }
                      leftContent={
                        <AssetsList
                          advertisementData={advertisementDetail}
                          assetsData={advertisementDetail.assets}
                          adnetworkID={this.state.adnetworkID}
                          updateAssetsDataCallback={
                            this.updateAssetsDataCallback
                          }
                          searchContent={this.state.searchContent}
                        />
                      }
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </Card>
          </GridContainer>
        ) : (
          ""
        ) /* (<img src={SHOW_LOADER}/>) */}
      </div>
    );
  }
}

AdvertisementDetailsClass.propTypes = {
  classes: PropTypes.object.isRequired
};
const AdvertisementDetails = connect(
  mapStateToProps, mapDispatchToProps
)(AdvertisementDetailsClass);
export default withStyles(dashboardStyle)(AdvertisementDetails);
