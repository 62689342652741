import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import { CircularProgress } from "@material-ui/core";
import {
  FORBIDDEN_STATUS,
  INVALID_DATA_POST,
  NO_DATA_FOUND,
  OK_SUCCESS_STATUS,
  STORED_ASSETS,
  ADNETWORK_ID_SLUG,
  ADVERTISEMENT_ID_SLUG
} from "__helpers/constants";
import { store } from "react-notifications-component";
import { authHeader } from "__helpers/auth-header";
import { apiPath } from "api";
import { STORED_ADVERTISEMENT } from "__helpers/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  addAdvertisements,
  addCampaigns,
  reduxLoad,
} from "../../js/actions/index";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function AssetsList(props) {
  // const stroedAssetDetails = JSON.parse(localStorage.getItem(STORED_ASSETS));
  const stroedAssetDetails = useSelector(state => state.assets);
  const classes = useStyles();
  const dispatch = useDispatch();
  const assetsData = props.assetsData ? props.assetsData : [];
  const advertisementData = props.advertisementData
    ? props.advertisementData
    : [];
  const adnetworkID = props.adnetworkID ? props.adnetworkID : [];
  const searchContent = props.searchContent ? props.searchContent : "";
  let notificationID = null;
  let localAssets = [];
  const [assets, setAssets] = React.useState([]);
  const [finalAssets, setFinalAssets] = React.useState([]);
  const [searchAssetList, setSearchAsset] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedAssets, pushAssets] = React.useState(assetsData.assetId);

  const [defaultMessage, setDefaultMessage] = useState(enMsg.fetchingData);

  const handleToggle = value => () => {
    const currentIndex = selectedAssets.indexOf(value);
    const newChecked = [...selectedAssets];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    pushAssets(newChecked);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (props.assetsData && props.assetsData.length) {
      let selectedAssets = props.assetsData.map(assetsId => {
        if (assetsId) return assetsId.assetId;
      });
      pushAssets(selectedAssets);
    }

    setAssets(stroedAssetDetails);
    setFinalAssets(stroedAssetDetails);
    setSearchAsset(stroedAssetDetails);
    setDefaultMessage(enMsg.noAssetsFound);
    fetchAssets();
    if(searchContent != "")
      searchVendor();
    return () => (isSubscribed = false);
  }, [props.assetsData, searchContent]);
  function searchVendor() {
    let setSearchAssetLocal = [];
    try {
      if (searchContent) {
        setSearchAssetLocal = searchAssetList.filter((v, i) => {
          if (v.name) {
            return (
              v.name.toLowerCase().indexOf(searchContent.toLowerCase().trim()) !== -1
            );
          }
        });
        // console.log(setSearchAssetLocal)
        // setSearchAsset(setSearchAssetLocal);
      }
    }catch (error) {
      console.log(error)
    }
    setAssets(setSearchAssetLocal);
  };
  async function fetchData(updateAdData) {
    let campaigns = [];
    let advertisement = [];
    try {
      campaigns = await userService.fetchCampagin(adnetworkID);
      if (campaigns) {
        dispatch(addCampaigns(campaigns));
        campaigns.map((campaign, key) => {
          if (campaign.advertisements && campaign.advertisements.length) {
            Array.prototype.push.apply(advertisement, campaign.advertisements);
          }
        });
      }
      // localStorage.setItem(STORED_ADVERTISEMENT, JSON.stringify(advertisement));
      dispatch(addAdvertisements(advertisement));
      setTimeout(function(){
        dispatch(reduxLoad(true));
      }.bind(this),1000);
    } catch (errors) {
      console.log("error: "+errors);
      window.history.back();
    }
  }
  async function attachAssets(event) {
    setLoading(true);
    event.preventDefault();
    let showNotification = {};
    /* after sorting by assets id & strore original object of assets in selectedAssets
     * AND then attach it into advertisementData
     */
    if (
      typeof selectedAssets === "object" &&
      (advertisementData.adId || advertisementData.id)
    ) {
      let attachAssets = selectedAssets.sort();
      const adId = advertisementData.adId
        ? advertisementData.adId
        : advertisementData.id;

      let attachAssetsData = [];

      finalAssets.map((value, key) => {
        if (attachAssets.includes(value.assetId)) {
          attachAssetsData.push(value);
        }
      });
      advertisementData.assets = attachAssetsData;
      try {
        
        let editAdvertisement = String(apiPath.editAdvertisement)
          .replace(ADNETWORK_ID_SLUG, adnetworkID)
          .replace(ADVERTISEMENT_ID_SLUG, advertisementData.adId);
        ;
        const response = await fetch(editAdvertisement, {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(advertisementData)
        })
          .then(response => {
            const contentType = response.headers.get("content-type");
            if (
              [FORBIDDEN_STATUS, INVALID_DATA_POST].includes(response.status)
            ) {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.json();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            } else if (response.status === NO_DATA_FOUND) {
              showNotification = {
                title: enMsg.failedTitle,
                message: enMsg.noDataFound,
                type: "danger"
              };
            } else if (response.ok && response.status == OK_SUCCESS_STATUS) {
              // const { token } = await response.json();
              if (props.updateAssetsDataCallback) {
                props.updateAssetsDataCallback(attachAssetsData);
              }
              // dispatch(reduxLoad(false));
              // fetchData(adnetworkID);
              showNotification = {
                title: enMsg.successTitle,
                message: enMsg.attachAssetsSuccess,
                type: "success"
              };
              return response.json();
            } else {
              if(contentType && contentType.indexOf("application/json") !== -1){
                return response.text();
              }else{
                showNotification = {
                  title: enMsg.failedTitle,
                  message: enMsg.invalidData400,
                  type: "danger"
                };
              }
            }
          })
          .then(data => {
            let jsonDataVal = data;
            if(("errorMessage" in jsonDataVal)){
              showNotification = {
                title: enMsg.failedTitle,
                message: jsonDataVal.errorMessage,
                type: "warning"
              };
            }
          })
          .catch(error => {
            showNotification = {
              title: enMsg.failedTitle,
              message: enMsg.networkFailedError,
              type: "danger"
            };
            return response;
          });

        // throw new Error(error);
      } catch (error) {
        showNotification = {
          title: enMsg.failedTitle,
          message: enMsg.networkFailedError,
          type: "danger"
        };
      }
    }

    if (
      showNotification !== undefined &&
      showNotification.title !== undefined &&
      showNotification.message !== undefined &&
      showNotification.type !== undefined
    ) {
      notificationID = store.removeNotification(notificationID);
      if (notificationID == undefined) {
        let notifiaciton = {
          title: showNotification.title,
          message: showNotification.message,
          type: showNotification.type
        };
      }
      userService.showNotification(showNotification)
    }
    setLoading(false);
  }

  async function fetchAssets() {
    // if(advertisementData.adId)
    localAssets = await userService.fetchAssets(adnetworkID);
    // setAssets(localAssets);
    setSearchAsset(localAssets);
    setDefaultMessage(enMsg.noAssetsFound);
  }

  return (
    <>
      <List className={classes.root + " tab-list-cover"}>
        {assets
          ? assets.map((assets, key) => {
              const id = `checkbox-list-label-${key}`;
              return (
                <ListItem
                  key={key}
                  // role={undefined}
                  className="vendor-details-cover"
                  dense
                  button
                  onClick={handleToggle(assets.assetId)}
                >
                  <ListItemIcon key={key}>
                    <Checkbox
                      key={key}
                      edge="start"
                      checked={selectedAssets.includes(assets.assetId)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": id }}
                      className="assetsCheckbox"
                    />
                  </ListItemIcon>
                  <ListItemText
                    key={`Assets-id-${key}`}
                    id={assets.assetId}
                    className="vendor-id"
                    primary={`assets id:#${assets.assetId}`}
                  />
                  <ListItemText
                    key={`Assets-name-${key}`}
                    id={assets.name}
                    className="vendor-name vendor-type"
                    primary={`${assets.name}`}
                  />
                  <ListItemText
                    key={`Assets-type-${key}`}
                    id={assets.assetType}
                    className="vendor-name"
                    primary={`${assets.assetType}`}
                  />
                </ListItem>
              );
            })
          : null}
      </List>

      <div className="add-btn-cover">
        {assets && assets.length ? (
          <button
            className={"pointer add-btn " + (loading ? "buttonSuccess" : "")}
            disabled={loading} // || !validateForm()
            onClick={attachAssets}
          >
            update
            {loading && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </button>
        ) : (
          defaultMessage
        )}
      </div>
    </>
  );
}
